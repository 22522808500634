import {
  Box,
  TextField,
  Button,
  Autocomplete,
  Rating,
  Divider,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import PopupForm from "../../Components/PopupForm";
import { useTranslation } from "react-i18next";
import {
  ContainerOfInput,
  ContainerOfInputFields,
  ContainerOfSelectField,
} from "../../Components/ThemDesign";
import { useSelector } from "react-redux";
import ObjectErrors, {
  checkErrors,
  resetErrors,
  restCustomeObject,
} from "../../Errors/Requests/Request";
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid";

// import EmployeeAPI from '../../../../Global/Network/Employee/index'
import requestAPI from "../../Network/Request/index";
import { toast } from "react-toastify";
import * as RiIcons from "react-icons/ri";
import * as BsIcons from "react-icons/bs";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Grid from "../Grid/Grid";
import helpers from "../../assets/js/helper";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import Preloading from "../../Pages/Preload/Preloading";
import CommetCard from "./CommetCard";
import ImageList from "./ImageList";
import ViewComment from "./ViewComment";

const cookies = new Cookies();
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1E6A99",
    color: theme.palette.common.white,
    fontFamily: "Cairo-Bold !important",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const APP_CONSTANTS = {
  API_URL_IMG: `${process.env.REACT_APP_API_URL_image}`,
};

const RenderContent = (props) => {
  const [t] = useTranslation("common");
  const navigate = useNavigate();
  const [selectedImages, setSelectedImages] = useState([]);
  const [showImage, setShowImage] = useState(false);
  const maintheme = useSelector((state) => state.themeData.maintheme);
  const [ErrorsObject, setErrorsObject] = useState(ObjectErrors);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleChange = (e, number, errName) => {
    if (number == 1) {
      props.setPhone(e.target.value);
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 2) {
      props.setRequestLocation(e.target.value);
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 3) {
      props.setRequestType(e);
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 4) {
      props.setNotes(e.target.value);
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 5) {
      console.log("date", e);
      props.setPreferedDate(e);
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 6) {
      props.setPreferedTime(e);
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    }
  };
  const clearInput = (number, errName) => {
    if (number == 1) {
      props.setPhone("");
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 2) {
      props.setRequestLocation("");
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 3) {
      props.setRequestType(null);
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 4) {
      props.setNotes("");
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 5) {
      props.setPreferedDate(null);
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 6) {
      props.setPreferedTime(null);
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    }
  };
  const [columns, setColumns] = useState([
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 40,
    },
    { headerName: t("REQUESTS.add_unit_id"), field: "id", flex: 0.5 },
    { headerName: t("REQUESTS.add_unit_name"), field: "nm", flex: 2 },
    {
      headerName: t("REQUESTS.add_unit_reasone"),
      field: "reason",
      flex: 2,
      editable: true,
    },
  ]);
  const [selectedImage, setSelectedImage] = useState("");
  const [pageSize, setPageSize] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const handlePageChange = (newPage) => {
    setPageNumber(newPage + 1);
  };
  const [rowsTotal, setRowsTotal] = useState(0);
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);
  const [listOfSelectedRows, setListOfSelectedRows] = useState([]);
  const handleCheckBoxChange = (rows) => {
    console.log("datadadjandkasjdn", rows);
    setListOfSelectedRows(rows);
    let arr = [];
    props?.units &&
      props?.units?.length &&
      props?.units?.map((itm) => {
        if (rows?.find((e) => e == itm?.id)) {
          arr.push(itm);
        }
      });
    console.log("datadadjandkasjdn", arr);
    props.setUnit(arr);
  };
  const handleRowCellChange = (row) => {
    console.log("sadasdasda", row);
    let key = null;
    for (var i in row) {
      key = i;
    }
    let arr = [];
    props?.units &&
      props?.units?.length &&
      props?.units?.map((itm) => {
        if (itm?.id == key) {
          arr.push({
            ...itm,
            reason: row[key]?.reason?.value,
          });
        } else {
          arr.push(itm);
        }
      });

    console.log("datadadjandkasjdn", arr);
    if (arr) props.setUnits(arr);
  };
  const setSelectedImageToShow = (itm) => {
    setSelectedImage(itm?.url);
  };

  const getTranslateStatus = (name) => {
    if (name == "new") return "جديد";
    else if (name == "in progress") return "قيد المعالجة";
    else if (name == "closed") return "مغلقة";
    else if (name == "rejected") return "ألغيت";
    else if (name == "approved") return "مكتملة";
    else if (name == "archived") return "مؤرشف";
    else if (name == "successful") return "مكتملة";
    else if (name == "canceled") return "ألغيت";
    else if (name == "failed") return "ألغيت";
    else return name;
  };

  const getExtension = (file) => {
    const extension = file.split(".").pop();

    if (
      extension == "jpeg" ||
      extension == "jpg" ||
      extension == "png" ||
      extension == "gif" ||
      extension == "svg"
    ) {
      return true;
    }
  };

  const getColor = (request) => {
    if (request?.status_id == 1) {
      return "rgba(97, 172, 232,.8)";
    }
    if (request?.status_id == 2) {
      return "#FDD835";
    }
    if (request?.status_id == 3) {
      if (request?.close_status_color) {
        return request?.close_status_color;
      }
      return "rgba(243, 112, 12,0.8)";
    }
    if (request?.status_id == 4) {
      return "rgba(198, 40, 40,0.8)";
    }
    if (request?.status_id == 5 && request?.close_status_id == 1) {
      return "rgb(0,191,61)";
    } else if (request?.status_id == 5 && request?.close_status_id != 1) {
      return "rgba(198, 40, 40,0.8)";
    }
    if (request?.status_id == 6) {
      return "rgba(27, 94, 32,0.8)";
    }
    return "lightgrey";
  };

  return (
    <Box className="Container-fluid position-relative">
      <Box
        sx={{
          width: "35px",
          height: "35px",
          borderRadius: "50%",
          position: "fixed",
          top: "15px",
          right: "20px",
          boxShadow: "0 0 10px -2px rgba(0,0,0,0.3)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          zIndex: "211231",
        }}
        onClick={() => {
          props?.setOpen(false);
          props.clearForm();
        }}
      >
        <CloseIcon
          sx={{
            fontSize: "30px",
            color: "#407ea4",
          }}
        />
      </Box>
      <Box
        sx={{
          width: "fit-content",
          height: "fit-content",
          padding: "5px 10px",
          borderRadius: "5px",
          position: "fixed",
          top: "15px",
          right: "200px",
          boxShadow: "0 0 10px -2px rgba(0,0,0,0.3)",
          backgroundColor: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          zIndex: 2000,
        }}
      >
        <span style={{ fontFamily: "Cairo-Medium" }}>
          {t("REQUESTS.status")}
        </span>
        <Box
          sx={{
            width: "fit-content",
            height: "30px",
            backgroundColor: getColor(props?.object),
            borderRadius: "3px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "Cairo-Medium",
            margin: "0 10px",
            padding: "0px 10px",
            color: "#fff",
          }}
        >
          {getTranslateStatus(props?.status?.name)}
        </Box>
      </Box>

      <Box className="row d-flex justify-content-start align-items-start">
        <Box className="col-12 col-sm-12 col-md-6 col-lg-4 px-4">
          <ContainerOfInputFields
            mainTheme={maintheme}
            customeWidth={"100%"}
            isForm={true}
          >
            <Box
              sx={{
                position: "relative",
                // margin: "5px 0",
                width: "100%",
                backgroundColor: "#fff",
                // paddingRight:'10px'
              }}
              className="mt-3 orderdata"
            >
              <TextField
                id="standard-basic"
                variant="filled"
                label={t("REQUESTS.add_username")}
                value={props?.userName}
                InputProps={{ readOnly: true }}
                // placeholder="سيف يوسف سيف ..."
                type={"text"}
                focused={true}
              />
            </Box>
          </ContainerOfInputFields>
        </Box>
        <Box className="col-12 col-sm-12 col-md-6 col-lg-4 px-4">
          <ContainerOfInputFields
            mainTheme={maintheme}
            customeWidth={"100%"}
            isForm={true}
          >
            <Box
              sx={{
                position: "relative",
                // margin: "5px 0",
                width: "100%",
                backgroundColor: "#fff",
                // paddingRight:'10px'
              }}
              className="mt-3 orderdata"
            >
              <TextField
                id="standard-basic"
                variant="filled"
                label={t("REQUESTS.add_phone")}
                value={props?.phone}
                InputProps={{ readOnly: true }}
                // onChange={(e)=>handleChange(e,1,'phone')}
                // placeholder="07500111111"
                type={"text"}
                focused={true}
                className={`${ErrorsObject?.phone?.error ? "errors" : ""}`}
              />
              {/* {props?.phone ? (
                        <CloseIcon
                        className="closeIcon"
                        onClick={()=>clearInput(1,'phone')}
                        />
                        ) : null} */}
            </Box>
          </ContainerOfInputFields>
        </Box>

        <Box className="col-12 col-sm-12 col-md-12 col-lg-12 ">
          <Divider
            sx={{
              border: "1px solid #1e6a99 !important",
              margin: "20px auto",
              width: "93%",
            }}
          />
        </Box>
        <Box className="col-12 col-sm-12 col-md-6 col-lg-4 px-4">
          <ContainerOfInputFields
            mainTheme={maintheme}
            customeWidth={"100%"}
            isForm={true}
          >
            <Box
              sx={{
                position: "relative",
                // margin: "5px 0",
                width: "100%",
                backgroundColor: "#fff",
              }}
              className="mt-3 orderdata"
            >
              <TextField
                id="standard-basic"
                variant="filled"
                label={t("REQUESTS.requestNumber")}
                value={props?.object?.task_id}
                InputProps={{ readOnly: true }}
                // onChange={(e)=>handleChange(e,1,'phone')}
                // placeholder="07500111111"
                type={"text"}
                focused={true}
                // className={`${ErrorsObject?.phone?.error?'errors':''}`}
              />
              {/* {props?.phone ? (
                            <CloseIcon
                            className="closeIcon"
                            onClick={()=>clearInput(1,'phone')}
                            />
                            ) : null} */}
            </Box>

            <Box
              sx={{
                position: "relative",
                // margin: "5px 0",
                width: "100%",
                backgroundColor: "#fff",
              }}
              className="mt-3 orderdata"
            >
              <TextField
                id="standard-basic"
                variant="filled"
                label={t("REQUESTS.add_request_date")}
                value={
                  props?.requestDate
                    ? helpers.formatDate(props?.requestDate)
                    : ""
                }
                InputProps={{ readOnly: true }}
                // onChange={(e)=>handleChange(e,2,'customerName')}
                placeholder=""
                type={"text"}
                focused={true}
                // className={`${ErrorsObject?.customerName?.error?'errors':''} Mui-focused`}
              />
              {/* {props?.customerName ? (
                            <CloseIcon
                            className="closeIcon"
                            onClick={()=>clearInput(2,'customerName')}
                            />
                            ) : null} */}
            </Box>

            <Box
              sx={{
                position: "relative",
                // margin: "5px 0",
                width: "100%",
                backgroundColor: "#fff",
              }}
              className="mt-3 orderdata"
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label={t("REQUESTS.add_prefered_date")}
                  inputFormat="MM/DD/YYYY"
                  value={props?.preferedDate}
                  inputProps={{
                    placeholder: "لم يتم التحديد",
                  }}
                  readOnly={true}
                  onChange={(date) => handleChange(date, 5, "preferedDate")}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      focused={true}
                      className={`${
                        ErrorsObject?.preferedDate?.error ? "errors" : ""
                      }`}
                      InputProps={{
                        ...params.inputProps,
                        readOnly: true,
                        placeholder: "لم يتم التحديد",
                      }}
                    />
                  )}
                />
                {/* {props?.preferedDate ? (
                                <CloseIcon
                                    className="closeIcon"
                                    onClick={()=>clearInput(5,'preferedDate')}
                                    sx={{
                                        right:'37px !important',
                                        top:'14px !important'
                                    }}
                                />
                                ) : null} */}
              </LocalizationProvider>
            </Box>

            <Box
              sx={{
                position: "relative",
                // margin: "5px 0",
                width: "100%",
                backgroundColor: "#fff",
              }}
              className="mt-3 orderdata"
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label={t("REQUESTS.add_prefered_time")}
                  // inputFormat="MM/DD/YYYY"
                  value={props?.preferedTime}
                  inputProps={{
                    placeholder: "لم يتم التحديد",
                  }}
                  readOnly={true}
                  onChange={(date) => handleChange(date, 6, "preferedTime")}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      focused={true}
                      className={`${
                        ErrorsObject?.preferedTime?.error ? "errors" : ""
                      }`}
                      InputProps={{
                        ...params.inputProps,
                        readOnly: true,
                        placeholder: "لم يتم التحديد",
                      }}
                    />
                  )}
                />
                {/* {props?.preferedTime ? (
                                <CloseIcon
                                    className="closeIcon"
                                    onClick={()=>clearInput(6,'preferedTime')}
                                    sx={{
                                        right:'37px !important',
                                        top:'14px !important'
                                    }}
                                />
                                ) : null} */}
              </LocalizationProvider>
            </Box>

            {/* <Box
                            sx={{
                            position: "relative",
                            // margin: "5px 0",
                            width: "100%",
                            backgroundColor: "#fff",
                            }}
                            className="mt-3 orderdata"
                        >
                            <TextField
                            id="standard-basic"
                            variant="filled"
                            label={t("REQUESTS.add_status")}
                            value={getTranslateStatus(props?.status?.name)}
                            InputProps={{readOnly: true,}}
                            // onChange={(e)=>handleChange(e,2,'customerName')}
                            placeholder=""
                            type={'text'}
                            focused={true}
                            // className={`${ErrorsObject?.customerName?.error?'errors':''} Mui-focused`}
                            />
                        </Box> */}
          </ContainerOfInputFields>
        </Box>
        <Box className="col-12 col-sm-12 col-md-6 col-lg-4 px-4">
          <ContainerOfSelectField
            mainTheme={maintheme}
            customeWidth={"100%"}
            hasError={ErrorsObject?.requestType?.error}
            isForm={props?.requestType ? true : false}
          >
            <Box
              sx={{
                position: "relative",
                // margin: "10px 0",
                width: "100%",
                backgroundColor: "#fff",
              }}
              className="mt-3"
            >
              <Autocomplete
                //    disablePortal
                id="combo-box-demo"
                options={props?.requestTypes ? props?.requestTypes : []}
                value={props?.requestType}
                onChange={(e, newValue) =>
                  handleChange(newValue, 3, "requestType")
                }
                getOptionLabel={(option) => option?.name || ""}
                readOnly={true}
                popupIcon={<RiIcons.RiArrowDropDownLine />}
                clearIcon={
                  <CloseIcon
                    sx={{ fontSize: "20px", color: maintheme?.iconColor }}
                    onClick={() => clearInput(3, "requestType")}
                  />
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("REQUESTS.add_request_type")}
                    focused={true}
                    variant="filled"
                    InputProps={{ readOnly: true }}
                  />
                )}
                className={`Mui-focused`}
              />
            </Box>
          </ContainerOfSelectField>
          <ContainerOfInputFields
            mainTheme={maintheme}
            customeWidth={"100%"}
            isForm={true}
          >
            <Box
              sx={{
                position: "relative",
                // margin: "5px 0",
                width: "100%",
                backgroundColor: "#fff",
              }}
              className="mt-3 orderdata"
            >
              <TextField
                id="standard-basic"
                variant="filled"
                label={t("REQUESTS.add_location")}
                value={props?.requestLocation}
                InputProps={{ readOnly: true }}
                onChange={(e) => handleChange(e, 2, "requestLocation")}
                // placeholder="بغداد"
                type={"text"}
                focused={true}
                className={`${
                  ErrorsObject?.requestLocation?.error ? "errors" : ""
                }`}
              />
              {/* {props?.requestLocation ? (
                            <CloseIcon
                            className="closeIcon"
                            onClick={()=>clearInput(2,'requestLocation')}
                            />
                            ) : null} */}
            </Box>
          </ContainerOfInputFields>
          {props?.inprogressDateTime && props?.inprogressDateTime != null ? (
            <ContainerOfInputFields
              mainTheme={maintheme}
              customeWidth={screenwidth <= 992 ? "100%" : "100%"}
              isForm={true}
              // haswidth={true}
              // customePadding="0"
            >
              <Box
                sx={{
                  position: "relative",
                  // margin: "5px 0",
                  width: "100%",
                  backgroundColor: "#fff",
                }}
                className="mt-3 orderdata"
              >
                <TextField
                  id="standard-basic"
                  variant="filled"
                  label={t("REQUESTS.add_inprogress_dateTime")}
                  // value={props?.inprogressDateTime}
                  value={
                    props?.inprogressDateTime
                      ? helpers.formatDate(props?.inprogressDateTime) +
                        " " +
                        helpers.formatTime(props?.inprogressDateTime)
                      : ""
                  }
                  // onChange={(e)=>handleChange(e,4,'notes')}
                  placeholder=""
                  type={"text"}
                  InputProps={{
                    readOnly: true,
                  }}
                  focused={true}
                  className={`${ErrorsObject?.notes?.error ? "errors" : ""}`}
                />
                {/* {props?.notes ? (
                              <CloseIcon
                              className="closeIcon"
                              onClick={()=>clearInput(4,'notes')}
                              />
                              ) : null} */}
              </Box>
            </ContainerOfInputFields>
          ) : null}
          <ContainerOfInputFields
            mainTheme={maintheme}
            customeWidth={screenwidth <= 992 ? "100%" : "100%"}
            isForm={true}
            // haswidth={true}
            // customePadding="0"
          >
            {props?.closeingDateTime ? (
              <Box
                sx={{
                  position: "relative",
                  // margin: "5px 0",
                  width: "100%",
                  backgroundColor: "#fff",
                }}
                className="mt-3 orderdata"
              >
                <TextField
                  id="standard-basic"
                  variant="filled"
                  label={t("REQUESTS.add_closing_dateTIme")}
                  value={
                    props?.closeingDateTime
                      ? helpers.formatDate(props?.closeingDateTime) +
                        " " +
                        helpers.formatTime(props?.closeingDateTime)
                      : ""
                  }
                  // onChange={(e)=>handleChange(e,4,'notes')}
                  placeholder=""
                  type={"text"}
                  InputProps={{ readOnly: true }}
                  focused={true}
                  className={`${ErrorsObject?.notes?.error ? "errors" : ""}`}
                />
                {/* {props?.notes ? (
                          <CloseIcon
                          className="closeIcon"
                          onClick={()=>clearInput(4,'notes')}
                          />
                          ) : null} */}
              </Box>
            ) : null}
          </ContainerOfInputFields>

          <ContainerOfInputFields
            mainTheme={maintheme}
            customeWidth={"100%"}
            isForm={true}
            hasMultiLine={true}
          >
            <Box
              sx={{
                position: "relative",
                // margin: "5px 0",
                width: "100%",
                backgroundColor: "#fff",
              }}
              className="mt-3 orderdata"
            >
              <TextField
                id="standard-basic"
                variant="filled"
                label={t("REQUESTS.add_notes")}
                value={props?.notes}
                onChange={(e) => handleChange(e, 4, "notes")}
                placeholder="..."
                type={"text"}
                multiline
                InputProps={{ readOnly: true }}
                focused={true}
                className={`${ErrorsObject?.notes?.error ? "errors" : ""}`}
              />
              {/* {props?.notes ? (
                            <CloseIcon
                            className="closeIcon"
                            onClick={()=>clearInput(4,'notes')}
                            />
                            ) : null} */}
            </Box>
            {ErrorsObject?.notes?.error &&
            ErrorsObject?.notes?.message?.length ? (
              <Box
                sx={{
                  height: "fit-content",
                  padding: "5px",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  width: "95%",
                }}
              >
                {ErrorsObject?.notes?.message &&
                ErrorsObject?.notes?.message?.length > 0
                  ? ErrorsObject?.notes?.message?.map((messg) => (
                      <span
                        style={{
                          fontFamily: "Cairo-Bold",
                          fontSize: "14px",
                          color: "red",
                          height: "auto",
                        }}
                      >
                        {messg}
                      </span>
                    ))
                  : null}
              </Box>
            ) : null}
          </ContainerOfInputFields>
        </Box>
        <Box className="col-12 col-sm-12 col-md-6 col-lg-4 px-4">
          <ContainerOfInputFields
            mainTheme={maintheme}
            customeWidth={"100%"}
            isForm={true}
          >
            <Box
              sx={{
                position: "relative",
                // margin: "5px 0",
                width: "100%",
                height: "55px",
                backgroundColor: "#fff",
                boxShadow: "0 0 10px -2px rgba(0,0,0,0.2)",
                padding: "10px 15px",
                display: "flex",
                alignItems: "center",
              }}
              className="mt-3 orderdata"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  height: "100%",
                  width: "5px",
                  backgroundColor: "#1e6a99",
                  zIndex: "3223",
                }}
              ></Box>
              <span style={{ fontFamily: "Cairo-Medium", marginLeft: "10px" }}>
                التقييم
              </span>
              <Rating
                key={props?.object?.task_id}
                defaultValue={props?.ratingObj?.value}
                value={props?.ratingObj?.value}
                readOnly
              />
            </Box>
          </ContainerOfInputFields>

          <ContainerOfInputFields
            mainTheme={maintheme}
            customeWidth={"100%"}
            isForm={true}
          >
            <Box
              sx={{
                position: "relative",
                // margin: "5px 0",
                width: "100%",
                backgroundColor: "#fff",
              }}
              className="mt-3 orderdata"
            >
              <TextField
                id="standard-basic"
                variant="filled"
                label={"ملاحظات الزبون"}
                value={props?.ratingObj?.note}
                InputProps={{ readOnly: true }}
                // onChange={(e)=>handleChange(e,2,'requestLocation')}
                // placeholder="ملاحظات الزبون"
                type={"text"}
                focused={true}
                className={`${
                  ErrorsObject?.requestLocation?.error ? "errors" : ""
                }`}
              />
              {/* {props?.requestLocation ? (
                            <CloseIcon
                            className="closeIcon"
                            onClick={()=>clearInput(2,'requestLocation')}
                            />
                            ) : null} */}
            </Box>
            <Box
              sx={{
                position: "relative",
                // margin: "5px 0",
                width: "100%",
                backgroundColor: "#fff",
              }}
              className="mt-3 orderdata"
            >
              <TextField
                id="standard-basic"
                variant="filled"
                label={"اقتراحات الزبون"}
                value={props?.ratingObj?.sugg}
                InputProps={{ readOnly: true }}
                // onChange={(e)=>handleChange(e,2,'requestLocation')}
                // placeholder="اقتراحات الزبون"
                type={"text"}
                focused={true}
                className={`${
                  ErrorsObject?.requestLocation?.error ? "errors" : ""
                }`}
              />
              {/* {props?.requestLocation ? (
                            <CloseIcon
                            className="closeIcon"
                            onClick={()=>clearInput(2,'requestLocation')}
                            />
                            ) : null} */}
            </Box>
          </ContainerOfInputFields>
        </Box>
        <Box className="col-12 col-md-12 col-lg-12 col-xlg-12 my-3 px-6">
          <Box
            sx={{
              width: "95%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "0 20px",
              margin: "10px auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "10px 0",
                width: "100%",
              }}
            >
              <span
                style={{
                  width: "43%",
                  height: "1px",
                  backgroundColor: "#c1c1c1",
                }}
              ></span>
              <span style={{ fontFamily: "Cairo", fontWeight: "Bold" }}>
                الصور
              </span>
              <span
                style={{
                  width: "43%",
                  height: "1px",
                  backgroundColor: "#c1c1c1",
                }}
              ></span>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                padding: "0 40px",
                flexWrap: "wrap",
              }}
            >
              {props?.imageList &&
                props.imageList?.length > 0 &&
                props.imageList?.map((itm) =>
                  getExtension(itm?.url) || itm?.type == "image" ? (
                    <Box
                      sx={{
                        width: "200px !important",
                        height: "230px !important",
                        borderRadius: "5px",
                        //  border:'3px dashed green',
                        //  backgroundCOlor:'lightgrey',
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        borderRadius: "3px",
                        margin: "25px 10px",
                        background: "#ebebeb",
                        boxShadow:
                          "14px 14px 14px #d8d8d8,-14px -14px 14px #fefefe",
                        zIndex: 10,
                        //  flexGrow:'1',
                        //  flexBasis:'1',
                        "&:hover": {
                          transform: "scale(1.1)",
                        },
                        position: "relative",
                      }}
                    >
                      <img
                        src={itm?.url}
                        alt="task photo"
                        style={{
                          width: "100%",
                          height: "230px",
                          objectFit: "cover",
                          backgroundPosition: "center center",
                        }}
                        onClick={() => setSelectedImageToShow(itm)}
                      />

                      <Box
                        sx={{
                          position: "absolute",
                          bottom: "-55px",
                          right: 0,
                          zIndex: 23423,
                          width: "200px",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "Cairo",
                            color: "#000",
                            fontSize: "12px",
                            wordSpacing: "break-word",
                            wordBreak: "break-word",
                          }}
                        >
                          {itm?.name}
                        </span>
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        width: "200px !important",
                        height: "230px !important",
                        borderRadius: "5px",
                        //  border:'3px dashed green',
                        //  backgroundCOlor:'lightgrey',
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        borderRadius: "3px",
                        margin: "25px 10px",
                        background: "#ebebeb",
                        boxShadow:
                          "14px 14px 14px #d8d8d8,-14px -14px 14px #fefefe",
                        zIndex: 10,
                        //  flexGrow:'1',
                        //  flexBasis:'1',
                        "&:hover": {
                          transform: "scale(1.1)",
                        },
                        position: "relative",
                      }}
                    >
                      <a href={itm?.url}>
                        <img
                          src={
                            "https://cdn-icons-png.flaticon.com/512/1092/1092004.png"
                          }
                          alt="task photo"
                          style={{
                            width: "100%",
                            height: "230px",
                            objectFit: "cover",
                            backgroundPosition: "center center",
                          }}
                          // onClick={()=>setSelectedImageToShow(itm)}
                        />
                      </a>
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: "-55px",
                          right: 0,
                          zIndex: 23423,
                          width: "200px",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "Cairo",
                            color: "#000",
                            fontSize: "12px",
                            wordSpacing: "break-word",
                            wordBreak: "break-word",
                          }}
                        >
                          {itm?.name}
                        </span>
                      </Box>
                    </Box>
                  )
                )}
            </Box>
          </Box>
        </Box>
        <Box className="col-12 col-md-12 col-lg-12 col-xlg-12 my-3 px-6">
          <Box
            sx={{
              width: "95%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "0 20px",
              margin: "10px auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "10px 0",
                width: "100%",
              }}
            >
              <span
                style={{
                  width: "43%",
                  height: "1px",
                  backgroundColor: "#c1c1c1",
                }}
              ></span>
              <span style={{ fontFamily: "Cairo", fontWeight: "Bold" }}>
                التعليقات
              </span>
              <span
                style={{
                  width: "43%",
                  height: "1px",
                  backgroundColor: "#c1c1c1",
                }}
              ></span>
            </Box>
            <ViewComment data={props?.data} />
          </Box>
        </Box>
        <Box className="col-12 col-md-12 col-lg-12 col-xlg-12 my-3 px-6">
          <Box
            sx={{
              width: "95%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "0 20px",
              margin: "0 auto",
              // height:window.innerHeight-170,
              // overflowY:'scroll'
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "10px 0 20px 0",
                width: "100%",
              }}
            >
              <span
                style={{
                  width: "43%",
                  height: "1px",
                  backgroundColor: "#c1c1c1",
                }}
              ></span>
              <span style={{ fontFamily: "Cairo", fontWeight: "Bold" }}>
                {" "}
                {t("REQUESTS.units_list")}
              </span>
              <span
                style={{
                  width: "43%",
                  height: "1px",
                  backgroundColor: "#c1c1c1",
                }}
              ></span>
            </Box>
            <TableContainer component={Paper}>
              <Table sx={{ width: "100%" }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      {t("REQUESTS.add_unit_id")}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {t("REQUESTS.add_unit_name")}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {t("REQUESTS.add_unit_reasone")}
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.units?.length > 0 ? (
                    props.units.map((row, index) => (
                      <StyledTableRow key={row.name}>
                        <StyledTableCell component="th" scope="row">
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.reason}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell
                        component="th"
                        colSpan={3}
                        scope="row"
                        sx={{ fontFamily: "Cairo", textAlign: "center" }}
                      >
                        لا توجد بيانات
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>

        {selectedImage ? (
          <Box
            sx={{
              position: "fixed",
              width: window.innerWidth,
              height: window.innerHeight,
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backdropFilter: "blur(7px)",
              zIndex: 2342342,
            }}
          >
            <img
              src={selectedImage}
              alt="selected photo to show"
              style={{
                width: "600px",
                height: "600px",
                objectFit: "cover",
                backgroundPosition: "center center",
              }}
            />
            <CloseIcon
              sx={{
                position: "fixed",
                top: 10,
                left: 20,
                color: "#47aeed",
                fontSize: "40px",
                zIndex: 234234234,
                cursor: "pinter !important",
                "&:hover": {
                  color: "red",
                },
              }}
              onClick={() => setSelectedImage("")}
            />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

const RenderFooter = (props) => {
  const [t] = useTranslation("common");
  const gridtheme = useSelector((state) => state.themeData.gridtheme);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box display="flex">
          <Button
            variant="outlined"
            // spacing={2}
            sx={{
              width: "50% !important",
              // flexGrow: 1,
              minWidth: "80px !important",
              maxWidth: "80px !important",
              margin: 1,
              backgroundColor: "#d1d0d0",
              borderColor: gridtheme?.colorWhite,
              color: gridtheme?.colorblack,
              boxShadow: "0 0 7px -2px white",
              //   color:SearchButtonTheme?.clear_button_text_color,

              "&:hover": {
                backgroundColor: "#1e6A99",
                borderColor: gridtheme?.colorWhite,
                color: "#fff",
                boxShadow: "0 0 7px 1px white",
                boxShadow: "0",
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterClear"
            color="secondary"
            onClick={() => {
              props.setOpen(false);
              props.clearForm();
            }}
          >
            {t("GLOBAL.close")}
          </Button>
          {/* <Button
                      variant="contained"
                      // spacing={2} 
                      sx={{ 
                          // flexGrow: 1,
                          margin: 1,
                          width:'80px !important',
                          minWidth:'80px !important',
                          maxWidth:'80px !important',
                          '&:hover':{
                            //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
                          },
                          height:'35px',
                          fontFamily:'Cairo-Bold'
                      }}
                      className="iconeFilterSearch"
                      onClick={() => {
                            props.submit()
                      }} 
                      >
                      {t('GLOBAL.Save')}
                  </Button> */}
        </Box>
      </Box>
    </Box>
  );
};

export default function ViewRequest(props) {
  const [t] = useTranslation("common");
  const [name, setName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [ErrorsObject, setErrorsObject] = useState(ObjectErrors);
  const navigate = useNavigate();
  const globalLoading = useSelector(
    (state) => state.settingsData.globalLoading
  );
  const [phone, setPhone] = useState("");
  const [requestLocation, setRequestLocation] = useState("");
  const [requestType, setRequestType] = useState(null);
  const [requestTypes, setRequestTypes] = useState([]);
  const [unit, setUnit] = useState(null);
  const [units, setUnits] = useState([]);
  const [preferedDate, setPreferedDate] = useState(null);
  const [preferedTime, setPreferedTime] = useState(null);
  const [notes, setNotes] = useState("");
  const [customerName, setCustomerName] = useState(cookies.get("account_name"));
  const [userName, setUserName] = useState(cookies.get("user_name"));
  const [requestDate, setRequestDate] = useState(new Date());
  const [status, setStatus] = useState(null);
  const [statusList, setStatusList] = useState([]);
  const [inprogressDateTime, setInprogressDate] = useState(null);
  const [closeingDateTime, setcloseingDate] = useState(null);
  const [monitoredBy, setMonitoredBy] = useState(null);
  const [assignedBy, setAssignedBy] = useState(null);
  const [imageList, setImageList] = useState([]);
  const [comments, setComments] = useState([]);

  const [ratingObj, setRatingObj] = useState({
    value: 0,
    note: "",
    sugg: "",
  });

  const getNameOfImage = (file) => {
    const name = file.split("/");
    if (name && name?.length > 0) {
      return name[name?.length - 1];
    }
    return "";
  };

  const getTranslateStatus = (name) => {
    if (name == "new") return "جديد";
    else if (name == "in progress") return "قيد المعالجة";
    else if (name == "closed") return "مغلقة";
    else if (name == "rejected") return "ألغيت";
    else if (name == "approved") return "مكتملة";
    else if (name == "archived") return "مؤرشف";
    else if (name == "successful") return "مكتملة";
    else if (name == "canceled") return "ألغيت";
    else if (name == "failed") return "ألغيت";
    else return name;
  };
  const getColor = (request) => {
    if (request?.status_id == 1) {
      return "rgba(97, 172, 232,.8)";
    }
    if (request?.status_id == 2) {
      return "#FDD835";
    }
    if (request?.status_id == 3) {
      if (request?.close_status_id == 1) {
        return request?.close_status_color;
      }
      return "rgba(198, 40, 40,0.8)";
    }
    if (request?.status_id == 4) {
      return "rgba(198, 40, 40,0.8)";
    }
    if (request?.status_id == 5 && request?.close_status_id == 1) {
      return "rgb(0,191,61)";
    } else if (request?.status_id == 5 && request?.close_status_id != 1) {
      return "rgba(198, 40, 40,0.8)";
    }
    if (request?.status_id == 6) {
      return "rgba(27, 94, 32,0.8)";
    }
    return "lightgrey";
  };

  useEffect(() => {
    if (props?.object && props?.object?.task_id) {
      getData();
      getDataOfSelectedTask(props?.object?.task_id);
    }
  }, [props?.addForm, props?.object]);

  const getExtension = (file) => {
    const extension = file.split(".").pop();

    if (
      extension == "jpeg" ||
      extension == "jpg" ||
      extension == "png" ||
      extension == "gif" ||
      extension == "svg"
    ) {
      return true;
    }
    return false;
  };
  const getDataOfSelectedTask = async (id) => {
    let result = null;
    try {
      let params = {
        username: cookies.get("user_name"),
        user_id: cookies.get("user_id"),
        account_name: cookies.get("account_name"),
        account_id: cookies.get("account_id"),
        sid: cookies.get("sid"),
        baseurl: cookies.get("baseUrl"),
      };
      result = await requestAPI.ShowRequestTask({
        params: params,
        id: id,
      });

      if (result?.status && result?.data?.status) {
        setPhone(result?.data?.data?.task?.user_mobile);
        setRequestLocation(result?.data?.data?.task?.request_location);
        setRequestType(result?.data?.data?.request_type);
        setNotes(result?.data?.data?.task?.note);
        setPreferedDate(
          result?.data?.data?.task?.prefered_date
            ? helpers.formatDate(result?.data?.data?.task?.prefered_date)
            : null
        );
        setPreferedTime(
          result?.data?.data?.task?.prefered_time &&
            result?.data?.data?.task?.prefered_date
            ? new Date(
                helpers.formatDate(result?.data?.data?.task?.prefered_date) +
                  " " +
                  result?.data?.data?.task?.prefered_time
              )
            : null
        );
        let arrImg = [];
        result?.data?.data?.files &&
          result?.data?.data?.files?.length > 0 &&
          result?.data?.data?.files?.map((itm, index) => {
            let checkImage = getExtension(itm?.file_path);
            arrImg.push({
              url: APP_CONSTANTS.API_URL_IMG + itm?.file_path,
              file: null,
              index: index,
              type: checkImage ? "image" : "file",
              name: itm?.name ? itm?.name : getNameOfImage(itm?.file_path),
            });
          });
        setImageList(arrImg);
        setUnits(result?.data?.data?.units);
        setStatus(result?.data?.data?.status);
        setInprogressDate(result?.data?.data?.inprogress?.created_at);
        setcloseingDate(result?.data?.data?.closed?.created_at);
        console.log("sdasdasdas", result?.data?.data?.task?.rate_score);
        setRatingObj({
          value: result?.data?.data?.task?.rate_score,
          note: result?.data?.data?.task?.rate_text,
          sugg: result?.data?.data?.task?.suggestions,
        });

        setLoading(false);
        // toast.success(t('NETWORKMESSAGE.addCustomerMessageSuccess'))
      } else {
        setLoading(false);
        toast.error(t("NETWORKMESSAGE.addCustomerMessageError"));
        if (result && result?.data && result?.data?.data?.message) {
          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };
  const clearForm = () => {
    setPhone("");
    setRequestLocation("");
    setRequestType(null);
    setUnit([]);
    // setListOfSelectedRows([])
    setPreferedDate(null);
    setPreferedTime(null);
    setImageList([]);
    setNotes("");
  };
  const getData = async () => {
    if (!props?.object || !props.object.id) {
      toast.warn(t("REQUESTS.youMustSelectTask"));
      return;
    }
    let params = {
      username: cookies.get("user_name"),
      user_id: cookies.get("user_id"),
      account_name: cookies.get("account_name"),
      account_id: cookies.get("account_id"),
      sid: cookies.get("sid"),
      baseurl: cookies.get("baseUrl"),
    };
    try {
      let data = await requestAPI.comments({
        params: params,
        data: {
          task_id: props?.object?.task_id,
        },
      });
      if (data && data?.status) {
        setComments(data?.data?.data);
      } else {
        toast.warn(t("NETWORKMESSAGE.deleteErrorr"));
      }
    } catch (err) {
      console.log(err?.message);
    }
  };

  return (
    <>
      {globalLoading ? <Preloading dashboardMode={true} /> : null}

      <PopupForm
        open={props.addForm}
        setOpen={props.setAddForm}
        title={t("REQUESTS.viewRequest")}
        isFullScreen={true}
        content={
          <RenderContent
            phone={phone}
            setPhone={setPhone}
            requestLocation={requestLocation}
            setRequestLocation={setRequestLocation}
            requestType={requestType}
            setRequestType={setRequestType}
            requestTypes={requestTypes}
            unit={unit}
            setUnit={setUnit}
            units={units}
            setUnits={setUnits}
            preferedDate={preferedDate}
            setPreferedDate={setPreferedDate}
            preferedTime={preferedTime}
            setPreferedTime={setPreferedTime}
            notes={notes}
            setNotes={setNotes}
            customerName={customerName}
            userName={userName}
            requestDate={requestDate}
            status={status}
            setImageList={setImageList}
            imageList={imageList}
            inprogressDateTime={inprogressDateTime}
            closeingDateTime={closeingDateTime}
            clearForm={clearForm}
            open={props.addForm}
            setOpen={props.setAddForm}
            isFullScreen={true}
            ratingObj={ratingObj}
            object={props?.object}
            data={comments}
            setAddForm={props?.setAddForm}
          />
        }
        footer={
          <RenderFooter
            open={props.addForm}
            setOpen={props.setAddForm}
            submit={null}
            clearForm={clearForm}
          />
        }
      />
    </>
  );
}
