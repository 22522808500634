import callAPI from '../ApiConfig'
import store from '../../app/store'

const profile = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"wialon_customers/get_profile",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        Headers:{
            'Content-Type':'application/json',
            Accept:'application/json'
        }
    })
    
}
const updateProfile = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"wialon_customers/update_profile",
        method:"put",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        Headers:{
            'Content-Type':'multipart/form-data',
            Accept:'application/json'
        }
    })
    
}
const getConfigurationData = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"wialon_customers/configuration",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        Headers:{
            'Content-Type':'multipart/form-data',
            Accept:'application/json'
        }
    })
    
}

const getUserEmails = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"user_emails",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        Headers:{
            'Content-Type':'application/json',
            Accept:'application/json'
        }
    })
    
}
const getUserEmailsList= async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"user_emails/list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        Headers:{
            'Content-Type':'application/json',
            Accept:'application/json'
        }
    })
    
}
const createUserEmails = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"user_emails",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        Headers:{
            'Content-Type':'application/json',
            Accept:'application/json'
        }
    })
    
}
const updateUserEmails = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"user_emails/"+props?.data?.user_email_id,
        method:"put",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        Headers:{
            'Content-Type':'application/json',
            Accept:'application/json'
        }
    })
    
}
const deleteUserEmails = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"user_emails/"+props?.data?.user_email_id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        Headers:{
            'Content-Type':'application/json',
            Accept:'application/json'
        }
    })
    
}

const handleShareTask = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"user_emails/share_task",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        Headers:{
            'Content-Type':'application/json',
            Accept:'application/json'
        }
    })
    
}





const profileAPI={
    Profile:profile,
    UpdateProfile:updateProfile,
    getConfigurationData:getConfigurationData,
    getUserEmails:getUserEmails,
    updateUserEmails:updateUserEmails,
    createUserEmails:createUserEmails,
    getUserEmailsList:getUserEmailsList,
    deleteUserEmails:deleteUserEmails,
    handleShareTask:handleShareTask
}
export default profileAPI