import callAPI from '../ApiConfig'
import store from '../../app/store'

const dashboard = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"wialon_customers/dashboard",
        method:"get",
        signal:props?.signal,
        params:props?.params,
    })
    
}




const dashboardAPI={
    dashboard:dashboard,
}
export default dashboardAPI