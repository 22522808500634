
import './App.css';
import React, { useEffect, useState } from "react";
import LandingPage from './Pages/LandingPage';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import $ from 'jquery'
import Cookies from "universal-cookie";
import Tasks from './Pages/Tasks';
import SideMenu from './SideMenu/SideMenu';
import { I18nextProvider } from 'react-i18next';
import { CacheProvider } from '@emotion/react';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import {Box} from '@mui/material'
import {useSelector,useDispatch} from 'react-redux'
import i18next from "i18next";
import Context from "./assets/js/context";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/test1.css";
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import { setUserToken } from "./reduxStore/UserReducer";
import { setscreenwidth } from "./reduxStore/SettingsReducer";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";
import ProviderRedirect from './Components/ProviderRedirect';
import Profile from './Pages/Progfile/Profile';
import Request from './Pages/Requests/Request';
import Dashboard from './Pages/Dashboard/Dashboard';
import { useNavigate } from 'react-router-dom';
import SessionEnd from './Pages/SessionEnd';
import SorryPage from './Pages/SorryPage';
import PageNotFound from './Pages/PageNotFound';
import Units from './Pages/Units/Units';
// const Request = React.lazy(() => import('./Pages/Requests/Request'))
// const Dashboard = React.lazy(() => import('./Pages/Dashboard/Dashboard'))
const cookies = new Cookies();

const MINUTE_MS = 60000;
function App() {


  useEffect(() => {
    const interval = setInterval(() => {
      const baseUrl = cookies.get('baseUrl')
      const sid = cookies.get('sid')
      const authHash = cookies.get('authHash')
      console.log('baseUrl', baseUrl);
      console.log('sid', sid);
      console.log('authHash', authHash);
      if (baseUrl && sid && authHash) {
        console.log('Logs every minute');
        $.ajax({
          type: "GET",
          enctype: "application/json",
          processData: !1,
          contentType: !1,
          crossDomain: true,
          dataType: "jsonp",
          url: baseUrl + "/avl_evts?sid=" + sid,
          success: function (data) {
            if (data?.tm) {
              // cookies.set('active_session', 'yes');
              cookies.set('active_session', 'yes', { path: '/' });

            }
            else if (data?.error) {
              // cookies.set('active_session', 'no')
              // cookies.set('sid',null)
              // cookies.set('baseUrl',null)
              cookies.set('active_session', 'no', { path: '/' });
              cookies.set('sid', null, { path: '/' });
              // cookies.set('baseUrl', null, { path: '/' });

              // cookies.remove('sid')
              // cookies.remove('baseUrl')
              // window.location.href='/'
            }
            console.log(data);
          }
          //   url: "https://hst-api.wialon.com/wialon/ajax.html?" + svc + "&params=" + params + "&sid=" + readCookie("eid"),
          // url: baseUrl+"/wialon/ajax.html?" + svc + "&params=" + params + "&sid=" + readCookie("eid"),
        })
      }
      else {
        cookies.set('active_session', 'no')
      }

    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])

  const [state] = useState({
    data: [],
    ms: [],
    data1: [],
    Dash: [],
    check: "",
    che: "login",
    new: "",
    inprogress: "",
    closed: "",
    approved: "",
    rejected: "",
    archived: "",
    noti: "",
    desc: "",
    commenter: "",
    body: "",
    title: "",
    SiFilter: [],
  });
  const directions = useSelector((state) => state.settingsData.directions);
  const languageCode = useSelector((state) => state.settingsData.code);

  const Translate_ar = useSelector(
    (state) => state.translateData.translation_ar
  );
  const Translate_en = useSelector(
    (state) => state.translateData.translation_en
  );
  const maintheme = useSelector((state) => state.themeData.maintheme);
  const dispatch = useDispatch();
  const token = cookies.get("token");
  useEffect(() => {
    if (token) {
      dispatch(setUserToken(token));
    }
  }, [token]);

  const [theme, setTheme] = useState(
    createTheme({
      direction: directions, // Both here and <body dir="rtl">
    })
  );

  try {
    const bodyWeb = document.querySelector("body");
    if (bodyWeb) {
      bodyWeb.style.background = maintheme?.background;
    }
    i18next.init({
      interpolation: { escapeValue: false }, // React already does escaping
      lng: languageCode,
      resources: {
        ar: {
          common: Translate_ar,
        },
        en: {
          common: Translate_ar,
        },
     
      },
    });
  } catch (error) {
    console.log(error?.message);
  }

  useEffect(() => {
    localStorage.setItem("direction", directions);
    if (directions === "rtl") {
      // setCacheRtl(
      //   createCache({
      //     key: 'muirtl',
      //     stylisPlugins: [rtlPlugin,prefixer],
      //    })
      // )
      document.dir = "rtl";
      const htmlTag = document.querySelector("html");
      if (htmlTag) {
        htmlTag.lang = "ar";
      }
      cookies.set("language", "ar");
    } else {
      // setCacheRtl(createCache({ key: 'css' }))
      document.dir = "ltr";
      cookies.set("language", "en");
    }

    setTheme(
      createTheme({
        direction: directions, // Both here and <body dir="rtl">
      })
    );
  }, [directions]);

  const isrtl = React.useMemo(() => {
    return createCache({
      key: directions === "rtl" ? "muirtl" : "muiltr",
      stylisPlugins: directions === "rtl" ? [rtlPlugin] : [],
    });
  }, [directions]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      dispatch(setscreenwidth(window.innerWidth));
    });
  }, []);



  return (
    <Box
    sx={{
      width: "100%",
      height: "100%",
    }}
  >
    <I18nextProvider i18n={i18next}>
      <CacheProvider value={isrtl}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <ToastContainer
              containerId="container_toast_id"
              position="top-center"
              autoClose={5000}
              hideProgressBar={true}
              newestOnTop={false}
              transition={Slide}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <Context.Provider
              value={{
                value: state,
                action: {},
              }}
            >
              <Routes>
                  <Route path="/" element={<LandingPage/>}/>
                  <Route path='/system' element={<SideMenu/>}>
                      <Route
                        path={"dashboard"}
                        element={<React.Suspense fallback={<LandingPage/>}>
                            <ProviderRedirect><Dashboard /></ProviderRedirect>
                          </React.Suspense>}
                      />
                      <Route
                        path={"requests"}
                        element={<React.Suspense fallback={<LandingPage/>}>
                          <ProviderRedirect>
                                <Request/>
                          </ProviderRedirect>
                          </React.Suspense>}
                      />
                      <Route
                        path={"profile"}
                        element={<React.Suspense fallback={<LandingPage/>}>
                          <ProviderRedirect>
                                <Profile/>
                          </ProviderRedirect>
                          </React.Suspense>}
                      />
                      <Route
                        path={"units"}
                        element={<React.Suspense fallback={<LandingPage/>}>
                          <ProviderRedirect>
                                <Units/>
                          </ProviderRedirect>
                          </React.Suspense>}
                      />
                      <Route
                        path={"tasks"}
                        element={<Tasks />}
                      />
                  </Route>
                  <Route path='/redirect' element={<SessionEnd/>}/>
                  <Route path='/sorry' element={<SorryPage/>}/>
                  <Route path="*" element={<PageNotFound />} />
              
              </Routes>
          </Context.Provider>
            </BrowserRouter>
          </ThemeProvider>
        </CacheProvider>
      </I18nextProvider>
    </Box>
  )

}

export default App;
