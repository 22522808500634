import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from '@mui/icons-material/Close';
import { Box } from "devextreme-react";

export default function ImagePreview(props) {

    const handleClose=()=>{
        props.setOpen(false)
    }
  return (
    <Box>
      <Dialog
        open={props?.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
            width:'100%',
            height:'auto',
            '& .MuiPaper-root':{
                width:'fit-content',
                height:'fit-content',
                // maxWidth:'70% !important',
                position:'relative',
                backgroundColor:'transparent',
                boxShadow:'0'
            }
        }}
        BackdropProps={{
            style: {
                background: 'rgba(255, 255, 255, 0.1)',
                backdropFilter: 'blur(3px)',
            }
        }}

      >
        {/* <DialogTitle id="alert-dialog-title"> */}
            <CloseIcon sx={{color:'#000',fontSize:'45px',cursor:'pointer'}} onClick={handleClose}/>
        {/* </DialogTitle> */}
        {/* <DialogContent> */}
            <img
               src={props?.src}
               style={{
                width:'600px',
                height:'600px',
                objectFit:'cover',
                backgroundPosition:'center center',
               }}
            />
        {/* </DialogContent> */}
       
      </Dialog>
    </Box>
  );
}
