import React ,{useState,useEffect} from 'react'
import ReactApexChart from 'react-apexcharts'
import {useNavigate} from 'react-router-dom'
import StarIcon from '@mui/icons-material/Star';

export default function StarsChart(props) {
    console.log("stars",props);
    const navigate=useNavigate()
    const [state,setState]=useState({
        series: [{
            name:'Star Rating',
            data: props?.rate_scores
        }],
        options: {
            chart: {
                height: 350,
                type: 'bar',
                events: {
                    click: function(event, chartContext, config) {
                        handleBarClicked(event, chartContext, config)
                    }
                },
                toolbar: {
                show: false
                }
            },
            colors: ['#A19B9B','#B71C1C','#D32F2F','#FF6F00','#DCE775','#F9E53D'],
            plotOptions: {
                bar: {
                columnWidth: '35%',
                distributed: true,
                }
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false
            },
            xaxis: {
                categories: [
                    'غير مقيم',
                    'نجمة واحدة',
                    'نجمتان',
                    'ثلاث نجمات',
                    'اربع نجمات',
                    'خمس نجمات',

                ],
                labels: {
                style: {
                    colors: ['#A19B9B','#B71C1C','#D32F2F','#FF6F00','#DCE775','#F9E53D'],
                    fontSize: '12px',
                    fontFamily:'Cairo'
                }
                }
            },
            yaxis:{
                labels: {
                    show: true,
                    formatter: (value) => { return Math.round(parseInt(value)) },
                },
            }
        },
    })
    useEffect(()=>{
        let obj={}
        if(props?.dashboardData&&props?.dashboardData?.rate_scores&&props?.dashboardData?.rate_scores?.length){
            obj={
                name:'Star Rating',
                data: props?.dashboardData?.rate_scores
            }
        }
        setState({
            ...state,
            series:[{
                ...obj
            }],
        })
    },[props?.dashboardData])
    const handleBarClicked=(event, chartContext, config)=>{
        // console.log('sadasdasdasdsadasdas',event)
        // console.log('sadasdasdasdsadasdas',chartContext)
        console.log('sadasdasdasdsadasdas',config)
 
        if(config?.dataPointIndex>-1){
            if(config?.dataPointIndex==0){
              props?.handleEWvalutionsRequest()
              localStorage.removeItem('isUseNavigation');
            }else{
                navigate('/system/requests', {
                    state: {
                      data: {
                        rate_score:config?.dataPointIndex
                      }
                    }
                  });
                localStorage.removeItem('isUseNavigation');
            }
       }
     }

  return (
    <div id="chart" style={{
        width:'100%',
      }}>
            <ReactApexChart 
              options={state.options} 
              series={state.series} 
              type="bar" 
              height={300} 
              width={'100%'}
            />
      </div>
  )
}
