import callAPI from '../ApiConfig'
import store from '../../app/store'

const status = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"wialon_customers/statuses",
        method:"get",
        signal:props?.signal,
        params:props?.params,
    })
    
}




const statusAPI={
    Status:status,
}
export default statusAPI