import React from 'react'
import './Preload.css'
import $ from 'jquery'
import Cookies from 'universal-cookie'
import { Box } from '@mui/material';
const cookies=new Cookies();

export default function Preload({dashboardMode}) {

    const handleSession=()=>{
        let data=null;
        try{
            // data=await axios({
            //     method:'get',
            //     url:'',
            // })

            $.ajax({
                type: "GET",
                enctype: "application/json",
                processData: !1,
                contentType: !1,
                crossDomain: true,
                dataType: "jsonp",
                url: 'https://hst-api.wialon.com/wialon/ajax.html?svc=token/login&params={"token":"33608235a85b6bbbe9fe4a5a0fcd16f23E5B1BA2717EFA0B525D7C38B1B1D8580752D858","fl":1}',
                success: function (data) {

                    console.log('allunits', data);
                    // cookies.set('sid',data?.eid)
                    cookies.set('sid', data?.eid, { path: '/' });
                    cookies.set('baseUrl','https://hst-api.wialon.com', { path: '/' });

                    // setUnits(data?.items?.length > 0 ? data?.items : [])
                }
            })
            // if(data&&data?.data){
            //     console.log('sadasdas',data)
            //     cookies.set('sid',data?.data?.eid)
            // }
        }catch(e){
            console.log(e.messages)
        }
     }
  return (
    <div 
      style={{
        width:'100vw',
        height:'100vh',
        position:'fixed',
        zIndex:234223,
        top:0,
        left:0,
        right:0,
        bottom:0,
        backdropFilter:'blur(5px)',
        display:'flex',
        justifyContent: 'center',
        alignItems:'center',
        overflow:'hidden',
        backgroundColor:dashboardMode?'transparent':'#045980'
    }}
    >
      {/* <Box className="btn btn-primary text-light position-fixed" sx={{top:window.location.pathname=="/system/dashboard"?'-54px':'04px',left:'6px',position:'absolute',zIndex:9999999}} onClick={handleSession}>Get New Session SID</Box> */}

        <div className="spinner-box">
            <div className="leo-border-1">
                <div className="leo-core-1"></div>
            </div> 
            <div className="leo-border-2">
                <div className="leo-core-2"></div>
            </div> 
        </div>

    </div>
  )
}
