import {
  Box,
  TextField,
  Button,
  Autocomplete,
  Rating,
  Divider,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import PopupForm from "../../Components/PopupForm";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Cookies from "universal-cookie";
import Preloading from "../Preload/Preloading";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import helpers from "../../assets/js/helper";
import requestAPI from "../../Network/Request";
import { toast } from "react-toastify";
import CommetCard from "./CommetCard";
import CloseIcon from "@mui/icons-material/Close";
import ImageList from "./ImageList";

const cookies = new Cookies();
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1E6A99",
    color: theme.palette.common.white,
    fontFamily: "Cairo-Bold !important",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const APP_CONSTANTS = {
  API_URL_IMG: `${process.env.REACT_APP_API_URL_image}`,
};

const ViewComment = (props) => {
  const [t] = useTranslation("common");
  const [selectedImages, setSelectedImages] = useState([]);
  const [showImage, setShowImage] = useState(false);

  return (
    <Box
      sx={{
        maxHeight: "400px",
        overflowY: "auto",
        width:'100%'
      }}
    >
      {props?.data && props?.data?.length > 0 ? (
        props?.data?.map((row) => (
          <CommetCard
            {...row}
            setSelectedImages={setSelectedImages}
            setShowImage={setShowImage}
          />
        ))
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "Cairo",
          }}
        >
          {t("REQUESTS.noData")}
        </Box>
      )}
      <ImageList
        addForm={showImage}
        setAddForm={setShowImage}
        images={selectedImages}
      />
    </Box>
  );
};
export default ViewComment;
