import callAPI from '../ApiConfig'
import store from '../../app/store'

const requestTypes = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"wialon_customers/request_types",
        method:"get",
        signal:props?.signal,
        params:props?.params,
    })
    
}
const getRequesTypestList = async (props) => {
    const storeState = store.getState();

    return await callAPI({
        route:"wialon_customers/request_types",
        method:"get",
        signal:props?.signal,
        params:props?.params,
    })
    
}



const requestTypeAPI={
    RequestTypes:requestTypes,
    GetRequesTypestList:getRequesTypestList,

}
export default requestTypeAPI