import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Cookies from "universal-cookie";
import profileAPI from "../../Network/Profile";
import { useDispatch } from "react-redux";
import { setGlobalLoading } from "../../reduxStore/SettingsReducer";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import DeleteIcone from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcone from "@mui/icons-material/Add";
import DropDownGrid from "../Grid/Components/DropDownGrid";
import { Box, IconButton, ListItemIcon, MenuItem } from "@mui/material";
import AddUserEmail from "./AddUserEmail";
import swal from "sweetalert";

const cookies = new Cookies();
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1e6a99",
    color: theme.palette.common.white,
    fontFamily: "Cairo",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function UserEmails() {
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("common");
  const [addForm, setAddForm] = useState(false);
  const [selectedObj, setSelectedObj] = useState(null);

  const getUserEmailsData = async () => {
    let data = null;
    try {
      let params = {
        username: cookies.get("user_name"),
        user_id: cookies.get("user_id"),
        account_name: cookies.get("account_name"),
        account_id: cookies.get("account_id"),
        sid: cookies.get("sid"),
        baseurl: cookies.get("baseUrl"),
      };
      dispatch(setGlobalLoading(true));
      data = await profileAPI.getUserEmailsList({
        params: params,
      });
      dispatch(setGlobalLoading(false));
      if (data && data?.status) {
        if (data?.data && data?.data?.data) {
          setRows(data?.data?.data);
        }
        if (data && data?.data && data?.data?.data?.message) {
          if (data?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }
        }
      } else {
        toast.warning(t("NETWORKMESSAGE.addCustomerMessageError"));
        if (data && data?.data && data?.data?.data?.message) {
          if (data?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }
        }
      }
    } catch (e) {
      dispatch(setGlobalLoading(false));
      console.log(e?.message);
    }
  };
  useEffect(() => {
    getUserEmailsData();
  }, []);
  const updateFunction = (row) => {
    setSelectedObj(row);
    setAddForm(true);
  };
  //   const deleteFunction = (row) => {
  //     setSelectedObj(row);
  //     setAddForm(true)
  //   };
  const deleteFunction = async (obj) => {
    swal({
      title: "?هل انت متئكد",
      text: "لن تتمكن من التراجع عن هذا!",
      icon: "warning",
      buttons: ["الغاء!", "نعم!"],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          dispatch(setGlobalLoading(true));
          let result = null;
          if (obj && obj?.id) {
            let params = {
              username: cookies.get("user_name"),
              user_id: cookies.get("user_id"),
              account_name: cookies.get("account_name"),
              account_id: cookies.get("account_id"),
              sid: cookies.get("sid"),
              baseurl: cookies.get("baseUrl"),
            };
            result = await profileAPI.deleteUserEmails({
              data: {
                user_email_id: obj.id,
              },
              params: params,
            });
          }

          if (result?.status) {
            dispatch(setGlobalLoading(false));
            toast.success(t("NETWORKMESSAGE.deleteSuccess"));
            swal(`${t("NETWORKMESSAGE.deleteSuccess")}`, {
              icon: "success",
            });
            getUserEmailsData();
          } else {
            dispatch(setGlobalLoading(false));
            toast.error(t("NETWORKMESSAGE.deleteErrorr"));
          }
          dispatch(setGlobalLoading(false));
        } catch (error) {
          dispatch(setGlobalLoading(false));
          toast.error(t(error?.message));
        }
      } else {
        // alert('canceled')
      }
    });
  };

  return (
    <>
      {addForm ? (
        <AddUserEmail
          object={selectedObj}
          addForm={addForm}
          setAddForm={setAddForm}
          getData={getUserEmailsData}
        />
      ) : null}
      <Box
        sx={{
          gap: 3,
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <span
          style={{
            fontFamily: "Cairo",
            fontSize: "20px",
          }}
        >
          {t("PROFILE.userEmailsTitle")}
        </span>
        <IconButton
          sx={{
            backgroundColor: "#f3f3f3",
          }}
          onClick={() => {
            setAddForm(true);
            setSelectedObj(null);
          }}
        >
          <AddIcone sx={{ color: "#1e6a99" }} />
        </IconButton>
      </Box>
      <Box mt={1}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>#</StyledTableCell>
                <StyledTableCell>{t("PROFILE.userEmailName")}</StyledTableCell>
                <StyledTableCell>{t("PROFILE.userEmailEmail")}</StyledTableCell>
                <StyledTableCell>
                  {t("PROFILE.userEmailAction")}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows && rows?.length ? (
                rows?.map((row, index) => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell component="th" scope="row">
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell>{row.name}</StyledTableCell>
                    <StyledTableCell>{row.email}</StyledTableCell>
                    <StyledTableCell>
                      <DropDownGrid>
                        <MenuItem
                          onClick={() => {
                            updateFunction(row);
                          }}
                          className="ActionIcons"
                        >
                          <ListItemIcon
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              margin: "0px 7px 0 0",
                            }}
                          >
                            <EditIcon style={{ fill: "#1E6A99" }} />
                          </ListItemIcon>
                          {t("GLOBAL.action_edit")}
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            deleteFunction(row);
                          }}
                          className="ActionIcons"
                        >
                          <ListItemIcon
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              margin: "0px 7px 0 0",
                            }}
                          >
                            <DeleteIcone style={{ fill: "#ff0000" }} />
                          </ListItemIcon>
                          {t("GLOBAL.action_delete")}
                        </MenuItem>
                      </DropDownGrid>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <tr  style={{
                  height:'50px'
                }}
                >
                  <td colSpan={4} style={{width:'100%',textAlign:'center',fontFamily:'Cairo'}}>لا توجد بيانات</td>
                </tr>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}
