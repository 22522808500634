import {
  Box,
  TextField,
  Button,
  Autocomplete,
  Rating,
  Divider,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import PopupForm from "../../Components/PopupForm";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Cookies from "universal-cookie";
import Preloading from "../Preload/Preloading";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import helpers from "../../assets/js/helper";
import requestAPI from "../../Network/Request";
import { toast } from "react-toastify";
import CommetCard from "./CommetCard";
import CloseIcon from "@mui/icons-material/Close";

const cookies = new Cookies();

const RenderContent = (props) => {
  const [t] = useTranslation("common");

  return (
    <Box className="Container-fluid position-relative">
      <Box
        sx={{
          position: "fixed",
          zIndex: 299999,
          top: 10,
          right: 20,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "35px",
          height: "35px",
          backgroundColor: "#fff",
          borderRadius: "50%",
          boxShadow: "0 0 7px -2px #000",
          cursor: "pointer",
        }}
        onClick={() => props?.setAddForm(false)}
      >
        <CloseIcon sx={{ color: "#1e6a99" }} />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "0 20px",
          // height:window.innerHeight-100
        }}
      >
        <div
          id="carouselExampleControls"
          class="carousel slide"
          data-bs-ride="carousel"
          style={{
            // height:'400px'
          }}
        >
          <div class="carousel-inner"
            style={{
               height:window.innerHeight-100
            }}
          >
            {props?.images && props?.images?.length > 0
              ? props?.images?.map((itm, index) =>
                  index == 0 ? (
                    <div class="carousel-item active">
                      <img src={process.env.REACT_APP_API_URL_image+itm} class="d-block w-100" alt="..." />
                    </div>
                  ) : (
                    <div class="carousel-item">
                      <img src={process.env.REACT_APP_API_URL_image+itm} class="d-block w-100" alt="..." />
                    </div>
                  )
                )
              : null}
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="prev"
            // style={{
            //   backgroundColor:'#fff',
            //   boxShadow:'0 0 7px -2px #000',
            //   borderRadius:'50%',
            //   width:'50px',
            //   height:'50px'
            // }}
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="next"
            // style={{
            //   backgroundColor:'#fff',
            //   boxShadow:'0 0 7px -2px #000',
            //   borderRadius:'50%',
            //   width:'50px',
            //   height:'50px'
            // }}
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </Box>
    </Box>
  );
};

const RenderFooter = (props) => {
  const [t] = useTranslation("common");
  const gridtheme = useSelector((state) => state.themeData.gridtheme);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box display="flex">
          <Button
            variant="outlined"
            // spacing={2}
            sx={{
              width: "50% !important",
              // flexGrow: 1,
              minWidth: "80px !important",
              maxWidth: "80px !important",
              margin: 1,
              backgroundColor: "#d1d0d0",
              borderColor: gridtheme?.colorWhite,
              color: gridtheme?.colorblack,
              boxShadow: "0 0 7px -2px white",
              //   color:SearchButtonTheme?.clear_button_text_color,

              "&:hover": {
                backgroundColor: "#1e6A99",
                borderColor: gridtheme?.colorWhite,
                color: "#fff",
                boxShadow: "0 0 7px 1px white",
                boxShadow: "0",
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterClear"
            color="secondary"
            onClick={() => {
              props.setOpen(false);
              props.clearForm();
            }}
          >
            {t("GLOBAL.close")}
          </Button>
          {/* <Button
                      variant="contained"
                      // spacing={2} 
                      sx={{ 
                          // flexGrow: 1,
                          margin: 1,
                          width:'80px !important',
                          minWidth:'80px !important',
                          maxWidth:'80px !important',
                          '&:hover':{
                            //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
                          },
                          height:'35px',
                          fontFamily:'Cairo-Bold'
                      }}
                      className="iconeFilterSearch"
                      onClick={() => {
                            props.submit()
                      }} 
                      >
                      {t('GLOBAL.Save')}
                  </Button> */}
        </Box>
      </Box>
    </Box>
  );
};

export default function ImageList(props) {
  const [t] = useTranslation("common");
  const [comments, setComments] = useState([]);
  const globalLoading = useSelector(
    (state) => state.settingsData.globalLoading
  );

  return (
    <>
      {globalLoading ? <Preloading dashboardMode={true} /> : null}
      <PopupForm
        open={props.addForm}
        setOpen={props.setAddForm}
        title={t("REQUESTS.Images")}
        isFullScreen={true}
        content={<RenderContent {...props} setAddForm={props?.setAddForm} />}
        footer={null}
      />
    </>
  );
}
