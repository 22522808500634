import React from "react";
import { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import $ from "jquery";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import Preloading from "../Pages/Preload/Preloading";
import profileAPI from "../Network/Profile/index";
import { useDispatch } from "react-redux";
import { setErrorTitle } from "../reduxStore/SettingsReducer";
const cookies = new Cookies();

function LandingPage(props) {
  const search = useLocation().search;
  const sid = new URLSearchParams(search).get("sid");
  const baseUrl = new URLSearchParams(search).get("baseUrl");
  const hostUrl = new URLSearchParams(search).get("hostUrl");
  const user = new URLSearchParams(search).get("user");
  const authHash = new URLSearchParams(search).get("authHash");
  const [units, setUnits] = useState([]);
  const [activeSession, setActiveSession] = useState(false);
  const navigate = useNavigate();
  // Login to server using entered username and password
 const dispatch=useDispatch()
  useEffect(() => {
    // cookies.set('sid', sid);
    // cookies.set('baseUrl', baseUrl)
    // cookies.set('authHash', authHash)
    // if(baseUrl)
    cookies.set("baseUrl", baseUrl, { path: "/" });
    cookies.set("hostUrl", hostUrl, { path: "/" });
    cookies.set("authHash", authHash, { path: "/" });

    console.log("AJAX----request====>start");
    if (sid && baseUrl) {
      // loginWithHash()
      console.log("AJAX----request====>has url");
      $.ajax({
        type: "GET",
        enctype: "application/json",
        processData: !1,
        contentType: !1,
        crossDomain: true,
        dataType: "jsonp",
        // url: baseUrl + "/avl_evts?sid=" + sid,
        url:
          baseUrl +
          '/wialon/ajax.html?svc=core/duplicate&params={"operateAs":"","continueCurrentSession":true}&sid=' +
          sid,
        success: function(data) {
          if (data?.eid && data?.user?.id) {
            console.log("AJAX----request====>has user and eid");
            // cookies.set('sid', data?.eid);
            // cookies.set('user_id', data?.user?.id)
            // cookies.set('user_name', data?.user?.nm)

            cookies.set("sid", data?.eid, { path: "/" });
            cookies.set("user_id", data?.user?.id, { path: "/" });
            cookies.set("user_name", data?.user?.nm, { path: "/" });

            let userData = {
              user_name: data?.user?.name,
              user_id: data?.user?.id,
            };
            $.ajax({
              type: "GET",
              enctype: "application/json",
              processData: !1,
              contentType: !1,
              crossDomain: true,
              dataType: "jsonp",
              url:
                baseUrl +
                '/wialon/ajax.html?svc=core/search_item&params={"id":' +
                data?.user?.bact +
                ',"flags":1}&sid=' +
                data?.eid,
              success: async function(data) {
                console.log(
                  "AJAX----request====>start request for getting resources"
                );

                if (data?.item?.nm && data?.item?.id) {
                  console.log("AJAX----request====>get recourcess success");

                  // cookies.set('account_id', data?.item?.id);
                  // cookies.set('account_name', data?.item?.nm);
                  // cookies.set('active_session', 'yes');
                  cookies.set("account_id", data?.item?.id, { path: "/" });
                  cookies.set("account_name", data?.item?.nm, { path: "/" });
                  cookies.set("active_session", "yes", { path: "/" });

                  await checkUserHasPhone({
                    account_id: data?.itm?.id,
                    account_name: data?.itm?.nm,
                    ...userData,
                  });
                  dispatch(setErrorTitle(''))
                  setActiveSession(true);
                } else if (data?.error) {
                  console.log(
                    "AJAX----request====>failed loading recources:",
                    data?.error
                  );
                 
                  // cookies.set('active_session', 'no')
                  cookies.set("active_session", "no", { path: "/" });
                  setActiveSession(false);
                  dispatch(setErrorTitle('فشل في الحصول على بيانات الحساب للزبون، رمز الخطأ: '+data?.error))
                  navigate("/sorry");
                }
              },
            });
          } else if (data?.error) {
            console.log("AJAX----request====>no user and eid");
            // cookies.set('active_session', 'no')
            cookies.set("active_session", "no", { path: "/" });
            setActiveSession(false);
          }
          // console.log(data);
        },
      });
    } else {
      console.log("AJAX----request====>no url");
      navigate("/redirect");
    }

    // setTimeout(()=>{
    //     setActiveSession(true)
    // },3000)

    return () => {}; // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  useEffect(() => {
    if (activeSession) {
      const newSid = cookies.get("sid");
      $.ajax({
        type: "GET",
        enctype: "application/json",
        processData: !1,
        contentType: !1,
        crossDomain: true,
        dataType: "jsonp",
        url:
          baseUrl +
          '/wialon/ajax.html?svc=core/search_items&params={"spec":{"itemsType":"avl_unit","propName":"sys_name","propValueMask":"*","sortType":"sys_name","propType":"property"},"force":0,"flags":1,"from":0,"to":0}&sid=' +
          newSid,
        success: function(data) {
          console.log("AJAX----request====>get units for this user");
          console.log("allunits", data);
          setUnits(data?.items?.length > 0 ? data?.items : []);
        },
      });
    }

    return () => {};
  }, [activeSession]);

  const checkUserHasPhone = async (object) => {
    if (cookies.get("active_session") == "yes") {
      let data = null;
      try {
        let params = {
          username: object?.user_name,
          user_id: object?.user_id,
          account_name: object?.account_name,
          account_id: object?.account_id,
          sid: sid,
          baseurl: baseUrl,
        };
        data = await profileAPI.Profile({
          params: params,
        });
        if (data && data?.status) {
          console.log("userinfo", data?.data);
          if (
            data?.data &&
            data?.data?.data &&
            (data?.data?.data?.phone == null || data?.data?.data?.name == null)
          ) {
            console.log("done");
          }
          //   if(data&&data?.data&&data?.data?.data?.message){
          //     if(data?.data?.data?.message?.text=="unauthorized to complete"){
          //        navigate('/redirect')
          //     }
          //   }
        } else {
          // if(data&&data?.data&&data?.data?.data?.message){
          //     if(data?.data?.data?.message?.text=="unauthorized to complete"){
          //        navigate('/redirect')
          //     }
          //   }
        }
      } catch (e) {
        console.log(e?.message);
      }
    } else {
      navigate("/redirect");
    }
  };

  if (activeSession) {
    //  <Outlet/>
    navigate("/system/dashboard");
  } else return <Preloading />;
}

export default LandingPage;
