import { Box, Button, Checkbox } from "@mui/material";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import PopupForm from "../../Components/PopupForm";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ObjectErrors, {
  checkErrors,
  resetErrors,
  restCustomeObject,
} from "../../Errors/Requests/Request";
import requestAPI from "../../Network/Request/index";
import { toast } from "react-toastify";
import helpers from "../../assets/js/helper";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { setGlobalLoading } from "../../reduxStore/SettingsReducer";
import moment from "moment";
import profileAPI from "../../Network/Profile";
import CustomTextField from "../../Components/CustomTextField";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1e6a99",
    color: theme.palette.common.white,
    fontFamily: "Cairo",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const cookies = new Cookies();

const RenderContent = (props) => {
  const [t] = useTranslation("common");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const maintheme = useSelector((state) => state.themeData.maintheme);

  console.log("props?.object", props?.object);
  return (
    <Box
      sx={{
        "& .MuiFormHelperText-root": {
          fontFamily: "Cairo !important",
          color: "red",
        },
        marginTop: "30px",
        width: "100%",
      }}
    >
      <Box mt={1} sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap:'10px',
            marginLeft:'2.5px'
          }}
        >
          <Checkbox
            {...label}
            style={{
              width: "20px",
            }}
            checked={props?.selectAll ? true : false}
            onChange={() => {
              props?.handleSelectAll();
            }}
          />
          <span style={{fontFamily:'Cairo'}}>اختر الكل</span>

        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ width: "100%" }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <Box sx={{ marginLeft: "10px" }}>#</Box>
                </StyledTableCell>
                <StyledTableCell>{t("PROFILE.userEmailName")}</StyledTableCell>
                <StyledTableCell>{t("PROFILE.userEmailEmail")}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props?.data && props?.data?.length
                ? props?.data?.map((row, index) => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell component="th" scope="row">
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Checkbox
                            {...label}
                            style={{
                              width: "20px",
                            }}
                            checked={row?.check ? true : false}
                            onChange={() => {
                              props?.handleToggleSelect(row);
                            }}
                          />

                          {index + 1}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>{row.name}</StyledTableCell>
                      <StyledTableCell>{row.email}</StyledTableCell>
                    </StyledTableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

const RenderFooter = (props) => {
  const [t] = useTranslation("common");
  const gridtheme = useSelector((state) => state.themeData.gridtheme);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box display="flex">
          <Button
            variant="outlined"
            // spacing={2}
            sx={{
              width: "50% !important",
              // flexGrow: 1,
              minWidth: "80px !important",
              maxWidth: "80px !important",
              margin: 1,
              backgroundColor: "#d1d0d0",
              borderColor: gridtheme?.colorWhite,
              color: gridtheme?.colorblack,
              boxShadow: "0 0 7px -2px white",
              //   color:SearchButtonTheme?.clear_button_text_color,

              "&:hover": {
                backgroundColor: "#1e6A99",
                borderColor: gridtheme?.colorWhite,
                color: "#fff",
                boxShadow: "0 0 7px 1px white",
                boxShadow: "0",
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterClear"
            color="secondary"
            onClick={() => {
              props.setOpen(false);
              // props.clearForm();
            }}
          >
            {t("GLOBAL.close")}
          </Button>
          <Button
            disabled={props?.isUploadingTime}
            variant="contained"
            // spacing={2}
            sx={{
              // flexGrow: 1,
              margin: 1,
              width: "80px !important",
              minWidth: "80px !important",
              maxWidth: "80px !important",
              "&:hover": {
                //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterSearch"
            onClick={() => {
              props.submit();
            }}
          >
            {t("GLOBAL.Save")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default function ShareTask(props) {
  const [t] = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [ErrorsObject, setErrorsObject] = useState(ObjectErrors);
  const [isUploadingTime, setIsUploadingTime] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const clearForm = () => {};
  const dispatch = useDispatch();
  const [selectAll, setSelectAll] = useState(false);
  useEffect(() => {}, [props?.object]);
  useEffect(() => {
    getUserEmailsData();
  }, [props?.addForm]);

  const submit = async () => {
    let error_message=''
    if(!props?.object?.task_id){
      error_message+='معرف الطلب مطلوب ,'
    }
    let user_email_ids=[]
    data&&data?.length>0&&data?.map(item=>{
      if(item?.check) user_email_ids.push(item?.id)
    })

    if(!user_email_ids||user_email_ids?.length<=0)
    {
      error_message+='يجب اختيار الاشخاص المراد مشاركة الطلب معهم'
    }

    if(error_message&&error_message?.length>0){
      return toast.warn(error_message)
    }
  
    try {
      let params = {
        username: cookies.get("user_name"),
        user_id: cookies.get("user_id"),
        account_name: cookies.get("account_name"),
        account_id: cookies.get("account_id"),
        sid: cookies.get("sid"),
        baseurl: cookies.get("baseUrl"),
      };
      let data = null;
        data = await profileAPI.handleShareTask({
          params: { ...params, user_email_id: props?.object?.id },
          data: {
            task_id: props?.object?.task_id,
            user_email_ids:user_email_ids,
            user_name:cookies.get("account_name")
          },
        });
      if (data && data?.status && data?.data?.status) {
        props?.setAddForm(false);
        props.loadData();
        clearForm();
        if (data?.data?.data?.message&&typeof data?.data?.data?.message == 'string') {
          toast.success(t(data?.data?.data?.message));
        } else {
          toast.success("Emails Send Successfuly.");
        }
      } else {
        if (typeof data?.data?.data?.message === "string") {
          toast.warn(t(data?.data?.data?.message));
        } else toast.warn(t("NETWORKMESSAGE.deleteErrorr"));
      }
    } catch (err) {
      console.log(err?.message);
    }
  };
  const getUserEmailsData = async () => {
    let data = null;
    try {
      let params = {
        username: cookies.get("user_name"),
        user_id: cookies.get("user_id"),
        account_name: cookies.get("account_name"),
        account_id: cookies.get("account_id"),
        sid: cookies.get("sid"),
        baseurl: cookies.get("baseUrl"),
      };
      dispatch(setGlobalLoading(true));
      data = await profileAPI.getUserEmailsList({
        params: params,
      });
      dispatch(setGlobalLoading(false));
      if (data && data?.status) {
        if (data?.data && data?.data?.data) {
          setData(data?.data?.data);
        }
        if (data && data?.data && data?.data?.data?.message) {
          if (data?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }
        }
      } else {
        toast.warning(t("NETWORKMESSAGE.addCustomerMessageError"));
        if (data && data?.data && data?.data?.data?.message) {
          if (data?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }
        }
      }
    } catch (e) {
      dispatch(setGlobalLoading(false));
      console.log(e?.message);
    }
  };
  const handleToggleSelect = (row) => {
    if (data && data?.length > 0) {
      setData(
        data?.map((item) => {
          if (item?.id == row?.id)
            return { ...item, check: item?.check ? false : true };
          else return item;
        })
      );
    }
  };
  const handleSelectAll = () => {
    if (data && data?.length > 0) {
      let check = true;
      if (selectAll) {
        setSelectAll(false);
        check = false;
      } else {
        setSelectAll(true);
        check = true;
      }
      setData(
        data?.map((item) => {
          return { ...item, check: check };
        })
      );
    }
  };

  return (
    <PopupForm
      open={props.addForm}
      setOpen={props.setAddForm}
      isFullScreen={true}
      isCenter={true}
      title={t("REQUESTS.shareTask")}
      content={
        <RenderContent
          object={props?.object}
          setAddForm={props?.setAddForm}
          data={data}
          handleToggleSelect={handleToggleSelect}
          selectAll={selectAll}
          handleSelectAll={handleSelectAll}
        />
      }
      footer={
        <RenderFooter
          open={props.addForm}
          setOpen={props.setAddForm}
          submit={submit}
          clearForm={clearForm}
          isUploadingTime={isUploadingTime}
        />
      }
    />
  );
}


