import React from 'react'
import './Preloading.css'
// import s1 from '../../assets/img/s1.png'
// import s2 from '../../assets/img/s2.png'
// import s3 from '../../assets/img/s3.png'
// import s4 from '../../assets/img/s4.png'

import s1 from '../../assets/img/01.png'
import s2 from '../../assets/img/02.png'
import s3 from '../../assets/img/03.png'
import s4 from '../../assets/img/04.png'
import s5 from '../../assets/img/05.png'
import s6 from '../../assets/img/06.png'
import s7 from '../../assets/img/07.png'
import s8 from '../../assets/img/08.png'
// import s4 from '../../assets/img/09.png'





export default function Preloading() {
  return (
    <div className='containerOfPreloading'>
      <section>
        <div className='sk-cube-grid'>
          <div className='sk-cube sk-cube-1'
            style={{
              backgroundImage:`url(${s1})`,
            }}
          ></div>
          <div className='sk-cube sk-cube-2'
            style={{
              backgroundImage:`url(${s2})`,
            }}
          ></div>
          <div className='sk-cube sk-cube-3'
            style={{
              backgroundImage:`url(${s3})`,
            }}
          ></div>
          <div className='sk-cube sk-cube-4'
            style={{
              backgroundImage:`url(${s4})`,
            }}
          ></div>
          <div className='sk-cube sk-cube-5'
            style={{
              backgroundImage:`url(${s5})`,
            }}
          ></div>
          <div className='sk-cube sk-cube-6'
            style={{
              backgroundImage:`url(${s6})`,
            }}
          ></div>
          <div className='sk-cube sk-cube-7'
            style={{
              backgroundImage:`url(${s7})`,
            }}
          ></div>
          <div className='sk-cube sk-cube-8'
            style={{
              backgroundImage:`url(${s8})`,
            }}
          ></div>
          <div className='sk-cube sk-cube-9'
            style={{
              backgroundImage:`url(${s1})`,
            }}
          ></div>
        </div>
      </section>
    </div>
  )
}
