import { Box, Button } from "@mui/material";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import PopupForm from "../../Components/PopupForm";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ObjectErrors, {
  checkErrors,
  resetErrors,
  restCustomeObject,
} from "../../Errors/Requests/Request";
import requestAPI from "../../Network/Request/index";
import { toast } from "react-toastify";
import helpers from "../../assets/js/helper";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { setGlobalLoading } from "../../reduxStore/SettingsReducer";
import moment from "moment";
import profileAPI from "../../Network/Profile";
import CustomTextField from "../../Components/CustomTextField";
const label = { inputProps: { "aria-label": "Switch demo" } };

const cookies = new Cookies();
const APP_CONSTANTS = {
  API_URL_IMG: `${process.env.REACT_APP_API_URL_image}`,
};

const RenderContent = (props) => {
  const [t] = useTranslation("common");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const maintheme = useSelector((state) => state.themeData.maintheme);

  console.log("props?.object", props?.object);
  return (
    <Box
      className="Container-fluid position-relative"
      sx={{
        "& .MuiFormHelperText-root": {
          fontFamily: "Cairo !important",
          color: "red",
        },
        marginTop: "30px",
      }}
    >
      <CustomTextField
        label={t("PROFILE.userEmailName")}
        haswidth={true}
        value={props?.name}
        error={props?.ObjError?.name_error}
        customWidth="100%"
        hasMultipleLine={false}
        message={
          props?.ObjError?.name_message ? [props?.ObjError?.name_message] : ""
        }
        readOnly={false}
        onChange={(e) => {
          props?.setName(e.target.value);
          props?.setObjError({
            ...props?.ObjError,
            name_error: false,
            name_message: "",
          });
        }}
        onClearClick={() => {
          props?.setName("");
        }}
      />
      <Box mt={2}></Box>
      <CustomTextField
        label={t("PROFILE.userEmailEmail")}
        haswidth={true}
        value={props?.email}
        error={props?.ObjError?.email_error}
        customWidth="100%"
        hasMultipleLine={false}
        message={
          props?.ObjError?.email_message ? [props?.ObjError?.email_message] : ""
        }
        readOnly={false}
        onChange={(e) => {
          props?.setEmail(e.target.value);
          if (props?.checkEmail(e?.target?.value)) {
            props?.setObjError({
              ...props?.ObjError,
              email_error: true,
              email_message: "صيغة البريد الالكتروني غير صحيحة!",
            });
          } else {
            props?.setObjError({
              ...props?.ObjError,
              email_error: false,
              email_message: "",
            });
          }
        }}
        onClearClick={() => {
          props?.setEmail("");
        }}
      />
    </Box>
  );
};

const RenderFooter = (props) => {
  const [t] = useTranslation("common");
  const gridtheme = useSelector((state) => state.themeData.gridtheme);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box display="flex">
          <Button
            variant="outlined"
            // spacing={2}
            sx={{
              width: "50% !important",
              // flexGrow: 1,
              minWidth: "80px !important",
              maxWidth: "80px !important",
              margin: 1,
              backgroundColor: "#d1d0d0",
              borderColor: gridtheme?.colorWhite,
              color: gridtheme?.colorblack,
              boxShadow: "0 0 7px -2px white",
              //   color:SearchButtonTheme?.clear_button_text_color,

              "&:hover": {
                backgroundColor: "#1e6A99",
                borderColor: gridtheme?.colorWhite,
                color: "#fff",
                boxShadow: "0 0 7px 1px white",
                boxShadow: "0",
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterClear"
            color="secondary"
            onClick={() => {
              props.setOpen(false);
              // props.clearForm();
            }}
          >
            {t("GLOBAL.close")}
          </Button>
          <Button
            disabled={props?.isUploadingTime}
            variant="contained"
            // spacing={2}
            sx={{
              // flexGrow: 1,
              margin: 1,
              width: "80px !important",
              minWidth: "80px !important",
              maxWidth: "80px !important",
              "&:hover": {
                //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterSearch"
            onClick={() => {
              props.submit();
            }}
          >
            {t("GLOBAL.Save")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default function AddUserEmail(props) {
  const [t] = useTranslation("common");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [ErrorsObject, setErrorsObject] = useState(ObjectErrors);
  const [isUploadingTime, setIsUploadingTime] = useState(false);
  const navigate = useNavigate();
  const [ObjError, setObjError] = useState({
    email_error: false,
    email_message: "",
    name_error: false,
    name_message: "",
  });
  const clearForm = () => {
    setName('')
    setEmail('')
    setObjError({
      email_error: false,
      email_message: "",
      name_error: false,
      name_message: "",
    })
  };
  useEffect(() => {
    console.log('props?.object',props?.object)
    if (props?.object) {
      setName(props?.object?.name);
      setEmail(props?.object?.email);
    }else{
      clearForm()
    }
  }, [props?.object]);

  const submit = async () => {
    let object = ObjError;
    if (!email || checkEmail(email)) {
      object = {
        ...ObjError,
        email_error: true,
        email_message: "صيغة البريد الالكتروني غير صحيحة!",
      };
    }
    if (!name) {
      object = {
        ...ObjError,
        name_error: true,
        name_message: "الاسم مطلوب",
      };
    }
    if (object?.name_error || object?.email_error) {
      setObjError(object);
      return;
    }
    let params = {
      username: cookies.get("user_name"),
      user_id: cookies.get("user_id"),
      account_name: cookies.get("account_name"),
      account_id: cookies.get("account_id"),
      sid: cookies.get("sid"),
      baseurl: cookies.get("baseUrl"),
    };
    try {
      let data = null;
      if (props?.object?.id) {
        data = await profileAPI.updateUserEmails({
          params: { ...params, user_email_id: props?.object?.id },
          data: {
            name: name,
            email: email,
            user_email_id: props?.object?.id 
          },
        });
      } else {
        data = await profileAPI.createUserEmails({
          params: params,
          data: {
            name: name,
            email: email,
          },
        });
      }

      if (data && data?.status&&data?.data?.status) {
        props?.setAddForm(false)
        props.getData();
        clearForm()
        if(props?.object?.id){
          toast.success(t("NETWORKMESSAGE.updateCustomerMessageSuccess"));
        }else{
          toast.success(t("NETWORKMESSAGE.addCustomerMessageSuccess"));
        }
      } else {
        if(typeof data?.data?.data ==='string'){
          toast.warn(t(data?.data?.data));
        }else
        toast.warn(t("NETWORKMESSAGE.deleteErrorr"));
      }
    } catch (err) {
      console.log(err?.message);
    }
  };
  const checkEmail = (value) => {
    var email = value;
    var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if (!filter.test(email)) return true;
    else return false;
  };

  return (
    <PopupForm
      open={props.addForm}
      setOpen={props.setAddForm}
      isCenter={true}
      title={
        props?.object?.task_id
          ? t("PROFILE.updateUserEmailTitle")
          : t("PROFILE.addUserEmailTitle")
      }
      content={
        <RenderContent
          object={props?.object}
          setAddForm={props?.setAddForm}
          checkEmail={checkEmail}
          name={name}
          setName={setName}
          email={email}
          setEmail={setEmail}
          ObjError={ObjError}
          setObjError={setObjError}
        />
      }
      footer={
        <RenderFooter
          open={props.addForm}
          setOpen={props.setAddForm}
          submit={submit}
          clearForm={clearForm}
          isUploadingTime={isUploadingTime}
        />
      }
    />
  );
}
