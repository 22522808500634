import { Box, TextField, Button, Autocomplete, FormControlLabel, Divider, Typography, Tooltip } from '@mui/material'
import React, { useState, useEffect, useCallback } from 'react'
import PopupForm from '../../Components/PopupForm'
import { useTranslation } from 'react-i18next'
import { ContainerOfInput, ContainerOfInputFields, ContainerOfSelectField } from '../../Components/ThemDesign'
import { useSelector } from 'react-redux'
import ObjectErrors, { checkErrors, resetErrors, restCustomeObject } from '../../Errors/Requests/Request'
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid'
import StarsIcon from '@mui/icons-material/Stars';

// import EmployeeAPI from '../../../../Global/Network/Employee/index'
import requestAPI from '../../Network/Request/index'
import { toast } from 'react-toastify'
import * as RiIcons from 'react-icons/ri'
import * as FaIcons from'react-icons/fa'
import * as BsIcons from 'react-icons/bs'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Grid from '../Grid/Grid'
import helpers from '../../assets/js/helper'
import $ from 'jquery'
import Cookies from 'universal-cookie'
import SearchInput from '../../Components/SearchInput'
import Switch from '@mui/material/Switch';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import DeleteIcon from '@mui/icons-material/Delete';
import requestTypeAPI from '../../Network/RequestType'
import { useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add';
import * as BiIcons from 'react-icons/bi'
import AltRouteIcon from '@mui/icons-material/AltRoute';
import Preloading from '../../Pages/Preload/Preloading';


const label = { inputProps: { 'aria-label': 'Switch demo' } };

const cookies = new Cookies();
const APP_CONSTANTS = {
  API_URL_IMG: `${process.env.REACT_APP_API_URL_image}`,
}

const FilterSearchContent = (props) => {
  const [t] = useTranslation('common')

  return (
    <Box sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: 'column',
      width: '100% !important',
    }}>
      <SearchInput
        value={props?.unitNameSearch}
        setValue={props?.setUnitNameSearch}
        title={t('REQUESTS.unitName')}

      />
      {/* <Box
        sx={{
          display: 'flex',
          justifyContent: 'start',
          width: '100%',
          marginLeft: '20px !important',
          marginTop: '10px !important',
          '& .MuiTypography-root': {
            fontFamily: 'Cairo-Medium'
          }
        }}>
        <FormControlLabel control={<Switch checked={props.checkAllselected} onChange={(e) => {
          props.setCheckAllselected(e.target.checked)
          if(e.target.checked)
          props.setCheckAllNotSelected(false)

        }} />} label={"المحددة" } />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'start',
          width: '100%',
          marginLeft: '20px !important',
          marginTop: '10px !important',
          '& .MuiTypography-root': {
            fontFamily: 'Cairo-Medium'
          }
        }}>
        <FormControlLabel control={<Switch checked={props.checkAllNotSelected} onChange={(e) => {
          props.setCheckAllNotSelected(e.target.checked)
          if(e.target.checked)
          props.setCheckAllselected(false)
        }} />} label={'غير محددة'} />
      </Box> */}
    </Box>)
}
const RenderContent = (props) => {
  const [t] = useTranslation('common')
  const navigate=useNavigate()

  const maintheme = useSelector(state => state.themeData.maintheme);
  const [ErrorsObject, setErrorsObject] = useState(ObjectErrors)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const handleChange = (e, number, errName) => {
    if (number == 1) {
      props.setPhone(e.target.value)
      handlePhoneChange(e)
      setErrorsObject(restCustomeObject(ErrorsObject, errName))
    } else if (number == 2) {
      props.setRequestLocation(e.target.value)
      setErrorsObject(restCustomeObject(ErrorsObject, errName))
    } else if (number == 3) {
      props.setRequestType(e)
      setErrorsObject(restCustomeObject(ErrorsObject, errName))
    } else if (number == 4) {
      props.setNotes(e.target.value)
      setErrorsObject(restCustomeObject(ErrorsObject, errName))
    } else if (number == 5) {
      console.log('date', e)
      props.setPreferedDate(e)
      setErrorsObject(restCustomeObject(ErrorsObject, errName))
    } else if (number == 6) {
      props.setPreferedTime(e)
      setErrorsObject(restCustomeObject(ErrorsObject, errName))
    }
  }
  const clearInput = (number, errName) => {
    if (number == 1) {
      props.setPhone('')
      setErrorsObject(restCustomeObject(ErrorsObject, errName))
    } else if (number == 2) {
      props.setRequestLocation('')
      setErrorsObject(restCustomeObject(ErrorsObject, errName))
    } else if (number == 3) {
      props.setRequestType(null)
      setErrorsObject(restCustomeObject(ErrorsObject, errName))
    } else if (number == 4) {
      props.setNotes('')
      setErrorsObject(restCustomeObject(ErrorsObject, errName))
    } else if (number == 5) {
      props.setPreferedDate(null)
      setErrorsObject(restCustomeObject(ErrorsObject, errName))
    } else if (number == 6) {
      props.setPreferedTime(null)
      setErrorsObject(restCustomeObject(ErrorsObject, errName))
    }

  }
  const [columns, setColumns] = useState([
    // {
    //   ...GRID_CHECKBOX_SELECTION_COL_DEF,
    //   width: 40,
    // },
    { headerName: t('REQUESTS.add_unit_id'), field: 'index', flex: 0.5 },
    { headerName: t('REQUESTS.add_unit_name'), field: 'nm', flex: 2 },
  //   { headerName: t('REQUESTS.add_unit_reasone'), field: 'reason', flex: 2, editable: false ,
 
  //       renderCell: (rows) => (
  //           <Box>
  //             {
  //               rows?.row?.reason?<span style={{fontSize:'15px',fontFamily:'Cairo'}}>{rows?.row?.reason}</span>:<span style={{color:'gray',fontSize:'12px',fontFamily:'Cairo-Light'}}>يرجى كتابة السبب</span>
  //             }
  //           </Box>
  //      )
  
  // },

  ])
  const [selectedImage, setSelectedImage] = useState('')
  const [pageSize, setPageSize] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  const handlePageChange = (newPage) => {
    setPageNumber(newPage + 1);
  };
  const [isUpdateImage, setIsUpdatedImage] = useState(false)
  const [rowsTotal, setRowsTotal] = useState(0)
  const screenwidth = useSelector(state => state.settingsData.screenwidth)
  // const [listOfSelectedRows,setListOfSelectedRows]=useState([])
  const handleCheckBoxChange = useCallback((rows) => {
    console.log('datadadjandkasjdn', rows);
    props.setListOfSelectedRows(rows)
    let arr = [];
    props?.units && props?.units?.length && props?.units?.map((itm) => {
      if (rows?.find(e => e == itm?.id)) {
        arr.push(itm)
      }
    })
    console.log('datadadjandkasjdn', arr);
    props.setUnit(arr)
  }, [])
  const handleRowCellChange = (row) => {
    console.log('sadasdasda', row)
    let key = null;
    for (var i in row) {
      key = i;
    }
    let arr = []
    props?.units && props?.units?.length && props?.units?.map((itm) => {
      if (itm?.id == key) {
        arr.push({
          ...itm,
          reason: row[key]?.reason?.value,
        })
      } else {
        arr.push(itm)
      }
    })

    console.log('datadadjandkasjdn', arr);
    if (arr)
      props.setUnits(arr)
  }
  const handleSizeOfFile=async(e)=>{
    if(e.size > 2097152){
      toast.warn('يجب رفع ملف بحجم اقل من 2M')
       return false
    };
    return true;
  };
  const getNameOfImage=(file)=>{
      const name = file?.split("\\");
      if(name&&name?.length>0){
        return name[name?.length-1];
      }
      return ''
  }
  const handleImageChange = async (e) => {
    // console.log('file', e.files[0].size)
    if(e&&e?.target&&e.target.files[0]){
        
      if(await handleSizeOfFile(e.target.files[0]))
      {
        try {
          if (e?.target?.files) {
            // alert('asdasd')
            if (!isUpdateImage) {
              if (props?.editMode) {
                let formData = new FormData()
                formData.append(`files[0]`, e.target.files[0])


                let params = {
                  username: cookies.get('user_name'),
                  user_id: cookies.get('user_id'),
                  account_name: cookies.get('account_name'),
                  account_id: cookies.get('account_id'),
                  sid: cookies.get('sid'),
                  baseurl: cookies.get('baseUrl')
                }

                let result = await requestAPI.AddRequestFile({
                  data: formData,
                  params: params,
                  id: props?.task_id,
                  headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                  }
                });


                if (result?.status && result?.data?.status) {
                  let checkImage=getExtension(e.target.value)

                  props?.setImageList((itms) => [...itms, {
                    url: window.URL.createObjectURL(e.target.files[0]),
                    file: e.target.files[0],
                    index: itms[itms?.length - 1]?.index ? itms[itms?.length - 1]?.index + 1 : 1,
                    type:checkImage?'image':'file',
                    name:getNameOfImage(e.target.value)
                  }
                  ])

                } else {
                  if(result&&result?.data&&result?.data?.data?.message){
                    if(result?.data?.data?.message?.text=="unauthorized to complete"){
                      navigate('/redirect')
                    }
                  }
                }

              }
              else {
                let checkImage=getExtension(e.target.value)
                props?.setImageList((itms) => [...itms, {
                  url: window.URL.createObjectURL(e.target.files[0]),
                  file: e.target.files[0],
                  index: itms[itms?.length - 1]?.index ? itms[itms?.length - 1]?.index + 1 : 1,
                  id: "",
                  type:checkImage?'image':'file',
                  name:getNameOfImage(e.target.value)
                }
                ])
              }
              
            } else {
              let arr = []
              let checkImage=getExtension(e.target.value)
              props?.imageList && props?.imageList?.length && props?.imageList?.map((itm) => {
                if (isUpdateImage?.index == itm?.index) {
                  arr.push({
                    ...itm,
                    url: window.URL.createObjectURL(e.target.files[0]),
                    file: e.target.files[0],
                    type:checkImage?'image':'file',
                    name:getNameOfImage(e.target.value)

                  })
                } else {
                  arr.push(itm)
                }
              })
              props.setImageList(arr)
              setIsUpdatedImage(null)
            }
          }
          // document.getElementById('imagesForTask').value='';
        } catch (e) {
          console.log(e?.message)
        }
      }
    }
    
  }
  const addImageToList = (e) => {
    if (props?.imageList?.length > 5 && !isUpdateImage) {// becase we started from index 0..4=5 items
      toast.warn('لا يمكن رفع اكثر من 6 صور')
      return
    }

    const image = e.target?.querySelector('input');
    if (image) {
      image.click()
      image=null;
    }
  }
  const setSelectedImageToShow = (itm) => {
    setSelectedImage(itm?.url)
  }
  const handleUpdateImage = (e) => {
    // setSelectedImage(null)
    setIsUpdatedImage(e)
    let data = document.getElementById('imagesForTask');
    if (data) {
      data.click();
    }
  }
  const handleDeleteImage = async (e) => {
    if (props?.editMode) {
      console.log(e);
      let params = {
        username: cookies.get('user_name'),
        user_id: cookies.get('user_id'),
        account_name: cookies.get('account_name'),
        account_id: cookies.get('account_id'),
        sid: cookies.get('sid'),
        baseurl: cookies.get('baseUrl')
      }

      let result = await requestAPI.DeleteRequestFile({
        params: params,
        id: e?.id,
      });


      if (result?.status && result?.data?.status) {
        setSelectedImage(null)
        props.setImageList(props?.imageList.filter(itm => itm?.index !== e.index))
      } else {
        if(result&&result?.data&&result?.data?.data?.message){
          if(result?.data?.data?.message?.text=="unauthorized to complete"){
             navigate('/redirect')
          }
        }
      }
    }
    else {
      setSelectedImage(null)
      props.setImageList(props?.imageList.filter(itm => itm?.index !== e.index))
    }

  }
  const [errorPhone,setPhoneError]=useState({
    error:false,
    message:''
  })
  const handlePhoneChange=(e)=>{
    let inputtxt=e.target;
    var phoneno = /^\d{11}$/;
     let object={
      error:false,
      message:''
     }
    setPhoneError({
      error:false,
      message:''
    })
    if(isNaN(inputtxt.value)){
      console.log('messgae','string');
      setPhoneError({
        ...errorPhone,
        message:'يجب ان يكون الهاتف رقم',
        error:true,
      })
      
      return false
    }

    if(e?.target?.value?.length==11&&!isNaN(inputtxt.value))
    {
      console.log('messgae','true');
      object={
        message:'',
        error:false,
      }
    }
    else if(e?.target?.value?.length>=11)
    {
      console.log('messgae','false');
      object={
        message:'رقم الهاتف بصيغة غير صحيحة !',
        error:true,
      }
    }else{
      object={
      message:'',
      error:false,
    }
    }

    setPhoneError({
        ...errorPhone,
        ...object,
    })
  }

  const getTranslateStatus=(name)=>{
    if(name=='new')
     return 'جديد'
    else if(name=='in progress')
    return "قيد المعالجة"
    else if(name=='closed')
     return "مغلقة"
    else if(name=='rejected')
     return "ألغيت"
    else if(name=='approved')
    return "مكتملة"
    else if(name=='archived')
    return 'مؤرشف'
    else if(name=='successful')
     return 'مكتملة'
     else if(name=='canceled')
     return 'ألغيت'
     else if(name=='failed')
     return 'ألغيت'
     else return name
  }
  const getExtension = (file) => {
    const extension = file.split(".").pop();
    
    if(extension=='jpeg'||extension=='jpg'||extension=='png'||extension=='gif'||extension=='svg'){
      return true;
    }
  };

  const checkisImageOrExcel=(obj)=>{
    let arr=obj?.split('.')
    console.log('kasdasdasd',arr);
    if(arr&&arr?.length>0){
      if(arr[arr?.length-1]=='jpeg'||arr[arr?.length-1]=='jpg'||arr[arr?.length-1]=='png'||arr[arr?.length-1]=='gif'||arr[arr?.length-1]=='svg'){
        return true;
      }
    }
    return false;
  }

  console.log("props?.object", props?.object);
  return (
    <Box className='Container-fluid position-relative'>
        <Grid
          rows={props?.units}
          columns={columns}
          setColumns={setColumns}
          pageSize={pageSize}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          loading={loading}
          handlePageChange={handlePageChange}
          rowsTotal={props?.units?.length}
          checkScreenSize={screenwidth}
          pageCount={Math.ceil(props?.units?.length / pageSize)}
          openFilterColumn={null}
          elementClicked={null}
          setOpenFilterColumn={null}
          setColumnFilterValue={null}
          columFilterValue={null}
          clearFunction={props.handleClearInAddvancSearch}
          searchFunction={props.handleSearchInAddvancSearch}
          creatFunction={null}
          ExportFunction={null}
          importFunction={null}
          mapFunction={null}
          telegramFunction={null}
          hasCreate={false}
          hasImport={false}
          hasExport={false}
          hasMap={false}
          hasTetelgram={false}
          filterChilds={<FilterSearchContent
            setUnitNameSearch={props?.setUnitNameSearch}
            unitNameSearch={props?.unitNameSearch}
            setCheckAllselected={props.setCheckAllselected}
            checkAllselected={props.checkAllselected}
            setCheckAllNotSelected={props.setCheckAllNotSelected}
            checkAllNotSelected={props?.checkAllNotSelected}
          />}
          filterHasSelectCounter={false}
          filterHasSelectCounterValue={props?.listOfSelectedRows?.length}
          notHaveAdvancSearch={false}
          hasSelectOption={false}
          handleCheckBoxChange={handleCheckBoxChange}
          listOfSelectedRows={props.listOfSelectedRows}
          handleRowCellChange={handleRowCellChange}
          paginationMode={'client'}
        />
    </Box>
  )
}

const RenderFooter = (props) => {
  const [t] = useTranslation('common')
  const gridtheme = useSelector(state => state.themeData.gridtheme)

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
    }}>


      <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', width: '100%' }}>
        <Box display="flex" >

          <Button variant="outlined"
            // spacing={2}
            sx={{
              width: '50% !important',
              // flexGrow: 1, 
              minWidth: '80px !important',
              maxWidth: '80px !important',
              margin: 1,
              backgroundColor: '#d1d0d0',
              borderColor: gridtheme?.colorWhite,
              color: gridtheme?.colorblack,
              boxShadow: '0 0 7px -2px white',
              //   color:SearchButtonTheme?.clear_button_text_color,

              '&:hover': {
                backgroundColor: '#1e6A99',
                borderColor: gridtheme?.colorWhite,
                color: '#fff',
                boxShadow: '0 0 7px 1px white',
                boxShadow: '0',
              },
              height: '35px',
              fontFamily: 'Cairo-Bold'

            }}
            className="iconeFilterClear"
            color="secondary"
            onClick={() => {
              // props.setOpen(false)
              props.clearForm()
            }}
          >
            {t('GLOBAL.clear')}
          </Button>
          <Button
            variant="contained"
            // spacing={2} 
            sx={{
              // flexGrow: 1,
              margin: 1,
              width: '80px !important',
              minWidth: '80px !important',
              maxWidth: '80px !important',
              '&:hover': {
                //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
              },
              height: '35px',
              fontFamily: 'Cairo-Bold'
            }}
            className="iconeFilterSearch"
            onClick={() => {
              props.submit()
            }}
          >
            {t('GLOBAL.Save')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}


export default function Units(props) {
  const [t] = useTranslation('common');
  const [name, setName] = useState(null)
  const [loading, setLoading] = useState(false)
  const [ErrorsObject, setErrorsObject] = useState(ObjectErrors)
  const navigate = useNavigate()
  const [phone, setPhone] = useState(cookies.get('phone'))
  const [requestLocation, setRequestLocation] = useState('');
  const [requestType, setRequestType] = useState(null);
  const [requestTypes, setRequestTypes] = useState([]);
  const [unit, setUnit] = useState(null);
  const [units, setUnits] = useState([]);
  const [preferedDate, setPreferedDate] = useState(null);
  const [preferedTime, setPreferedTime] = useState(null);
  const [notes, setNotes] = useState('');
  const [customerName, setCustomerName] = useState(cookies.get('account_name'))
  const [userName, setUserName] = useState(cookies.get('user_name'))
  const [requestDate, setRequestDate] = useState(new Date());
  const globalLoading=useSelector(state=>state.settingsData.globalLoading)
  const [status, setStatus] = useState({
    id: 1,
    name: 'جديد'
  })
  const [statusList, setStatusList] = useState([
    {
      id: 1,
      name: 'new',
    },
    {
      id: 2,
      name: 'in Progress',
    },
    {
      id: 3,
      name: 'closed',
    },
    {
      id: 4,
      name: 'rejected',
    },
    {
      id: 5,
      name: 'approved',
    },
    {
      id: 6,
      name: 'archived',
    },
  ])
  const [inprogressDateTime, setInprogressDate] = useState(new Date())
  const [closeingDateTime, setcloseingDate] = useState(new Date())
  const [monitoredBy, setMonitoredBy] = useState(null)
  const [assignedBy, setAssignedBy] = useState(null)
  const [unitNameSearch, setUnitNameSearch] = useState()
  const [filteredUnits, setFilteredUnits] = useState([])
  const [listOfSelectedRows, setListOfSelectedRows] = useState([])
  const [isSearchApplay, setIsSearchApplay] = useState(false)
  const [checkAllselected, setCheckAllselected] = useState(false)
  const [checkAllNotSelected, setCheckAllNotSelected] = useState(false)

  const [imageList, setImageList] = useState([])

  const getNameOfImage=(file)=>{
    const name = file.split("/");
    if(name&&name?.length>0){
      return name[name?.length-1];
    }
    return ''
}

  const submit = async () => {
    if (cookies.get('active_session') && cookies.get('active_session') == 'yes') {
      
      try {
        if(!phone){
           toast.warn('رقم الهاتف مطلوب')
           return false
        }
        if((phone?.length<11||phone?.length>11)&&!isNaN(phone)){
          toast.warning('رقم الهاتف يجب ان يتكون من 11 رقم')
          return
        }else if(isNaN(phone)){
            toast.warning('صيغة رقم الهاتف غير صحيحة')
            return
        }

        if(listOfSelectedRows?.length==0&&!notes){
           toast.warn('حقل الملاحظات مطلوب')
           return false;
        }




        setLoading(true)
        let arr = []
        let updateObject = {}
        console.log('listOfSelectedRows', listOfSelectedRows)
        listOfSelectedRows && listOfSelectedRows?.length > 0 && listOfSelectedRows?.map((itm) => {
          units && units?.length && units?.map((itm2) => {
            if (itm === itm2?.id) {
              arr.push({
                wialon_id: itm2?.id,
                name: itm2?.nm?.replace("'", ""),
                reason: itm2?.reason ? itm2?.reason : '',
              })
            }
          })
        })
        console.log('listOfSelectedRows', arr)

        // let dumData=resetErrors(ErrorsObject)
        const formData = new FormData()
        if (phone) {
          formData.append('user_mobile', phone)
          updateObject['user_mobile'] = phone
        }
        if (requestLocation) {
          let string=requestLocation?.replace(/\\|\/|\'/g,'')
          formData.append('request_location', string)
          updateObject['request_location'] = string
        }else{
          toast.warn('يرجى تحديد مكان الطلب')
          return false
        }
        if (requestType) {
          formData.append('request_type_id', requestType?.id)
          updateObject['request_type_id'] = requestType?.id
        }else{
          toast.warn('يرجى تحديد نوع الطلب')
          return false
        }

        if (arr && arr?.length > 0) {
          arr?.map((itm, index) => {
            formData.append(`units[${index}][wialon_id]`, itm?.wialon_id)
            formData.append(`units[${index}][name]`, itm?.name)
            formData.append(`units[${index}][reason]`, itm?.reason)
          })
          updateObject['units'] = arr
        }
        if (preferedDate) {
          formData.append('prefered_date', helpers.formatDate(preferedDate))
          updateObject['prefered_date'] = helpers.formatDate(preferedDate)
        }
        if (preferedTime) {
          formData.append('prefered_time', helpers.formatTime(preferedTime))
          updateObject['prefered_time'] = helpers.formatTime(preferedTime)
        }
        if (notes) {
          let string=notes?.replace(/\\|\/|\'/g,'')
          formData.append('notes', string)
          updateObject['notes'] = string
        }
        if(preferedTime&&!preferedDate){
           toast.warn('يرجى أختيار التاريخ المفضل')
           return false;
        }
        
       

        let params = {
          username: cookies.get('user_name'),
          user_id: cookies.get('user_id'),
          account_name: cookies.get('account_name'),
          account_id: cookies.get('account_id'),
          sid: cookies.get('sid'),
          baseurl: cookies.get('baseUrl')
        }

        let result = null;
        if (props?.object && props?.object?.task_id) {

          updateObject['id'] = props?.object?.task_id

          result = await requestAPI.EditRequest({
            data: updateObject,
            params: params,
            id: props?.object?.task_id,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          });
        } else {
          if(imageList&&imageList?.length>0){
            imageList?.map((itm,index)=>{
              if(itm?.file)
               formData.append(`files[${index}]`,itm?.file)
            })
          }
          result = await requestAPI.AddRequest({
            data: formData,
            params: params,
          });
        }

        if (result?.status && result?.data?.status) {
          setLoading(false)
          toast.success(t('NETWORKMESSAGE.addCustomerMessageSuccess'))
          clearForm()
          props.loadData()
          props.setAddForm(false)
        } else {
          if (typeof result.errorMessage === "object") {
            //   let updatedObject = resetErrors(ErrorsObject)
            //  alert('asdasd')
            // console.log('message',result.errorMessage,updatedObject)

            //   setErrorsObject(checkErrors(result.errorMessage, updatedObject))   
          }
          setLoading(false)
          toast.error(t('NETWORKMESSAGE.addCustomerMessageError'))
          if(result&&result?.data&&result?.data?.data?.message){
            if(result?.data?.data?.message?.text=="unauthorized to complete"){
               navigate('/redirect')
            }
          }
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
        toast.error(error.message)
      }
    } else {
      // navigate('/redirect')
    }
  }

  const clearForm = () => {
    setPhone('')
    setRequestLocation('')
    setRequestType(null)
    setUnit([])
    setListOfSelectedRows([])
    setPreferedDate(null)
    setPreferedTime(null)
    setImageList([])
    setNotes('')
  }
  useEffect(() => {
    if (!props?.object)
      {
        clearForm()
        setPhone(cookies.get('phone'))
      }

    else {
      getDataOfSelectedTask(props?.object?.task_id)
    }
  }, [props?.addForm, props?.object])

  useEffect(() => {
    getAllUnits()
    getAllRequestTypes()
  }, [])
  //get all units from wialon her...
  const getAllUnits = () => {
    if (cookies.get('active_session') && cookies.get('active_session') == 'yes') {
      const newSid = cookies.get('sid')
      $.ajax({
        type: "GET",
        enctype: "application/json",
        processData: !1,
        contentType: !1,
        crossDomain: true,
        dataType: "jsonp",
        url: cookies.get('baseUrl') + '/wialon/ajax.html?svc=core/search_items&params={"spec":{"itemsType":"avl_unit","propName":"sys_name","propValueMask":"*","sortType":"sys_name","propType":"property"},"force":0,"flags":1,"from":0,"to":0}&sid=' + newSid,
        success: function (data) {
          console.log('allunitsdata', data);
            let arr=[]
            data?.items&&data?.items?.length&&data?.items?.map((itm,index)=>{
               arr.push({
                ...itm,
                index:index+1
               })
            })
            setUnits(arr)
        }
      })
    } else {
      navigate('/')
    }

  }
  //get all requestTypes here..
  const getAllRequestTypes = async () => {
    if (cookies.get('active_session') && cookies.get('active_session') == 'yes') {
      try {
        setLoading(true)
        let arr = []
        let params = {
          username: cookies.get('user_name'),
          user_id: cookies.get('user_id'),
          account_name: cookies.get('account_name'),
          account_id: cookies.get('account_id'),
          sid: cookies.get('sid'),
          baseurl: cookies.get('baseUrl')
        }
        let result = await requestTypeAPI.RequestTypes({
          params: params
        });
        if (result?.status) {
          setLoading(false)
          if (result?.data && result?.data?.data)
            setRequestTypes(result?.data?.data)
          // toast.success(t('NETWORKMESSAGE.addCustomerMessageSuccess'))
          if(result&&result?.data&&result?.data?.data?.message){
            if(result?.data?.data?.message?.text=="unauthorized to complete"){
               navigate('/redirect')
            }
          }
        } else {
          setLoading(false)
          toast.error(t('NETWORKMESSAGE.addCustomerMessageError'))
          if(result&&result?.data&&result?.data?.data?.message){
            if(result?.data?.data?.message?.text=="unauthorized to complete"){
               navigate('/redirect')
            }
          }
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
        toast.error(error.message)
      }
    } else {
      // navigate('/redirect')
    }
  }
  const handleSearchInAddvancSearch = () => {
    let arr = []
    if (unitNameSearch) {
      setIsSearchApplay(true)
 
      units && units?.length && units?.map((itm) => {
        if (itm?.nm.includes(unitNameSearch)) {
          arr.push(itm)
        }
      })
      setFilteredUnits(arr)
    } else {
      arr=[...units];
      setFilteredUnits(units)
    }
    if (checkAllselected) {
      setFilteredUnits(arr.filter(itm => listOfSelectedRows?.includes(itm?.id)))
      console.log('sadasdasdasdasd', unit)
      console.log('sadasdasdasdasd', listOfSelectedRows)
    }
     if(checkAllNotSelected){
      setFilteredUnits(arr.filter(itm => !listOfSelectedRows?.includes(itm?.id)))
    }
  }
  const handleClearInAddvancSearch = () => {
    setIsSearchApplay(false)
    setUnitNameSearch('')
    setFilteredUnits(units)
    setCheckAllselected(false)
    console.log('hhhddkkkdd', unit)
  }
  // useEffect(()=>{
  //   if(unit&&unit?.length>0&&!isSearchApplay){
  //     let arr=[];
  //     unit&&unit?.length&&unit?.map((itm)=>{
  //       if(units){
  //         units&&units?.length&&units?.map((itm2)=>{
  //             if(itm?.id==itm2?.id)
  //              arr.push(itm2)
  //         })
  //       }
  //     })
  //     units&&units?.length&&units?.map((itm2)=>{
  //       let check=false;
  //       arr&&arr?.length&&unit?.map((itm)=>{
  //         if(itm2?.id==itm?.id)
  //         check=true
  //       })
  //       if(!check)
  //         arr.push(itm2)
  //     })

  //     setUnits(arr)
  //   }
  // },[unit,isSearchApplay])

  useEffect(() => {
    if (units) {
      setFilteredUnits(units)
    }
  }, [units])
  const getExtension = (file) => {
    const extension = file.split(".").pop();
    
    if(extension=='jpeg'||extension=='jpg'||extension=='png'||extension=='gif'||extension=='svg'){
      return true;
    }
  };


  const getDataOfSelectedTask = async (id) => {
    let result = null
    try {
      let params = {
        username: cookies.get('user_name'),
        user_id: cookies.get('user_id'),
        account_name: cookies.get('account_name'),
        account_id: cookies.get('account_id'),
        sid: cookies.get('sid'),
        baseurl: cookies.get('baseUrl')
      }
      result = await requestAPI.ShowRequestTask({
        params: params,
        id: id,
      })

      if (result?.status && result?.data?.status) {
        setPhone(result?.data?.data?.task?.user_mobile)
        setRequestLocation(result?.data?.data?.task?.request_location)
        setRequestType(result?.data?.data?.request_type)
        setNotes(result?.data?.data?.task?.note)
        setPreferedDate(result?.data?.data?.task?.prefered_date ? helpers.formatDate(result?.data?.data?.task?.prefered_date) : null)
        setPreferedTime(result?.data?.data?.task?.prefered_time && result?.data?.data?.task?.prefered_date ? new Date(helpers.formatDate(result?.data?.data?.task?.prefered_date) + ' ' + result?.data?.data?.task?.prefered_time) : null)
        let arrImg = []
        result?.data?.data?.files && result?.data?.data?.files?.length > 0 && result?.data?.data?.files?.map((itm, index) => {
          let checkImage=getExtension(itm?.file_path)
          arrImg.push({
            url: APP_CONSTANTS.API_URL_IMG + itm?.file_path,
            file: null,
            index: index,
            id: itm?.file_id,
            type:checkImage?'image':'file',
            name:getNameOfImage(itm?.file_path)

          })
        })
        setImageList(arrImg)
        //  setUnits(result?.data?.data?.units)
        setStatus(result?.data?.data?.status)
        setInprogressDate(result?.data?.data?.inprogress?.created_at)
        setcloseingDate(result?.data?.data?.closed?.created_at)
        let arrId = []
        result?.data?.data?.units && result?.data?.data?.units?.length > 0 && result?.data?.data?.units?.map((itm) => {
          arrId.push(itm?.wialon_id)
        })
        setListOfSelectedRows(arrId)



        setLoading(false)
        // toast.success(t('NETWORKMESSAGE.addCustomerMessageSuccess'))
      } else {
        setLoading(false)
        toast.error(t('NETWORKMESSAGE.addCustomerMessageError'))
        if(result&&result?.data&&result?.data?.data?.message){
          if(result?.data?.data?.message?.text=="unauthorized to complete"){
             navigate('/redirect')
          }
        }
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      toast.error(error.message)
    }
  }


  return (
    <Box>
        {
        globalLoading?<Preloading dashboardMode={true}/>:null
      }


         <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'stretch',
          // backgroundColor: '#fff',
          position: 'relative',
          // top:'-25px'
        }}
        className="pb-3"
      >
  

        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography
            variant='h4'
            sx={{
              marginLeft: '10px',
              marginBottom: '10px',
              fontFamily: 'Cairo-Bold',
              fontSize: '23px',
              letterSpacing: '-1px',
            }}>
            {t('UNITS.title')}
          </Typography>
        </Box>

        <Box sx={{ marginRight: '20px', backgroundColor: '#1E6A99', color: '#FFFFFF', width: '43px', height: '40px', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
          // onClick={(event)=>{
          //   setOpenFilterColumn(true)
          //   setElementClicked(event.currentTarget)
          // }}

        >
          {/* <ViewWeekIcon sx={{color:'#fffff'}}/> */}
          <AltRouteIcon sx={{
            color: '#fff',
            fontSize: '25px',
          }} />
        </Box>

      </Box>
     <RenderContent
        phone={phone}
        task_id={props?.object?.task_id}
        editMode={props?.object?.task_id ? true : false}
        setPhone={setPhone}
        requestLocation={requestLocation}
        setRequestLocation={setRequestLocation}
        requestType={requestType}
        setRequestType={setRequestType}
        requestTypes={requestTypes}
        unit={unit}
        setUnit={setUnit}
        units={filteredUnits}
        setUnits={setUnits}
        preferedDate={preferedDate}
        setPreferedDate={setPreferedDate}
        preferedTime={preferedTime}
        setPreferedTime={setPreferedTime}
        notes={notes}
        setNotes={setNotes}
        customerName={customerName}
        userName={userName}
        requestDate={requestDate}
        status={status}
        inprogressDateTime={inprogressDateTime}
        closeingDateTime={closeingDateTime}
        setImageList={setImageList}
        imageList={imageList}
        unitNameSearch={unitNameSearch}
        setUnitNameSearch={setUnitNameSearch}
        handleSearchInAddvancSearch={handleSearchInAddvancSearch}
        handleClearInAddvancSearch={handleClearInAddvancSearch}
        setListOfSelectedRows={setListOfSelectedRows}
        listOfSelectedRows={listOfSelectedRows}
        isSearchApplay={isSearchApplay}
        checkAllselected={checkAllselected}
        setCheckAllselected={setCheckAllselected}
        setCheckAllNotSelected={setCheckAllNotSelected}
        checkAllNotSelected={checkAllNotSelected}
        open={props.addForm}
        setOpen={props.setAddForm}
        isFullScreen={true}
      />
      </Box>
  )
}
