import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  translation_en: {
    GLOBAL: {},
    LOGIN:{
      login_title:'Login'
    }
  },
  translation_ar: {
    GLOBAL:{
      cancel_button:'الغاء',
      save_button:'حفظ',
      GRID_NO_DATA:'لا توجد بيانات',
      GRID_NO_RESULT_DATA:'لا توجد بيانات',
      action_edit:'تعديل',
      action_view:'عرض',
      action_delete:"حذف",
      action_rating:'تقييم',
      SEARCH_RESULT_OF_SEARCH:"النتيجة",
      SEARCH_SEARCH_BUTTON:'بحث',
      SEARCH_CLEAR_BUTTON:"مسح",
      Save:'حفظ',
      close:'اغلاق',
      SEARCH_RESULT_OF_selected:"المحددة",
      alertPopup:'تحديث بيانات المستخدم',
      updateUserInfo:'تحديث',
      endSessionMessage:'تم انتهاء الجلسة الخاصة بك سيتم تحويلك لصفحة التسجل خلال',
      endSessionMessageUnits:'ثانية',
      sorryMessage:"عذرا لم نتمكن من تحويلك الى صفحة تسجيل الدخول، رجاءا قم  بتسجيل الدخول مره اخرى من النظام الرئيسي",
      pageNotFoundMessage:"عذرا الصفحة غير موجوده",
      clear:'مسح',
      action_share:'مشاركة مع الاخرين',
      action_check:'اختر'
    },
    LOGIN:{
      login_title:'تسجيل دخول',
      company_name:'نظام Milestone',
      company_title:'لادارة المجمعات السكنية والعقارات',
      wellcome_message:'مرحبا بك في نظام Milestone',
      Introductory_message:'هذا نظام آمن وستحتاج إلى تقديم تفاصيل تسجيل الدخول الخاصة بك للوصول إلى الموقع.',
      user_name:'اسم المستخدم',
      user_password:'كلمة المرور',
      checkbox_remember_me:'تذكرني',
      login:'تسجيل دخول',
      forget_password:'هل نسية كلمة المرور؟',
      footer_message:'مقدم من شركة ركن الحلول المتكاملة لتقنية المعلومات',
      reset_password_title:'أكتب رقم الهاتف الخاص بك لاستعادة كلمة المرور',
      phone_number:'رقم الهاتف',
      Submit:'أرسال',
      recived_sms_code_title:'أدخل الرمز المرسل اليك برسالة قصيرة',
      not_recivied_message:'لم يصلك الرمز ؟',
      reSend_message:'أعادة ارسال',
      new_password_form_title:'يرجى كتابة كلمة المرور الجديدة الان',
      confirm_user_password:'تأكيد كلمة المرور',
      save_changes_passwords:'حفظ التغييرات',
    },
    ERROR:{
      login_username_error:'اسم المستخدم مطلوب',
      login_userpassword_error:'يجب ادخال كلمة المرور',
      userName_or_password_error:'اسم المستخدم او كلمة المرور غير صحيحة'
      
    },
    SIDEMENU:{
      dashboard:'لوحة التحكم',
      Requests:'الطلبات',
      profiel:'الملف الشخصي',
      logout:'تسجيل خروج',
      units:'الوحدات'
    },
    DASHBOARD:{
      request_wait:'الطلبات  في الانتظار',
      request_canceled:'الطلبات الملغاة',
      request_completed:'الطلبات المكتملة',
      request_sum:'جميع الطلبات',
      unit_counts:'عدد الوحدات',
      title:'لوحة التحكم',
      request_new:'الطلبات الجديدة',
      show_status_title:'مخطط نسب الحالات للطلبات',
      show_RatedScore_title:'مخطط التقييم للطلبات'
    },
    ADVANCESEARCH:{
      title:'البحث عن عملاء',
      customer_name:'أسم العميل',
      customer_phone_number:'رقم الهاتف',
      Name_ofCumpany:"تاريخ التسجيل",
      fromDate:'من ',
      toDate:'الى',
      no_cutomerTypeData:'لا توجد بيانات',
      SelectCustomerWorkType:'نوع العميل',
      salesEmployee:'موظف المبيعات',
      selectedReason:'الغرض',
      no_resones:'لا توجد بيانات',
      advancesearch:'البحث المتقدم',
      applaySearch:'تطبيق البحث',
      sideOfWork:'جهة العمل',
      no_workside:'لا توجد بيانات',
      companyname:'اسم الشركة',


    },
    REQUESTS:{
      title:'الطلبات',
      ID:'#',
      accountName:'اسم الحساب',
      accountUserName:'اسم المستخدم',
      dateforRequest:'رقم وتاريخ الطلب',
      requestNumber:'رقم الطلب',
      requestType:'نوع الطلب',
      deviceNumber:'رقم الجهاز',
      dateAndFavoriteTIme:'التاريخ والوقت المفضل',
      status:'الحالة',
      actions:'الوظائف',
      archived_request:"مؤرشفة",
      approved_request:"تمة الموافقة",
      rejected_request:"رفضت",
      closed_request:"مغلقة",
      inprogress_request:"قيد المعالجة",
      new_request:'جديد',
      requestDate:'من تاريخ الطلب',
      endrequestDate:'الى تاريخ طلب',
      unitName:'اسم الوحدة',
      addTitle:'أضافة طلب',
      updateTitle:'تعديل بيانات الطلب',
      add_phone:'رقم الهاتف',
      add_location:'مكان الطلب',
      add_request_type:'نوع الطلب',
      add_units:'الوحدات',
      add_prefered_date:'التاريخ المفضل',
      add_prefered_time:'الوقت المفضل',
      add_notes:'الملاحضات',
      add_customer_name:'اسم الزبون',
      add_username:'اسم المستخدم',
      add_request_date:'تاريخ الطلب',
      add_status:'الحالة',
      add_inprogress_dateTime:'وقت وتاريخ بدء العمل',
      add_closing_dateTIme:'وقت وتاريخ الاغلاق',
      add_unit_reasone:'السبب',
      add_unit_name:'اسم الوحدة',
      add_unit_id:'#',
      no_menu_itme:'لا توجد عناصر تحكم',
      viewRequest:'عرض تفاصيل الطلب',
      units_list:'قائمة الوحدات',
      ratedRequest:'نوع التقييم',
      rating:'التقييم',
      addRating:'اضافة تقييم',
      add_ratingsNot:'ملاحظات',
      add_rating_suggestions:'اي اقتراح',
      maessage_after_rating_1:'شكرا لوقتك. نحن ناسف لتجربتك الغير جيدة. سوف يتم الاتصال بحضرتك لبحث سبب المشكلة والعمل على حلها في المستقبل',
      maessage_after_rating_2:'شكرا لوقتك. نحن ناسف لتجربتك الغير جيدة. سوف يتم الاتصال بحضرتك لبحث سبب المشكلة والعمل على حلها في المستقبل',
      maessage_after_rating_3:'شكرا لوقتك. على ما يبدوا ان تجربتك لم تكن ممتازة. سوف يتم مراجعة تقييمك والعمل على اصلاح الخلل',
      maessage_after_rating_4:'شكرا لوقتك. نحن نعمل على تقديم أفضل الخدمات. نأمل بان نقدم لك خدمة أفضل في المستقبل',
      maessage_after_rating_5:'شكرا لوقتك. نحن سعداء بخدمتك',
      unitName_details:'تفاصيل الوحدات',
      reasonTitle:"اضفافة ملاحظة",
      notesReason:'ملاحظة',
      noteReason:'ملاحظة',
      add_unit_notes:'السبب',
      commentsCount:'عدد التعليقات',
      viewRequestComments:'التعليقات',
      commentText:'التعليق',
      commentDate:'تاريخ التعليق',
      commenter:'المعلق',
      noData:'لا توجد بيانات',
      youMustSelectTask:'يجب ان تختار المهمة ',
      comments:'التعليقات',
      Images:'الصور',
      shareTask:'مشاركة الطلب'
    },
    NETWORKMESSAGE:{
      addCustomerMessageSuccess:'تم اضافة المعلومات بنجاح',
      updateCustomerMessageSuccess:'تم تحديث المعلومات بنجاح',
      addCustomerMessageError:'خطء غير معروف',
      deleteErrorr:'خطء غير معروف',
      deleteSuccess:'تم الحذف بنجاح'
    },
    TOOLTIP:{
      EXPORT:'تصدير',
      IMPORT:'استيراد',
      CREATE:'انشاء',
      MAP:'خريطة',
      TELEGRAM:'تليكرام',
    },
    PROFILE:{
      email:'البريد الالكتروني',
      USER_TITLE:'معلومات المستخدم',
      FULL_NAME:"أسم المستخدم كامل",
      USERNAME:'اسم المستخدم',
      PHONE:"رقم الهاتف",
      UPDATE_BUTTON:"تحديث البيانات",
      userEmailsTitle:'رسائل البريد الإلكتروني المشتركة للمستخدم',
      userEmailName:'الاسم',
      userEmailEmail:'البريد الالكتروني',
      userEmailAction:'وظائف',
      addUserEmailTitle:'أضافة بريد',
      updateUserEmailTitle:'تعديل بيانات البريد'
    },
    UNITS:{
      title:'الوحدات',
    }
  },

};
export const TranslateSlice = createSlice({
  name: "translateData",
  initialState: initialState,
  reducers: {
    setTranslateEnglish:(state,action)=>{
      state.translation_en=action.payload
    },
    setTranslateArabic:(state,action)=>{
      state.translation_ar=action.payload
    },
  },
});

export const {
  setTranslateEnglish,
  setTranslateArabic,
} = TranslateSlice.actions;

// export const sideMenuMode = state => state.settingsData.sideMenuMode;

export default TranslateSlice.reducer;
