import React from 'react'
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
// import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import {Drawer,AppBar,DrawerHeader} from './SideMenuTheme'
import { useSelector,useDispatch } from 'react-redux';
import HeaderDrawer from '../Components/HeaderDrawer';
import SideMenuItemArray from './SideMenuItemArray';
import { useTranslation } from "react-i18next";
import LogoutIcon from '@mui/icons-material/Logout';
import {useLocation} from 'react-router-dom'

import DashboardIcon from '@mui/icons-material/Dashboard';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import * as FaIcons from 'react-icons/fa'
import * as BsIcons from 'react-icons/bs'
import * as ImIcons from 'react-icons/im'
import * as MdIcons from 'react-icons/md'
import * as BiIocns from 'react-icons/bi'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { toast } from 'react-toastify';

import {Outlet} from 'react-router-dom'
import Cookies from 'universal-cookie'
import {useNavigate} from 'react-router-dom'
import ShapeLineIcon from '@mui/icons-material/ShapeLine';
import WialonLogo from "../assets/img/Logo.png";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {setIsOpenSideMenu} from '../reduxStore/SettingsReducer'
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import profileAPI from '../Network/Profile';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import Preload from '../Pages/Preload/Preload';
import userImage from '../assets/img/user.png'


const APP_CONSTANTS = {
  API_URL_IMG: `${process.env.REACT_APP_API_URL}`,
}
const cookies=new Cookies()
const tempImgURL="https://images.unsplash.com/photo-1630945386735-372fbe731e3f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTZ8fHBlcG9sZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"

export default function SideMenu() {
  const [t] = useTranslation("common");
  const location=useLocation()
  const drawerwidth = useSelector(state=>state.settingsData.drawerwidth)
  const sidemenutheme=useSelector(state=>state.themeData.sidemenutheme)
  const appbarTheme=useSelector(state=>state.themeData.appbar)
  const maintheme=useSelector(state=>state.themeData.appbar)
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const dispatch=useDispatch()
  const [userInfo,setUserInfo]=React.useState();
  const globalLoading = useSelector(state=>state.settingsData.globalLoading)
 
  const handleDrawerOpen = () => {
    setOpen(true);
    dispatch(setIsOpenSideMenu(true))
  };
  const handleDrawerClose = () => {
    setOpen(false);
    dispatch(setIsOpenSideMenu(false))
  };
  const navigate=useNavigate();

  const getUserData=async()=>{
    let data=null
    try{
      let params={
          username:cookies.get('user_name'),
          user_id:cookies.get('user_id'),
          account_name:cookies.get('account_name'),
          account_id:cookies.get('account_id'),
          sid:cookies.get('sid'),
          baseurl:cookies.get('baseUrl')
      }


      data=await profileAPI.Profile({
          params:params,
      });
      if(data&&data?.status){
        //   console.log('userinfo',data?.data)
        if(data?.data&&data?.data?.data){
          setUserInfo({
            ...userInfo,
            phone:data?.data?.data?.phone,
            full_name:data?.data?.data?.name,
            img_url:APP_CONSTANTS.API_URL_IMG+"uploads/users/"+data?.data?.data?.image_path,
            email:data?.data?.data?.email,
            hasImage:data?.data?.data?.image_path?true:false
           })
           cookies.set('phone', data?.data?.data?.phone?data?.data?.data?.phone:'', { path: '/' });

        }
        // toast.success(t('NETWORKMESSAGE.updateCustomerMessageSuccess'))
        if(data&&data?.data&&data?.data?.data?.message){
          if(data?.data?.data?.message?.text=="unauthorized to complete"){
             navigate('/redirect')
          }
        }
      }else{
        toast.warning(t('NETWORKMESSAGE.addCustomerMessageError'))
        if(data&&data?.data&&data?.data?.data?.message){
          if(data?.data?.data?.message?.text=="unauthorized to complete"){
             navigate('/redirect')
          }
        }
      }
    }catch(e){
       console.log(e?.message)
    }
   }
   React.useEffect(()=>{
    getUserData()
   },[])

  const pathsList=React.useMemo(()=>[
    {
        id:1,
        name:t('SIDEMENU.dashboard'),
        to:'dashboard',
        component:'',
        icon:<Tooltip arrow placement="right" title={!open?<span style={{fontFamily:'Cairo-Medium'}}>{t('SIDEMENU.dashboard')}</span>:''} ><DashboardIcon className="iconSideMenu"/></Tooltip>,
        subLink:[],
    },
    {
        id:2,
        name:t('SIDEMENU.Requests'),
        to:'requests',
        component:'',
        icon:<Tooltip arrow placement="right" title={!open?<span style={{fontFamily:'Cairo-Medium'}}>{t('SIDEMENU.Requests')}</span>:''} sx={{fontFamily:'Cairo-Bold'}}><span><BiIocns.BiGitPullRequest className="iconSideMenu" sx={{transform:' matrix(0, -1, 1, 0, 0, 0)'}}/></span></Tooltip>,
        subLink:[],
    },
    {
      id:2,
      name:t('SIDEMENU.profiel'),
      to:'profile',
      component:'',
      icon:<Tooltip arrow placement="right" title={!open?<span style={{fontFamily:'Cairo-Medium'}}>{t('SIDEMENU.profiel')}</span>:''} sx={{fontFamily:'Cairo-Bold'}}><span><AccountCircleIcon className="iconSideMenu" /></span></Tooltip>,
      subLink:[],
  },
  {
    id:2,
    name:t('SIDEMENU.units'),
    to:'units',
    component:'',
    icon:<Tooltip arrow placement="right" title={!open?<span style={{fontFamily:'Cairo-Medium'}}>{t('SIDEMENU.units')}</span>:''} sx={{fontFamily:'Cairo-Bold'}}><span><AltRouteIcon className="iconSideMenu" /></span></Tooltip>,
    subLink:[],
},
   ],[open])

  const renderComponent=()=>{
    let check=false;
    pathsList&&pathsList?.length>0&&pathsList?.map((itm)=>{
      if(location?.pathname==itm?.to){
        return itm?.component;
      }
    })
    
  }
  const getUserImage=()=>{
      return userInfo?.hasImage?userInfo.img_url:userImage
  }
  React.useEffect(() => {
 console.log('globalLoading',globalLoading)
  }, [globalLoading]);

  const logout=()=>{
    if(cookies.get('token'))
    cookies.remove('token')
    if(cookies.get('language'))
    cookies.remove('language')
    if(cookies.get('access_type'))
    cookies.remove('access_type')
   
    cookies.set('sid',null)
    window.location.href=cookies.get('hostUrl')
  }

  return (
    <Box sx={{ display: 'flex', transition:'all 0.9 ease !important' }}>
    <CssBaseline />
    <AppBar position="fixed" open={open} drawerwidth={drawerwidth} sidemenutheme={sidemenutheme} appbarTheme={appbarTheme}>
      <Toolbar
       sx={{
        display:'flex',
        alignItems:'center'
       }}
      >
        {/* <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton> */}
        {/* <Typography variant="h6" noWrap component="div" style={{
          color:appbarTheme?.colorWhite,
          textAlign: 'left',
          font: 'normal normal 21x/40px Cairo-Bold',
          letterSpacing:' 5.5px',
          color: '#FFFFFF',
          opacity: 1,
          marginLeft:'15px'
        }}>
          MILESTONE
        </Typography> */}
        <img src={WialonLogo}  style={{
           width: '162px',
           height:' 48px',
           dropShadow: '0px 3px 6px #00000029',
           objectFit:'cover',
           backgroundPosition:'center center',
           opacity: 1,
           position:'relative',
           top:'2px'
        }}/>
      </Toolbar>
    </AppBar>
    <Drawer 
    variant="permanent" 
    open={open} 
    drawerwidth={drawerwidth} 
    sidemenutheme={sidemenutheme}
    > 
      <HeaderDrawer 
        open={open} 
        setOpen={setOpen}
        handleDrawerClose={handleDrawerClose}
        handleDrawerOpen={handleDrawerOpen}
        getUserImage={getUserImage}
      />

      <Divider sx={{
        width: open?'92%':"50%", 
        height: "2px", 
        background: "#EBF2F7 0% 0% no-repeat padding-box", 
        margin:'10px auto',
        opacity: "1",
        display:'flex',
        alignSelf:'center'
      }}/>

      <Box sx={{display:'flex',flexDirection:'column',alignItems:'start',justifyContent:'start',width:'100%',height:'100%'}}>
        <Box sx={{height:'100% !important',width:'100%',height:'fit-content'}}>
          <SideMenuItemArray open={open} setOpen={setOpen} pathsList={pathsList}/>
        </Box>
        {/* <Box sx={{height:'130px',width:"100%",marginTop:10}}></Box> */}
        <Box
          sx={{
              position:'fixed',
              bottom:'0px',
              backgroundColor:sidemenutheme?.backgroundColor,
              left:0,
              right:0,
              // height:'10% !important',
              // flex:0.1,
              width:open?drawerwidth-1:'83px',
              boxShadow: "0px 4px 6px #00000029;", 
              display:'flex',
              // marginTop:open?'30px':'70px',
              justifyContent: 'center',
              flexDirection:'column',
              alignItems: open?'start':'center',
              '& .iconSideMenu':{
                  color: `${sidemenutheme?.iconColor}`,
                  fontSize:'28px'
              },
              '& .MuiListItemText-root .Mui51Typography-root,& .logout':{
                  textAlign: "left", 
                  font: "normal normal 15px/30px Cairo-Medium !important", letterSpacing: "0px", color: "#52575D", opacity: "1"
              },
              "& .logout":{
                margin:'0 7px !important',
                position: "relative",
                top:'-2px'
              },
              transition:'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
              // boxShadow:'-9px 3px 15px -7px #000'
          }}
        >
          <Divider 
          sx={{
              width: !open?"60%":'92%', 
              height: "3px", 
              background: "#EBF2F7 0% 0% no-repeat padding-box", 
              margin:'0px auto',
              // marginRight:open?0:'15px',
              opacity: "1",
              marginLeft:open?'9px':'17px',
              display:'flex',
              // alignSelf:'center'
          }}/>

            <Box  sx={{ marginLeft:'10px',padding:'15px 10px',display: 'flex !important',justifyContent:open?'start':'center',alignItems:'center',cursor:'pointer'}}>
                  <LogoutIcon onClick={logout} sx={{transform:' matrix(-1, 0, 0, -1, 0, 0)'}} className="iconSideMenu"/>
                  {open? <span onClick={logout} className='logout' sx={{ opacity: open ? 1 : 0,margin:'0 10px !important' }} >{t('SIDEMENU.logout')}</span>:null}
             
            </Box>
        </Box>
      </Box>

    </Drawer>
    <Box component="main" sx={{ 
      flexGrow: 1, 
      p: 3,
      marginTop:window.location.pathname=='/admin/available-compounds/compound-map'?'0px':'60px',
      backgroundColor:'transparent',
      // paddingRight:0,
      // paddingLeft:0,
      minWidth:'900px !important'
      }}>

      {/* <DrawerHeader /> */}
        <Outlet/>
 
    </Box>
  </Box>
  )
}
