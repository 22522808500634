import { Box,TextField,Button,Autocomplete} from '@mui/material'
import React,{useState,useEffect}from 'react'
import PopupForm from './PopupForm'
import {useTranslation} from 'react-i18next'
import {ContainerOfInput,ContainerOfInputFields,ContainerOfSelectField} from '../Components/ThemDesign'
import { useSelector } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close';
import StarsIcon from '@mui/icons-material/Stars';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import AddAlertIcon from '@mui/icons-material/AddAlert';

const APP_CONSTANTS = {
    API_URL_IMG: `${process.env.REACT_APP_API_URL_image}`,
}

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },

}));


const RenderContent=({phoneError,setPhoneError,...props})=>{
    const [t]=useTranslation('common')
    const maintheme=useSelector(state=>state.themeData.maintheme);

    const handleChange=(value,number,errName)=>{
      if(number==1){
         props.setFullName(value.target.value)
      }else if(number==2){
        props.setPhone(value.target.value)
        handlePhoneChange(value)
      }
    }
    const clearInput=(number,errName)=>{
        if(number==1){
           props.setFullName('')
        }else if(number==2){
          props.setPhone('')
        }
    }
    const handlePhoneChange=(e)=>{
      let inputtxt=e.target;
      var phoneno = /^\d{11}$/;
  
      let object={
        ...phoneError,
        mes:'',
        err:false,
      }
      if(isNaN(inputtxt.value)){
        setPhoneError({
          ...phoneError,
          mes:'يجب ان يكون الهاتف رقم',
          err:true,
        })
        return false
      }
  
      if(e?.target?.value?.length==11&&!isNaN(inputtxt.value))
      {
        object={
          ...phoneError,
          mes:'',
          err:false,
        }
      }
      else if(e?.target?.value?.length>=11)
      {
        object={
          ...phoneError,
          mes:'رقم الهاتف بصيغة غير صحيحة !',
          err:true,
        }
      } else if(e?.target?.value?.length<11)
      {
        object={
          ...phoneError,
          mes:'رقم الهاتف يجب ان يتكون من 11 رقم !',
          err:true,
        }
      }else{
        object={
        ...phoneError,
        mes:'',
        err:false,
      }
      }
  
      setPhoneError({
        ...phoneError,
          ...object,
      })
    }
   
    return(
        <Box className='Container-fluid'>
            <Box className="row d-flex justify-content-center align-items-start">
            <Box className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                    <ContainerOfInputFields
                            mainTheme={maintheme}
                            customeWidth={"100%"}
                            isForm={true}
                    >
           
                        <Box
                            sx={{
                            position: "relative",
                            // margin: "5px 0",
                            width: "100%",
                            backgroundColor: "#fff",
                            }}
                            className="mt-3 orderdata"
                           >
                            <TextField
                            id="standard-basic"
                            variant="filled"
                            label={t("PROFILE.FULL_NAME")}
                            value={props?.fullName}
                            onChange={(e)=>handleChange(e,1,'fullName')}
                            placeholder="..."
                            type={'text'}
                            />
                            {props?.fullName ? (
                            <CloseIcon
                            className="closeIcon"
                            onClick={()=>clearInput(1,'fullName')}
                            />
                            ) : null}
                        </Box>
                    
                        <Box
                            sx={{
                            position: "relative",
                            // margin: "5px 0",
                            width: "100%",
                            backgroundColor: "#fff",
                            '& .MuiFormHelperText-root':{
                              fontFamily:'Cairo-Light'
                            }
                            }}
                            className="mt-3 orderdata"
                        >
                            <TextField
                            id="standard-basic"
                            variant="filled"
                            label={t("PROFILE.PHONE")}
                            value={props?.phone}
                            onChange={(e)=>handleChange(e,2,'phone')}
                            placeholder="07511111111" 
                            error={phoneError?.err}
                            helperText={phoneError?.mes}
                            type={'text'}
                            />
                            {props?.phone ? (
                            <CloseIcon
                            className="closeIcon"
                            onClick={()=>clearInput(2,'phone')}
                            />
                            ) : null}
                        </Box>
                    </ContainerOfInputFields>
                 
                </Box>
            </Box>
        </Box>
    )
}

const RenderFooter=(props)=>{
    const [t]=useTranslation('common')
    const gridtheme=useSelector(state=>state.themeData.gridtheme)

    return(
        <Box sx={{
            display:'flex',
            alignItems:'center',
        }}>
          

          <Box sx={{display:'flex',justifyContent: 'end',alignItems: 'center',width:'100%'}}>
          <Box display="flex" >
                  
                  <Button
                      variant="contained"
                      // spacing={2} 
                      sx={{ 
                          // flexGrow: 1,
                          margin: 1,
                          width:'80px !important',
                          minWidth:'80px !important',
                          maxWidth:'80px !important',
                          '&:hover':{
                            //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
                          },
                          height:'35px',
                          fontFamily:'Cairo-Bold',
                          backgroundColor:'#369df7'
                      }}
                      className="iconeFilterSearch"

                      onClick={() => {
                            props.submit({
                                name:props?.fullName,
                                phone:props?.phone,
                            })
                      }} 
                      >
                      {t('GLOBAL.updateUserInfo')}
                  </Button>
                  
          </Box>
      </Box>
        </Box>
    )
}


export default function PopupAlert(props) {
    const [t]=useTranslation('common');
    const [loading,setLoading]=useState(false)
    const [taskId,setTaskId]=useState();
    const [ratingValue,setRatingValue]=useState(0);
    const [ratingNotes,setRatingNotes]=useState('')
    const [ratingSuggestions,setRatingSuggestions]=useState('')
    const [messgaeAfterRating,setMesageAfterRating]=useState("1 شكرا لوقتك. نحن ناسف لتجربتك الغير جيدة. سوف يتم الاتصال بحضرتك لبحث سبب المشكلة والعمل على حلها في المستقبل2 شكرا لوقتك. نحن ناسف لتجربتك الغير جيدة. سوف يتم الاتصال بحضرتك لبحث سبب المشكلة والعمل على حلها في المستقبل3 شكرا لوقتك. على ما يبدوا ان تجربتك لم تكن ممتازة. سوف يتم مراجعة تقييمك والعمل على اصلاح الخلل4 شكرا لوقتك. نحن نعمل على تقديم أفضل الخدمات. نأمل بان نقدم لك خدمة أفضل في المستقبل5 شكرا لوقتك. نحن سعداء بخدمتك")
    const [ratingComplete,setRatingComplete]=useState(false)
    const [phone,setPhone]=useState(props?.phone?props?.phone:'')
    const [name,setName]=useState(props?.username?props?.username:'')

    useEffect(()=>{
        clearForm()
        if(props?.object){
          setRatingValue(props?.object?.rating_score)
          setTaskId(props?.object?.task_id)
        }
    },[props?.object])

    const clearForm=()=>{
      
    }
    useEffect(()=>{
      if(!props?.object)
      clearForm()
    },[props?.addForm,props?.object])
    useEffect(()=>{
      setRatingValue(0)
      return()=>{
        setRatingComplete(false)
      }
    },[])

  return (
       <PopupForm 
        open={props.addForm} 
        setOpen={props.setAddForm}
        notClose={true}
        title={t('GLOBAL.alertPopup')}
        subTitle={"مرحبا بك في نظام خدمة العملاء. يرجى ادخال بيانات المستخدم ليتسنى لنا الاتصال بحضرتك عند الحاجة."}
        hasSubTitle={!ratingComplete?true:false}
        isFullScreen={false}
        loading={loading}
        hasIconheader={!ratingComplete?true:false}
        customeHeight="45%"
        customeWidth="45%"
        isCenter={true}
        iconHeader={<AddAlertIcon 
        sx={{
          position:'absolute',
          top:'13px',
          right:'-20px',
          fontSize:'40px',
          color:'#369df7'
        }}/>}
        content={<RenderContent 
          loading={loading}
          fullName={name}
          setFullName={setName}
          phone={phone}
          setPhone={setPhone}
          setPhoneError={props?.setPhoneError}
          phoneError={props?.phoneError}
          setRatingSuggestions={setRatingSuggestions}
        open={props.addForm} 
        setOpen={props.setAddForm}
        isFullScreen={false}
        />}
        footer={<RenderFooter
            open={props.addForm} 
            setOpen={props.setAddForm}
            ratingComplete={ratingComplete}
            setRatingComplete={setRatingComplete}
            fullName={name}
            phone={phone}
            submit={props.submit}
        />}
       />
  )
}
