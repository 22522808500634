import { Box, Divider, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import './style.css'
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import DoDisturbOffIcon from '@mui/icons-material/DoDisturbOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import * as TbIcons from 'react-icons/tb'
import { useNavigate } from "react-router-dom";
import StarsIcon from '@mui/icons-material/Stars';
import AddIcon from '@mui/icons-material/Add';
import DashboardIcon from '@mui/icons-material/Dashboard';

import PieChart from "./Components/PieChart";
import dashboardAPI from "../../Network/Dashboard";
import Cookies from 'universal-cookie';
import { toast } from 'react-toastify';
import StarsChart from "./Components/StarsChart";
import Preloading from '../../Pages/Preload/Preloading';

import StarIcon from '@mui/icons-material/Star';
import { useSelector } from "react-redux";
import $ from 'jquery'
const cookies = new Cookies();

export default function Dashboard() {
  const [t] = useTranslation("common");
  const [loading, setLoading] = useState(true)
  const [dashboardObject, setDashboardObject] = useState({
    all_statuses: 0,
    closed_canceld_or_failed: 0,
    closed_successful: 0,
    in_progress: 0,
    new: 0,
    units:{
      unit_counts:0
    },
    rate_scores:[]
  })
  const globalLoading=useSelector(state=>state.settingsData.globalLoading)
  const [dashboardData,setDashboardData]=useState(null)
  const controller = new AbortController();
  const [units,setUnits]=useState([])

  useEffect(() => {
    loadData()
    getAllUnits();
  }, [])

  const loadData = async (searchParams) => {
    if (cookies.get('active_session') && cookies.get('active_session') == 'yes') {
      setLoading(true)
      const accountName = cookies.get('account_name') ? cookies.get('account_name') : null
      const accountUserName = cookies.get('user_name') ? cookies.get('user_name') : null
      const accountUserId = cookies.get('user_id') ? cookies.get('user_id') : null
      const accountId = cookies.get('account_id') ? cookies.get('account_id') : null
      const authHash = cookies.get('authHash') ? cookies.get('authHash') : null
      const sid = cookies.get('sid') ? cookies.get('sid') : null
      const baseUrl = cookies.get('baseUrl') ? cookies.get('baseUrl') : null

      const result = await dashboardAPI.dashboard({
        params: {
          // page_size: pageSize,
          username: accountUserName,
          user_id: accountUserId,
          account_name: accountName,
          account_id: accountId,
          authHash: authHash,
          sid: sid,
          baseurl: baseUrl,

        },
        signal: controller?.signal
      })
      setLoading(false)
      console.log('asdadasdaasdasdasdas', result)
      if (result?.data?.status) {
        setDashboardObject({
          ...dashboardObject,
          ...result?.data?.data
        })
        if(result&&result?.data&&result?.data?.data?.message){
          if(result?.data?.data?.message?.text=="unauthorized to complete"){
             navigate('/redirect')
          }
        }
      }
      else {
        if(result&&result?.data&&result?.data?.data?.message){
          if(result?.data?.data?.message?.text=="unauthorized to complete"){
             navigate('/redirect')
          }
        }
        // alert('3')
        if (!result?.data?.error) {
          toast.error(t('NETWORKMESSAGE.addCustomerMessageError'))
          return
        }
      }
      setLoading(false)
    } else {
      navigate('/redirect')
    }

  }
  const navigate = useNavigate();

  const goToWaitStatus = () => {
    navigate('/system/requests', {
      state: {
        data: {
          status_id: 2
        }
      }
    });
    localStorage.removeItem('isUseNavigation');
  }
  const goToRejectStatus = () => {
    navigate('/system/requests', {
      state: {
        data: {
          status_id: 4
        }
      }
    });
    localStorage.removeItem('isUseNavigation');
  }
  const goToCompleteStatus = () => {
    navigate('/system/requests', {
      state: {
        data: {
          status_id: 3
        }
      }
    });
    localStorage.removeItem('isUseNavigation');
  }
  const goToNewStatus = () => {
    navigate('/system/requests', {
      state: {
        data: {
          status_id: 1
        }
      }
    });
    localStorage.removeItem('isUseNavigation');
  }
  const createFUnction = () => {
    navigate('/system/requests', {
      state: {
        data: {
          is_createdMod: true
        }
      }
    });
    localStorage.removeItem('isUseNavigation');
  }
  const handleEWvalutionsRequest = () => {
    navigate('/system/requests', {
      state: {
        data: {
          is_ratedMode: true,
          status_id:3,
        }
      }
    });
    localStorage.removeItem('isUseNavigation');
  }
  const goToRequestPage=()=>{
    navigate('/system/requests', { replace: true });
  }

  const goToUnitsPage=()=>{
    navigate('/system/units', { replace: true });
  }

    //get all units from wialon her...
  const getAllUnits = () => {
      // console.log('entered_here')
      if (cookies.get('active_session') && cookies.get('active_session') == 'yes') {
        // console.log('entered_here2')
  
        const newSid = cookies.get('sid')
        $.ajax({
          type: "GET",
          enctype: "application/json",
          processData: !1,
          contentType: !1,
          crossDomain: true,
          dataType: "jsonp",
          url: cookies.get('baseUrl') + '/wialon/ajax.html?svc=core/search_items&params={"spec":{"itemsType":"avl_unit","propName":"sys_name","propValueMask":"*","sortType":"sys_name","propType":"property"},"force":0,"flags":1,"from":0,"to":0}&sid=' + newSid,
          success: function (data) {
            // console.log('allunitsdataشسيش', data);
            setUnits(data?.items?.length > 0 ? data?.items : [])
          },
          error: function (err) {
            console.log('allunitsdataشسيش', err)
          }
        })
      } else {
        navigate('/')
      }
  }


  return (
    loading||globalLoading?<Preloading dashboardMode={true}/>
    :
    <Box className="dashboard container-fluid">

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'stretch',
          // backgroundColor: '#fff',
          position: 'relative',
          paddingTop: '20px'
          // top:'-25px'
        }}
      >
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          margin: '0 30px'
        }}>
          <Box sx={{ marginRight: '10px', backgroundColor: '#1E6A99', color: '#FFFFFF', width: '45px', height: '45px', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
            onClick={createFUnction}
          >
            {/* <ViewWeekIcon sx={{color:'#fffff'}}/> */}
            <Tooltip title={<span style={{fontFamily:'Cairo'}}>أنشاء طلب</span>} >
              <AddIcon sx={{
                color: '#fff',
                fontSize: '40px',
              }} />
            </Tooltip>
          </Box>
          <Box sx={{ marginRight: '10px', backgroundColor: '#878703', color: '#FFFFFF', width: '40px', height: '40px', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
            onClick={handleEWvalutionsRequest}
          >
            {/* <ViewWeekIcon sx={{color:'#fffff'}}/> */}
            <Tooltip title={<span style={{fontFamily:'Cairo'}}>عرض الطلبات الغير مقيمة</span>}>
              <StarsIcon sx={{
                color: '#fff',
                fontSize: '25px',
              }} />
            </Tooltip>
          </Box>

        </Box>

        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography
            variant='h4'
            sx={{
              marginLeft: '10px',
              marginBottom: '10px',
              fontFamily: 'Cairo-Bold',
              fontSize: '23px',
              letterSpacing: '-1px',
            }}>
            {t('DASHBOARD.title')}
          </Typography>
        </Box>

        <Box sx={{ marginRight: '20px', backgroundColor: '#1E6A99', color: '#FFFFFF', width: '46px', height: '40px', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
        // onClick={(event)=>{
        //   setOpenFilterColumn(true)
        //   setElementClicked(event.currentTarget)
        // }}

        >
          {/* <ViewWeekIcon sx={{color:'#fffff'}}/> */}
          <DashboardIcon sx={{
            color: '#fff',
            fontSize: '25px',
          }} />
        </Box>

      </Box>

      <Divider
        sx={{
          backgroundColor: '#EBF2F7',
          margin: '30px',
          marginTop: '10px',
          marginBottom: '10px',
          width: '100%'
        }}
        style={{
          height: '3px',
        }}
      />
      <div className="all-card">
        <div 
          style={{
            display:'flex',
            justifyContent:'stretch',
            alignItems:'center',
            flexWrap:'wrap',
            gap:'10px',
            width:'100%',
          }}
        >
            <div className="card card2" onClick={goToNewStatus}>
              <div className="card-body border-left-green"
                style={{
                  borderRightColor:'#43a3f7'
                }}
              >
                <div className="row">
                  <div className="col">
                    <p className="card-title text-title">{t('DASHBOARD.request_new')}</p>
                    <h2 className="card-text text-amount">{dashboardObject?.new}</h2>
                  </div>
                  <div className="col-auto">
                    <div className="icon-shape icon-complete" style={{backgroundColor:'#43a3f7'}}>
                      <CheckCircleIcon sx={{ color: '#fff', fontSize: '35px' }} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="cardCoverd">
                  <div >تفاصيل اكثر</div>
              </div>
            </div>
            <div className="card card2" onClick={goToWaitStatus}>
              <div className="card-body border-left-orange">
                <div className="row">
                  <div className="col">
                    <p className="card-title text-title">{t('DASHBOARD.request_wait')}</p>
                    <h2 className="card-text text-amount">{dashboardObject?.in_progress}</h2>
                  </div>
                  <div className="col-auto">
                    <div className="icon-shape icon-wait">
                      <HourglassTopIcon sx={{ color: '#fff', fontSize: '35px' }} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="cardCoverd">
                  <div >تفاصيل اكثر</div>
              </div>
            </div>
            <div className="card card2" onClick={goToCompleteStatus}>
              <div className="card-body border-left-green">
                <div className="row">
                  <div className="col">
                    <p className="card-title text-title">{t('DASHBOARD.request_completed')}</p>
                    <h2 className="card-text text-amount">{(dashboardObject?.closed_successful?dashboardObject?.closed_successful:0)+(dashboardObject?.approved?dashboardObject?.approved:0)}</h2>
                  </div>
                  <div className="col-auto">
                    <div className="icon-shape icon-complete">
                      <CheckCircleIcon sx={{ color: '#fff', fontSize: '35px' }} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="cardCoverd">
                  <div >تفاصيل اكثر</div>
              </div>
            </div>
            <div className="card card2" onClick={goToRejectStatus}>
              <div className="card-body border-left-pink">
                <div className="row">
                  <div className="col">
                    <p className="card-title text-title">{t('DASHBOARD.request_canceled')}</p>
                    <h2 className="card-text text-amount">{(dashboardObject?.closed_failed?dashboardObject?.closed_failed:0)+(dashboardObject?.closed_cancelled?dashboardObject?.closed_cancelled:0)+(dashboardObject?.rejected?dashboardObject?.rejected:0)}</h2>
                  </div>
                  <div className="col-auto">
                    <div className="icon-shape icon-canceled">
                      <DoDisturbOffIcon sx={{ color: '#fff', fontSize: '35px' }} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="cardCoverd">
                  <div >تفاصيل اكثر</div>
              </div>
            </div>
            
            <div className="card card2" onClick={goToRequestPage}>
              <div className="card-body border-left-green">
                <div className="row">
                  <div className="col">
                    <p className="card-title text-title">{t('DASHBOARD.request_sum')}</p>
                    <h2 className="card-text text-amount">{dashboardObject?.all_statuses}</h2>
                  </div>
                  <div className="col-auto">
                    <div className="icon-shape icon-complete">
                      <TbIcons.TbSum style={{ color: '#fff', fontSize: '35px' }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
     
        </div>
        <div className="row">
          <div className="col-lg- 6col-md-6 col-sm-5 col-6">
              <div className="card" onClick={goToUnitsPage}>
                <div className="card-body border-left-blue">
                  <div className="row">
                    <div className="col">
                      <p className="card-title text-title">{t('DASHBOARD.unit_counts')}</p>
                      <h2 className="card-text text-amount">{units?.length}</h2>
                    </div>
                    <div className="col-auto">
                      <div className="icon-shape icon-counts">
                        <AccountTreeIcon sx={{ color: '#fff', fontSize: '35px' }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-12 col-md-6 col-lg-6"  style={{marginRight:'0'}}>
             <div style={{
                width:'100%',
                height:'395px',
                backgroundColor:'#fff',
                boxShadow: '0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15)',
                display:'flex',
                justifyContent: 'center',
                flexDirection:'column',
                alignItems: 'center',
                borderRadius: '5px',
                overflowX:'scroll',
                padding:'0 20px',
                 position:'relative'


              }}>
                  <span
                    style={{
                    position:'absolute',
                    top:'10px',
                    right:'10px',
                    fontSize:'20px',
                    }}
                  >{t('DASHBOARD.show_RatedScore_title')}</span>
                 <StarsChart   rate_scores={dashboardObject?.rate_scores} handleEWvalutionsRequest={handleEWvalutionsRequest}/>
              </div>
          </div>
          <div className="col-12 col-md-12 col-md-6 col-lg-6">
            <Box sx={{
              width: '100%',
              height:'395px',
              backgroundColor: '#fff',
              boxShadow: '0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15)',
              display: 'flex',
              justifyContent: 'center',
              flexDirection:'column',
              alignItems: 'center',
              borderRadius: '5px',
              position:'relative'
            }}>
              <span style={{
                position:'absolute',
                top:'10px',
                right:'10px',
                fontSize:'20px',
              }}>{t('DASHBOARD.show_status_title')}</span>
              <PieChart dashboardObject={dashboardObject}   />
            </Box>
          </div>
        </div>
      </div>
    </Box>
  );
}
