import {styled} from '@mui/material/styles'
import {Box,TextField,Typography} from '@mui/material'
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

export const openedMixin = (theme,drawerwidth) => ({
    width: drawerwidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });
  
export const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 20px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 20px)`,
    },
  });
  
export const DrawerHeader = styled('div')(({ theme,sidemenutheme,drawerwidth  }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));
  
export const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open ,sidemenutheme,drawerwidth,appbarTheme }) => ({
    zIndex: 1100,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // ...(open && {
    //   marginLeft: drawerwidth,
    //   width: `calc(100% - ${drawerwidth}px)`,
    //   transition: theme.transitions.create(['width', 'margin'], {
    //     easing: theme.transitions.easing.sharp,
    //     duration: theme.transitions.duration.enteringScreen,
    //   }),
    // }),
    display:'flex',
    justifyContent:"flex-end",
    alignItems:'flex-End',
    height:'60px',
    backgroundColor:appbarTheme?.background,
    boxShadow:'0px 3px 6px #00000029 !important'
}));
  
export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open,sidemenutheme,drawerwidth }) => ({
      width: drawerwidth,
      height:'100%',
      display: 'flex !important',
      alignItems: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight:'0px',
      position:'relative',
      // height:'100%',
      '& .MuiPaper-root':{
        // position:'relative',
        padding:'10px 0px',
        // left:'30px',
        height:window.innerHeight-120+'px',
        paddingBottom:'40px',

        // height:'90%',
        top:'60px',
        // bottom:'10%',
        margin:'auto 0 !important',
        background: `${sidemenutheme?.backgroundColor} 0% 0% no-repeat padding-box`, 
        boxShadow: "0px 3px 6px #00000029;", 
        // height:'auto',
        // borderRadius: "10px", 
        opacity: "1"
      },
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme,drawerwidth),
        '& .MuiDrawer-paper': openedMixin(theme,drawerwidth),
      }),
      ...(!open && {
        ...closedMixin(theme,drawerwidth),
        '& .MuiDrawer-paper': closedMixin(theme,drawerwidth),
      }),
    }),
  );
  