import React from 'react'
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';

import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { ContainerOfInput, ContainerOfInputFields, ContainerOfSelectField } from '../../Components/ThemDesign'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { Box, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function AddReason(props) {
    const { onClose, selectedValue, open } = props;
    const gridtheme = useSelector(state => state.themeData.gridtheme)
    const maintheme = useSelector(state => state.themeData.maintheme)

    const [t]=useTranslation('common')

    const handleClose = () => {
      props?.setOpen(false)
      props?.clearFlieldAfterClose()
    };
  

  return (
     <Dialog 
     onClose={handleClose}
      open={props?.open}
      sx={{
        padding:'10px',
        "& .MuiPaper-root":{
            width:'400px',
            height:'fit-content',
            overflow:'hidden',
            display:'flex',
            justifyContent:'start',
            alignItems:'center',
            position:'relative',
        },
        '& h2':{
            textAlign:'center',
            position:'relative',
            left:'10px',
        }
      }}
     >
        <Box
         sx={{
          width:'35px',
          height:'35px',
          borderRadius:'50%',
          position:'absolute',
          top:'15px',
          right:'20px',
          boxShadow:'0 0 10px -2px rgba(0,0,0,0.3)',
          display:'flex',
          alignItems:'center',
          justifyContent:'center',
          cursor:'pointer',
          zIndex:'211231'
         }}
         onClick={()=>{
          props?.setOpen(false)
         }}
       >
           <CloseIcon sx={{
            fontSize:'30px',
            color:'#407ea4',
           }}/>
       </Box>

      <Box sx={{
        fontFamily:'Cairo-Bold',
        width:'100%',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        padding:'20px 10px',
        fontSize:'20px',
        textAlign:'center',
        '& h2':{
            textAlign:'center'
        }
        }}>{props?.title}</Box>

      <Box className="col-12 col-sm-12 col-md-12 col-lg-12 px-0">
        <ContainerOfInputFields
          mainTheme={maintheme}
          customeWidth={"100%"}
          isForm={true}
          haswidth={true}
          hasMultiLine={true}

        >
          <Box
            sx={{
              position: "relative",
              // margin: "5px 0",
              width: "100%",
              backgroundColor: "#fff",
            }}
            className="mt-3 orderdata"
          >
            <TextField
              id="standard-basic"
              variant="filled"
              label={props?.titleFiled}
              value={props?.value}
              onChange={(e) =>{
                let string=e?.target?.value?.replace(/\\|\/|\'/g,'')
                props?.setValue(string)
              }}
              error={props?.errorPhone?.error}
              helperText={props?.errorPhone?.message}
              placeholder="..."
              type={'text'}
              focused={props?.value ? true : false}
              className={`${props?.ErrorsObject?.value?.error ? 'errors' : ''}`}
              multiline
            />
            {props?.value ? (
              <CloseIcon
                className="closeIcon"
                onClick={() => {
                   props?.setValue('')
                }}
              />
            ) : null}
          </Box>

        </ContainerOfInputFields>

        <Box sx={{
        display: 'flex',
        alignItems: 'center',
         marginTop:'20px'
        }}>

        <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', width: '100%' }}>
            <Box display="flex" >

            <Button variant="outlined"
                // spacing={2}
                sx={{
                width: '50% !important',
                // flexGrow: 1, 
                minWidth: '80px !important',
                maxWidth: '80px !important',
                margin: 1,
                backgroundColor: '#d1d0d0',
                borderColor: gridtheme?.colorWhite,
                color: gridtheme?.colorblack,
                boxShadow: '0 0 7px -2px white',
                //   color:SearchButtonTheme?.clear_button_text_color,

                '&:hover': {
                    backgroundColor: '#1e6A99',
                    borderColor: gridtheme?.colorWhite,
                    color: '#fff',
                    boxShadow: '0 0 7px 1px white',
                    boxShadow: '0',
                },
                height: '35px',
                fontFamily: 'Cairo-Bold'

                }}
                className="iconeFilterClear"
                color="secondary"
                onClick={() => {
                // props.setOpen(false)
                props?.setValue('')
                }}
            >
                {t('GLOBAL.clear')}
            </Button>
            <Button
                variant="contained"
                // spacing={2} 
                sx={{
                // flexGrow: 1,
                margin: 1,
                width: '80px !important',
                minWidth: '80px !important',
                maxWidth: '80px !important',
                '&:hover': {
                    //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
                },
                height: '35px',
                fontFamily: 'Cairo-Bold'
                }}
                className="iconeFilterSearch"
                onClick={() => {
                props.submit()
                }}
            >
              
                {t('GLOBAL.Save')}
            </Button>
            </Box>
        </Box>
        </Box>

      </Box>
    </Dialog>
  )
}
