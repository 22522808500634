import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import TechnicialSupportImage from "../../assets/img/technical-support.png";
import helpers from "../../assets/js/helper";
import { Badge } from "@mui/material";
import InsertLinkIcon from "@mui/icons-material/InsertLink";

export default function CommetCard(props) {
  const theme = useTheme();

  return (
    <Card
      sx={{
        display: "flex",
        margin: "10px 0",
        padding: "10px",
        backgroundColor: "#f5f5f5",
        borderRadius: "0",
        alignItems: "flex-start",
        height: "auto !important",
        minHeight: "100px !important",
        maxHeight: "fit-content !important",
        position: "relative",
      }}
    >
      <CardMedia
        component="img"
        sx={{
          width: "80px",
          height: "80px",
          borderRadius: "50%",
          backgroundColor: "##c4c4c4",
          backgroundPosition: "center center",
        }}
        image={TechnicialSupportImage}
        alt="Live from space album cover"
      />
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <CardContent
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Typography component="div" variant="h5">
            {props?.commenter_name}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
          >
            {props.comment_dateTime
              ? helpers.formatTime(props.comment_dateTime) +
                " " +
                helpers.formatDate(props.comment_dateTime)
              : ""}
          </Typography>
        </CardContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            pl: 2,
            pb: 1,
            fontFamily: "Cairo",
            fontSize: "14px",
          }}
        >
          {props?.comment_text}
        </Box>
      </Box>
      {props?.comment_pic_path != null ? (
        <Box
         sx={{
            position: "absolute",
              top: 10,
              right: 20,
         }}
        >
          <Badge badgeContent={1} color="primary">
            <IconButton
              sx={{
                backgroundColor: "#e0e0e0",
                width:'40px',
                height:'40px'
              }}
              onClick={()=>{
                props?.setShowImage(true)
                props?.setSelectedImages([props?.comment_pic_path])
              }}
            >
              <InsertLinkIcon color="action" sx={{ fontSize: "30px" }} />
            </IconButton>
          </Badge>
        </Box>
      ) : null}
    </Card>
  );
}
