import React, { useState, useEffect } from "react";
// reactstrap components
import { styled } from "@mui/material/styles";
import { Box, Button, CircularProgress, IconButton } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import FilterCenterFocusIcon from "@mui/icons-material/FilterCenterFocus";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import Host from "../../assets/js/Host";
import { ImagePath } from "../../assets/js/Host";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import axios from "axios";
import LandingPage from "../LandingPage";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import {
  setUserName,
  setUserPhone,
  setUserData,
  setUserRoleName,
  setFullName,
  setImage,
} from "../../reduxStore/UserReducer";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import profileAPI from "../../Network/Profile/index";
import Preloading from "../../Pages/Preload/Preloading";
import userImage from "../../assets/img/user.png";
import AddIcone from "@mui/icons-material/Add";
import UserEmails from "./UserEmails";
const cookies = new Cookies();
// const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar";
const APP_CONSTANTS = {
  API_URL_IMG: `${process.env.REACT_APP_API_URL}`,
};

const CustomeHeader = styled(Box)(({ theme, MainTheme, SideMenuTheme }) => ({
  width: "95%",
  height: "200px",
  backgroundColor: "#c8c8c8",
  color: MainTheme?.disabledGlobalText
    ? MainTheme?.gloablTextColor
    : MainTheme?.primaryTextColor,
  position: "relative",
  top: "0px",
  borderRadius: "10px",
  zIndex: 0,
}));

const CustomeWrapperContent = styled(Box)(
  ({ theme, MainTheme, screenWidth }) => ({
    width: "100%",
    display: "flex",
    flexDirection: screenWidth <= 991 ? "column" : "row",
    justifyContent: "center",
    alignItems: "start",
    position: "relative",
    top: "-120px",
    zIndex: 100,
  })
);

const LeftSide = styled(Box)(({ theme, MainTheme, screenWidth }) => ({
  width: screenWidth <= 991 ? "80%" : "30%",
  height: "250px",
  borderRadius: "5px",
  backgroundColor: "#fff",
  boxShadow: `1px 0px 8px 1px ${MainTheme?.paperTextColor + "33"}`,
  margin: " 10px auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "10px 0px",
  // overflow: 'hidden',
  position: "relative",
  top: screenWidth <= 991 ? "0px" : "75px",
  "& h3": {
    position: "relative",
    top: "-50px",
    fontSize: "30px",
    color: MainTheme?.disabledGlobalText
      ? MainTheme?.gloablTextColor
      : MainTheme?.paperTextColor,
  },
  "& p": {
    position: "relative",
    top: "-50px",
    fontSize: "19px",
    color: MainTheme?.disabledGlobalText
      ? MainTheme?.gloablTextColor
      : MainTheme?.paperTextColor,
  },
  "& span": {
    fontSize: "16px",
    margin: "5px 30px",
  },
  "& .headerPanner": {
    position: "absolute",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    top: 0,
    left: 0,
    right: 0,
    height: "100px",
    // backgroundImage:"url('https://demos.creative-tim.com/now-ui-dashboard-react/static/media/bg5.056eb058.jpg')"
  },
  borderRadius: "5px",
  boxShadow: "5px 5px 40px #c5c5c5,-5px -5px 40px #fbfbfb",
}));

const RightSide = styled(Box)(({ theme, MainTheme, screenWidth }) => ({
  width: screenWidth <= 991 ? "80%" : "50%",
  height: "400px",
  borderRadius: "5px",
  backgroundColor: "#fff",
  boxShadow: `1px 0px 8px 1px ${MainTheme?.paperTextColor + "33"}`,
  zIndex: "2000 !important",
  margin: " 10px auto",
  padding: "10px 20px",
  position: "relative",
  "& p": {
    fontSize: "27px",
    color: MainTheme?.disabledGlobalText
      ? MainTheme?.gloablTextColor
      : MainTheme?.paperTextColor,
  },
  borderRadius: "5px",
  boxShadow: "5px 5px 40px #c5c5c5,-5px -5px 40px #fbfbfb",
}));

const CustomeImageProfile = styled(Box)(({ theme, MainTheme, img }) => ({
  width: "140px",
  height: "150px",
  borderRadius: "50%",
  backgroundImage: `url('${img}')`,
  backgroundColor: MainTheme?.paperColor + "aa",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
  border: "2px solid white",
  boxShadow: "0 2px 5px -4px #000",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  top: "-80px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "rgba(0,0,0,0.4)",
  },
  "& .chooiceImage": {
    position: "absolute",
    bottom: 2,
    right: "12px",
    cursor: "pointer",
    backgroundColor: "rgba(255,255,255,0.8)",
    borderRadius: "50%",
    padding: "1px",
    zIndex: "2000",
    color: "#116e90 !important",
  },
}));

export default function Profile() {
  const directions = useSelector((state) => state.settingsData.directions);
  const MainTheme = useSelector((state) => state.settingsData.MainTheme);
  const SideMenuTheme = useSelector(
    (state) => state.settingsData.SideMenuTheme
  );
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("common");
  const token = cookies.get("token");
  const [loading, setLoading] = useState(false);
  const userName = cookies.get("user_name") ? cookies.get("user_name") : "";
  const fullName = cookies.get("user_name") ? cookies.get("user_name") : "";
  const image = "";
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const globalLoading = useSelector(
    (state) => state.settingsData.globalLoading
  );
  const [userInfo, setUserInfo] = useState({
    name: userName,
    phone: "",
    image: "",
    full_name: "",
    img_url: "",
    password: "",
    email: "",
    phoneErroreMessage: "",
    phoneErrore: false,
    emailErroreMessage: "",
    emailErrore: false,
  });
  const [phone, setPhone] = useState("");
  useEffect(() => {
    console.log("image", ImagePath + image);
    setUserInfo({
      ...userInfo,
      image: ImagePath + image,
      img_url: ImagePath + image,
    });
  }, [image]);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    getUserData();
    window.addEventListener("resize", () => {
      setScreenWidth(window.innerWidth);
    });
    return () => {};
  }, []);

  const openImage = (e) => {
    let userIconeCamera = document.getElementById("userImage");
    if (userIconeCamera) userIconeCamera.click();
  };

  const uploadImages = async (image) => {
    let imgurl = window.URL.createObjectURL(image);
    setUserInfo({
      ...userInfo,
      image: image,
      img_url: imgurl,
    });
    let data = null;
    try {
      let params = {
        username: cookies.get("user_name"),
        user_id: cookies.get("user_id"),
        account_name: cookies.get("account_name"),
        account_id: cookies.get("account_id"),
        sid: cookies.get("sid"),
        baseurl: cookies.get("baseUrl"),
      };
      const formData = new FormData();
      formData.append("image", image);
      formData.append("_method", "put");

      data = await profileAPI.UpdateProfile({
        params: params,
        data: formData,
      });
      if (data && data?.status) {
        //   console.log('userinfo',data?.data)
        toast.success(t("NETWORKMESSAGE.updateCustomerMessageSuccess"));
        if (data && data?.data && data?.data?.data?.message) {
          if (data?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }
        }
      } else {
        toast.warning(t("NETWORKMESSAGE.addCustomerMessageError"));
        if (data && data?.data && data?.data?.data?.message) {
          if (data?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }
        }
      }
    } catch (e) {
      console.log(e?.message);
    }
  };

  const updateUserInfo = async () => {
    let data = null;
    if (!userInfo?.full_name) {
      toast.warning("اسم المستخمد مطلوب");
      return;
    }
    if (!phone) {
      toast.warning("رقم الهاتف مطلوب");
      return;
    }
    if (userInfo?.phoneErrore) {
      console.log("messgae", "false");

      toast.warn("رقم الهاتف بصيغة غير صحيحة !");
      return;
    }
    if ((phone?.length < 11 || phone?.length > 11) && !isNaN(phone)) {
      toast.warning("رقم الهاتف يجب ان يتكون من 11 رقم");
      return;
    } else if (isNaN(phone)) {
      toast.warning("صيغة رقم الهاتف غير صحيحة");
      return;
    }

    if (userInfo?.email && checkEmail(userInfo?.email)) {
      setUserInfo({
        ...userInfo,
        emailErrore: true,
        emailErroreMessage: "صيغة البريد الالكتروني غير صحيحة !",
      });
      return;
    }

    try {
      setLoading(true);
      let params = {
        username: cookies.get("user_name"),
        user_id: cookies.get("user_id"),
        account_name: cookies.get("account_name"),
        account_id: cookies.get("account_id"),
        sid: cookies.get("sid"),
        baseurl: cookies.get("baseUrl"),
      };
      var str = userInfo?.full_name
        ? userInfo?.full_name?.replace(/ +(?= )/g, "")
        : "";
      str = str ? str.trim() : "";
      let dataList = {
        name: str,
        phone: phone,
        email: userInfo?.email,
      };

      data = await profileAPI.UpdateProfile({
        params: params,
        data: dataList,
      });
      if (data && data?.status) {
        cookies.set("phone", phone, { path: "/" });
        setLoading(false);
        toast.success(t("NETWORKMESSAGE.updateCustomerMessageSuccess"));
        setUserInfo({
          ...userInfo,
          full_name: str,
        });
        if (data && data?.data && data?.data?.data?.message) {
          if (data?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
            console.log("errorr", 1);
          }
        }
      } else {
        setLoading(false);
        toast.warning(t("NETWORKMESSAGE.addCustomerMessageError"));
        if (data && data?.data && data?.data?.data?.message) {
          if (data?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
            console.log("errorr", 2);
          }
        }
      }
    } catch (e) {
      setLoading(false);
      console.log(e?.message);
    }
  };

  const setNewUserData = (name, value) => {
    // str.trim();
    setUserInfo({
      ...userInfo,
      [name]: value,
      emailErrore: false,
      emailErroreMessage: "",
    });
  };

  const getUserData = async () => {
    let data = null;
    try {
      let params = {
        username: cookies.get("user_name"),
        user_id: cookies.get("user_id"),
        account_name: cookies.get("account_name"),
        account_id: cookies.get("account_id"),
        sid: cookies.get("sid"),
        baseurl: cookies.get("baseUrl"),
      };

      data = await profileAPI.Profile({
        params: params,
      });
      if (data && data?.status) {
        //   console.log('userinfo',data?.data)
        if (data?.data && data?.data?.data) {
          let emailCheck = false;
          if (data?.data?.data?.email.includes("@http")) {
            emailCheck = true;
          }
          setUserInfo({
            ...userInfo,
            phone: data?.data?.data?.phone,
            full_name: data?.data?.data?.name,
            img_url:
              APP_CONSTANTS.API_URL_IMG +
              "uploads/users/" +
              data?.data?.data?.image_path,
            email: emailCheck ? "" : data?.data?.data?.email,
          });
          setPhone(data?.data?.data?.phone);
        }
        // toast.success(t('NETWORKMESSAGE.updateCustomerMessageSuccess'))
        if (data && data?.data && data?.data?.data?.message) {
          if (data?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }
        }
      } else {
        toast.warning(t("NETWORKMESSAGE.addCustomerMessageError"));
        if (data && data?.data && data?.data?.data?.message) {
          if (data?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }
        }
      }
    } catch (e) {
      console.log(e?.message);
    }
  };

  const reformatDisplayPhone = (phone) => {
    let cuirrentphone = phone;

    if (phone) {
      if (phone.slice(0, 1) == "+") {
        cuirrentphone = phone.slice(1) + phone.slice(0, 1);
        console.log("currentphone", cuirrentphone);
      } else {
        return phone;
      }
    }
    return cuirrentphone;
  };

  const handlePhoneChange = (e) => {
    let inputtxt = e.target;
    var phoneno = /^\d{11}$/;

    setPhone(inputtxt.value);
    let object = {
      ...userInfo,
      phone: inputtxt.value,
      phoneErroreMessage: "",
      phoneErrore: false,
    };
    if (isNaN(inputtxt.value)) {
      console.log("messgae", "string");
      setUserInfo({
        ...userInfo,
        phoneErroreMessage: "يجب ان يكون الهاتف رقم",
        phoneErrore: true,
      });
      return false;
    }

    if (e?.target?.value?.length == 11 && !isNaN(inputtxt.value)) {
      console.log("messgae", "true");
      object = {
        ...userInfo,
        phoneErroreMessage: "",
        phoneErrore: false,
      };
    } else if (e?.target?.value?.length >= 11) {
      console.log("messgae", "false");
      object = {
        ...userInfo,
        phoneErroreMessage: "رقم الهاتف بصيغة غير صحيحة !",
        phoneErrore: true,
      };
    } else {
      object = {
        ...userInfo,
        phoneErroreMessage: "",
        phoneErrore: false,
      };
    }

    setUserInfo({
      ...userInfo,
      ...object,
    });
  };

  const checkEmail = (value) => {
    var email = value;
    var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if (!filter.test(email)) return true;
    else return false;
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {globalLoading ? <Preloading dashboardMode={true} /> : null}

      <CustomeHeader MainTheme={MainTheme} SideMenuTheme={SideMenuTheme} />

      <CustomeWrapperContent screenWidth={screenWidth} MainTheme={MainTheme}>
        <LeftSide screenWidth={screenWidth} MainTheme={MainTheme}>
          <div className="headerPanner"></div>
          <CustomeImageProfile
            onClick={openImage}
            MainTheme={MainTheme}
            img={userInfo?.img_url ? userInfo?.img_url : userImage}
          >
            <input
              type="file"
              style={{ display: "none" }}
              id="userImage"
              onChange={(e) => {
                uploadImages(e.target.files[0]);
              }}
              accept="image/x-png,image/jpg,image/jpeg"
            />
            <PhotoCameraIcon
              onClick={openImage}
              size={30}
              className="chooiceImage"
              sx={{
                color: MainTheme?.disabledGlobalText
                  ? MainTheme?.gloablTextColor
                  : MainTheme?.paperTextColor,
              }}
            />
          </CustomeImageProfile>
          <h3 style={{ fontFamily: "Cairo-Bold" }}>{fullName}</h3>
          <p style={{ fontFamily: "Cairo" }}>
            {cookies.get("account_name") ? cookies.get("account_name") : ""}
          </p>
          {/* <span>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Totam aperiam natus quis odio obcaecati pariatur sunt deserunt ipsam quaerat voluptatibus?</span> */}
        </LeftSide>
        <RightSide screenWidth={screenWidth} MainTheme={MainTheme}>
          <p
            style={{
              display: "flex",
              justifyContent: "start",
              fontFamily: "Cairo-Medium",
            }}
          >
            {t("PROFILE.USER_TITLE")}
          </p>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              flexWrap: "wrap",
              width: "100% !important",
              "& .muiltr-a4ms82-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root,& svg,& .muirtl-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
                color: MainTheme?.disabledGlobalText
                  ? MainTheme.gloablTextColor
                  : MainTheme?.paperTextColor,
              },
              "& input": {
                color: MainTheme?.disabledGlobalText
                  ? MainTheme.gloablTextColor
                  : MainTheme?.paperTextColor,
              },
              "& .MuiOutlinedInput-root": {
                borderColor: `1px solid ${MainTheme?.paperTextColor} !important`,
                color: MainTheme?.disabledGlobalText
                  ? MainTheme.gloablTextColor
                  : MainTheme?.paperTextColor,
              },
              "& .MuiInputLabel-root": {
                // backgroundColor:`${MainTheme?.paperTextColor+'22'} !important`,
                color: MainTheme?.disabledGlobalText
                  ? MainTheme.gloablTextColor
                  : MainTheme?.paperTextColor,
              },
              "& .MuiFormControl-root": {
                // border:`1px solid ${MainTheme?.paperTextColor} !important`,
                color: MainTheme?.disabledGlobalText
                  ? MainTheme.gloablTextColor
                  : MainTheme?.paperTextColor,
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: MainTheme?.disabledGlobalText
                    ? MainTheme.gloablTextColor
                    : MainTheme?.paperTextColor,
                },
                "&:hover fieldset": {
                  borderColor: MainTheme?.disabledGlobalText
                    ? MainTheme.gloablTextColor
                    : MainTheme?.paperTextColor,
                },
                "&.Mui-focused fieldset": {
                  borderColor: MainTheme?.secondaryColor,
                  color: MainTheme?.disabledGlobalText
                    ? MainTheme.gloablTextColor
                    : MainTheme?.secondaryColor,
                },
              },
              "& .MuiOutlinedInput": {
                // borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
              },
              "& .MuiOutlinedInput-notchedOutline": {
                // border: "0 none",
              },
              "& .muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root:before,& .muiltr-1ptx2yq-MuiInputBase-root-MuiInput-root:before": {
                borderColor: MainTheme?.disabledGlobalText
                  ? MainTheme.gloablTextColor
                  : MainTheme?.paperTextColor,
              },
              "& .muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root .Mui-focused": {
                borderColor: MainTheme?.secondaryColor,
                color: MainTheme?.disabledGlobalText
                  ? MainTheme.gloablTextColor
                  : MainTheme?.secondaryColor,
              },
              "& p,& span,& label,& input,& button": {
                fontFamily: "Cairo",
              },
            }}
            fullWidth
          >
            <Box
              sx={{
                width: "50%",
              }}
              fullWidth
              autoComplete="off"
            >
              <TextField
                sx={{ width: "98%" }}
                onChange={(e) => {
                  setNewUserData("name", e.target.value);
                }}
                focused
                inputProps={{
                  readOnly: true,
                }}
                value={userInfo?.name}
                id="outlined-basic"
                label={t("PROFILE.USERNAME")}
                variant="standard"
              />
            </Box>
            <Box
              sx={{
                width: "50%",
              }}
              fullWidth
              autoComplete="off"
            >
              <TextField
                sx={{ width: "98%" }}
                focused
                value={userInfo?.full_name}
                onChange={(e) => {
                  setNewUserData("full_name", e.target.value);
                }}
                id="outlined-basic"
                label={t("PROFILE.FULL_NAME")}
                variant="standard"
              />
            </Box>

            <Box
              sx={{
                m: 0.1,
                width: "100%",
                "& .MuiFormHelperText-root": {
                  fontSize: "15px !important",
                },
              }}
              fullWidth
              autoComplete="off"
            >
              <TextField
                sx={{
                  width: "99%",
                  mt: 1,
                  "& input": {
                    textAlign: "left !important",
                  },
                }}
                focused
                onChange={handlePhoneChange}
                value={phone}
                defaultValue={phone}
                id="outlined-basic"
                inputProps={{
                  dir: "ltr",
                  textAlign: "right",
                }}
                //  type="tel"
                error={userInfo?.phoneErrore}
                helperText={userInfo?.phoneErroreMessage}
                label={t("PROFILE.PHONE")}
                variant="standard"
                placeholder={"07812346794"}
              />
            </Box>
            <Box
              sx={{
                m: 0.1,
                width: "100%",
                "& .MuiFormHelperText-root": {
                  fontSize: "15px !important",
                },
              }}
              fullWidth
              autoComplete="off"
            >
              <TextField
                sx={{
                  width: "99%",
                  mt: 1,
                  "& input": {
                    textAlign: "left !important",
                  },
                }}
                focused
                onChange={(e) => {
                  setNewUserData("email", e.target.value);
                  checkEmail(e);
                }}
                error={userInfo?.emailErrore}
                helperText={userInfo?.emailErroreMessage}
                type="email"
                value={userInfo?.email}
                id="outlined-basic"
                inputProps={{
                  dir: "ltr",
                  textAlign: "right",
                }}
                label={t("PROFILE.email")}
                variant="standard"
                placeholder={"test@gmail.com"}
              />
            </Box>

            <Box
              //
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                mr: 0.6,
              }}
              fullWidth
              //
              // autoComplete="off"
            >
              <Button
                onClick={updateUserInfo}
                sx={{
                  mt: 2,
                  backgroundColor: MainTheme?.secondaryColor,
                  color: MainTheme?.disabledGlobalText
                    ? MainTheme.gloablTextColor
                    : MainTheme?.oppositeSecondaryColor,
                }}
                variant="contained"
              >
                {t("PROFILE.UPDATE_BUTTON")}
              </Button>
            </Box>
          </Box>
        </RightSide>
      </CustomeWrapperContent>
      <Box
        style={{
          width: "100%",
          position: "relative",
          top: "-50px",
          padding: "0 30px",
        }}
      >
        <UserEmails />
      </Box>

      {loading ? (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            zIndex: 324234,
            backgroundColor: "rgba(0,0,0,0.3)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress
            size={40}
            style={{
              color: "#fff",
            }}
          />
        </div>
      ) : null}
    </Box>
  );
}
