import React ,{useState,useEffect} from 'react'
import ReactApexChart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom';

export default function PieChart(props) {
    const  [t]=useTranslation('common');
    const [state,setState]=useState({
         series: [0,0,0,0],
            options: {
              chart: {
                width: 420,
                type: 'pie',
                events: {
                  dataPointSelection: function(event, chartContext, config) {
                      handleBarClicked(event, chartContext, config)
                  }
              },
              },
              labels: [t('DASHBOARD.request_completed'), t('DASHBOARD.request_wait'),t('DASHBOARD.request_canceled'),t('DASHBOARD.request_new')],
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  legend: {
                    position: 'bottom'
                  }
                }
              }],
              legend: {
                show: true,
                fontSize: '15px',
                fontFamily: 'Cairo-Medium',
                margin:'10px'
              },
              colors: ["#64df3e",'#f1e846','#f95151','#43a3f7'],
              tooltip: {
                enabled: true,
                enabledOnSeries: undefined,
                shared: true,
                followCursor: false,
                intersect: false,
                inverseOrder: false,
                custom: undefined,
                fillSeriesColor: true,
                theme: false,
                style: {
                  fontSize: '12px',
                  fontFamily: 'Cairo-Medium'
                },
            }

            },
    })
    const navigate =useNavigate()

    const handleBarClicked=(event, chartContext, config)=>{
      // console.log('sadasdasdasdsadasdas',event)
      // console.log('sadasdasdasdsadasdas',chartContext)
      console.log('sadasdasdasdsadasdas',config)

      if(config?.dataPointIndex>-1){
          if(config?.dataPointIndex==0){
            navigate('/system/requests', {
              state: {
                data: {
                  status_id:3,
                }
              }
            });
            localStorage.removeItem('isUseNavigation');
          }else if(config?.dataPointIndex==1){
              navigate('/system/requests', {
                state: {
                  data: {
                    status_id:2,
                  }
                }
              });
              localStorage.removeItem('isUseNavigation');
          }else if(config?.dataPointIndex==2){
            navigate('/system/requests', {
              state: {
                data: {
                  status_id:4,
                }
              }
            });
            localStorage.removeItem('isUseNavigation');
        }else if(config?.dataPointIndex==3){
          navigate('/system/requests', {
            state: {
              data: {
                status_id:1,
              }
            }
          });
          localStorage.removeItem('isUseNavigation');
      }
         
     }
   }

    const getPercentageOfPieChart=(data,totla)=>{
      return data
      console.log('dashboardresult',Math.round((data/totla)*100));
      return Math.round((data/totla)*100);
    }

    useEffect(()=>{
      console.log('dashboarddat6a',props?.dashboardObject);
      if(props?.dashboardObject){
      console.log('dashboarddat6a',props?.dashboardObject);

        setState({
          ...state,
          series: [
            getPercentageOfPieChart(((props?.dashboardObject?.closed_successful?props?.dashboardObject?.closed_successful:0)+(props?.dashboardObject?.approved?props?.dashboardObject?.approved:0)),props?.dashboardObject?.all_statuses),
            getPercentageOfPieChart((props?.dashboardObject?.in_progress?props?.dashboardObject?.in_progress:0),props?.dashboardObject?.all_statuses),
            getPercentageOfPieChart(
              (props?.dashboardObject?.closed_cancelled?props?.dashboardObject?.closed_cancelled:0)+
              (props?.dashboardObject?.closed_failed?props?.dashboardObject?.closed_failed:0)+
              (props?.dashboardObject?.rejected?props?.dashboardObject?.rejected:0)
              ,props?.dashboardObject?.all_statuses),
            getPercentageOfPieChart(props?.dashboardObject?.new,props?.dashboardObject?.all_statuses),
          ],
        })
      }
    },[props?.dashboardObject])
    

  return (
    <div id="chart">
          <ReactApexChart 
            options={state.options} 
            series={state.series} 
            type="pie" 
            width={430} 
          />
    </div>
  )
}
