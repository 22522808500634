import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import {DrawerHeader} from '../SideMenu/SideMenuTheme'
import { useSelector } from 'react-redux';
import * as ImIcon from 'react-icons/im'
import Cookies from 'universal-cookie'
const cookies =new Cookies();

const tempImgURL="https://images.unsplash.com/photo-1539571696357-5a69c17a67c6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"

export default function HeaderDrawer({open,setOpen,handleDrawerOpen,handleDrawerClose,getUserImage}) {
    const drawerwidth = useSelector(state=>state.settingsData.drawerwidth)
    const sidemenutheme=useSelector(state=>state.themeData.sidemenutheme)
    const maintheme=useSelector(state=>state.themeData.maintheme)
    const styleToggleIconeMenu={
      position:'absolute',
      top:'10px',
      left:'25px',
      fontWeight:'bold',
      cursor:'pointer'
    }
  return (
    <Box>
    {/* <IconButton onClick={handleDrawerClose}>
      {theme.direction === 'rtl' ? open?<ChevronRightIcon />:<MenuIcon/> : <ChevronLeftIcon />}
    </IconButton> */}
    <Box
      sx={{
        display: 'flex',
        flexDirection:'column',
        justifyContent: open?'space-between': 'end',
        alignItems: 'center',
        alignContent: 'center',
        marginBottom:'10px',
        width: '100%',
        height:open?'175px':'100px',
        position:'relative',
      }}
    >
    {open?<ImIcon.ImArrowRight2 onClick={handleDrawerClose}  style={styleToggleIconeMenu} size={24} color={sidemenutheme?.colorblack}/>:<ImIcon.ImArrowLeft2 onClick={handleDrawerOpen} style={styleToggleIconeMenu} size={24} color={sidemenutheme?.colorblack}/>}
        <Box
          sx={{
            display: 'flex',
            flexDirection:'column',
            justifyContent: open?'start':'center',
            alignItems: open?'center':'flex-end',
            alignContent: open?'center':'flex-end',
          }}
        >
          <img src={getUserImage()} alt="admin-picture"  style={{
            width: open?"100px":"43px", 
            height: open?"100px":"43px",  
            background: "transparent  u0% 0% no-repeat padding-box", 
            borderRadius: open?'50%':"28px", 
            opacity: "1",
            objectFit:'cover',
            backgroundPosition:'center center',
            transition:'all 0.4s ease',
         

          }} />
          {
            open?
          <Typography  variant='span'
            sx={{
              textAlign: 'left',
              font: 'normal normal  19px/22px Cairo-Bold',
              letterSpacing:' -1px',
              color: sidemenutheme?.lightblack,
              opacity: 1,
              marginTop:'18px',
            transition:'all 0.6s ease'

            }}
          >{cookies.get('account_name')}</Typography>:null
          }
             {
         open?
         <Typography variant='span' 
         sx={{
          textِlign: "left",
          font:' normal normal  15px/20px Cairo-Light',
          letterSpacing: '0px',
          color:  sidemenutheme?.lightblack,
          opacity: 1,
          marginTop:'10px',
          transition:'all 0.8s ease'

         }}
       >
          {cookies.get('user_name')}
       </Typography>:null
        }
        </Box>
     
    </Box>
  </Box>
  )
}
