import callAPI from '../ApiConfig'
import store from '../../app/store'

const requests = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"wialon_customers/tasks",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            'Content-Type': 'application/json',
            Accept:'application/json',
        }
    
    })
    
}
const addRequest = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"wialon_customers/tasks",
        method:"post",
        signal:props?.signal,
        data:props?.data,
        params:props?.params,
        headers:{
            'Content-Type': 'multipart/form-data',
        }
    })
    
}
const editRequest = async (props) => {
    const storeState = store.getState();

    return await callAPI({
        route:"wialon_customers/tasks/"+props?.id,
        method:"put",
        signal:props?.signal,
        data:props?.data,
        params:props?.params,
        headers:{
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
            ...props?.headers
        }
    })
}
const deleteRequest = async (props) => {
    const storeState = store.getState();

    
    return await callAPI({
        route:"wialon_customers/tasks/"+props?.data?.id,
        method:"delete",
        signal:props?.signal,
        headers:{
            // "Authorization": `Bearer ${storeState?.userData?.token}`,
            "jwt":storeState?.userData?.token
        }
    })
}

const getRequestList = async (props) => {
    const storeState = store.getState();

    return await callAPI({
        route:"wialon_customers/tasks/list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            // "Authorization": `Bearer ${storeState?.userData?.token}`,
            "jwt":storeState?.userData?.token
        }
    
    })
    
}
const addRating = async (props) => {
    const storeState = store.getState();

    return await callAPI({
        route:"wialon_customers/tasks/rating",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        headers:{
            // "Authorization": `Bearer ${storeState?.userData?.token}`,
            "jwt":storeState?.userData?.token
        },
        data:props?.data,
    })
    
}
const updateRating = async (props) => {

    return await callAPI({
        route:"wialon_customers/rate_task/"+props?.id,
        method:"put",
        signal:props?.signal,
        params:props?.params,
        headers:{
            Accept: 'application/json',
            ...props?.headers
        },
        data:props?.data,
    })
    
}
const showRequestTask = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"wialon_customers/tasks/"+props?.id,
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            'Content-Type': 'application/json',
            Accept:'application/json',
        }
    
    })
    
}

const deleteRequestFile = async (props) => {
    const storeState = store.getState();

    
    return await callAPI({
        route:"wialon_customers/task_file/"+props?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            'Content-Type': 'application/json',
            Accept:'application/json',
        }
    })
}

const addRequestFile = async (props) => {

    return await callAPI({
        route:"wialon_customers/task_files/"+props?.id,
        method:"post",
        signal:props?.signal,
        data:props?.data,
        params:props?.params,
        headers:{
            'Content-Type': 'multipart/form-data',
        }
    })
    
}

const comments = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"wialon_customers/comments/"+props?.data?.task_id,
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            'Content-Type': 'application/json',
            Accept:'application/json',
        }
    
    })
    
}

const requestAPI={
    Requests:requests,
    AddRequest:addRequest,
    EditRequest:editRequest,
    DeleteRequest:deleteRequest,
    GetRequestList:getRequestList,
    AddRating:addRating,
    UpdateRating:updateRating,
    ShowRequestTask:showRequestTask,
    DeleteRequestFile:deleteRequestFile,
    AddRequestFile:addRequestFile,
    comments:comments
}
export default requestAPI