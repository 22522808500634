import {
  Box,
  TextField,
  Button,
  Autocomplete,
  FormControlLabel,
  Divider,
} from "@mui/material";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import PopupForm from "../../Components/PopupForm";
import { useTranslation } from "react-i18next";
import {
  ContainerOfInput,
  ContainerOfInputFields,
  ContainerOfSelectField,
} from "../../Components/ThemDesign";
import { useDispatch, useSelector } from "react-redux";
import ObjectErrors, {
  checkErrors,
  resetErrors,
  restCustomeObject,
} from "../../Errors/Requests/Request";
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid";

// import EmployeeAPI from '../../../../Global/Network/Employee/index'
import requestAPI from "../../Network/Request/index";
import { toast } from "react-toastify";
import * as RiIcons from "react-icons/ri";
import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Grid from "../Grid/Grid";
import helpers from "../../assets/js/helper";
import $ from "jquery";
import Cookies from "universal-cookie";
import SearchInput from "../../Components/SearchInput";
import Switch from "@mui/material/Switch";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import DeleteIcon from "@mui/icons-material/Delete";
import requestTypeAPI from "../../Network/RequestType";
import { useNavigate } from "react-router-dom";
// import debounce from 'react-debouncing';
import AddReason from "./AddReason";
import { setGlobalLoading } from "../../reduxStore/SettingsReducer";
import moment from "moment";
import profileAPI from "../../Network/Profile";
import ViewComment from "./ViewComment";
const label = { inputProps: { "aria-label": "Switch demo" } };

const cookies = new Cookies();
const APP_CONSTANTS = {
  API_URL_IMG: `${process.env.REACT_APP_API_URL_image}`,
};

const FilterSearchContent = (props) => {
  const [t] = useTranslation("common");

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100% !important",
      }}
    >
      <SearchInput
        value={props?.unitNameSearch}
        setValue={props?.setUnitNameSearch}
        title={t("REQUESTS.unitName")}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          width: "100%",
          marginLeft: "20px !important",
          marginTop: "10px !important",
          "& .MuiTypography-root": {
            fontFamily: "Cairo-Medium",
          },
        }}
      >
        <FormControlLabel
          control={
            <Switch
              checked={props.checkAllselected}
              onChange={(e) => {
                props.setCheckAllselected(e.target.checked);
                if (e.target.checked) props.setCheckAllNotSelected(false);
              }}
            />
          }
          label={"المحددة"}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          width: "100%",
          marginLeft: "20px !important",
          marginTop: "10px !important",
          "& .MuiTypography-root": {
            fontFamily: "Cairo-Medium",
          },
        }}
      >
        <FormControlLabel
          control={
            <Switch
              checked={props.checkAllNotSelected}
              onChange={(e) => {
                props.setCheckAllNotSelected(e.target.checked);
                if (e.target.checked) props.setCheckAllselected(false);
              }}
            />
          }
          label={"غير محددة"}
        />
      </Box>
    </Box>
  );
};
const RenderContent = (props) => {
  const [t] = useTranslation("common");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const maintheme = useSelector((state) => state.themeData.maintheme);
  const [ErrorsObject, setErrorsObject] = useState(ObjectErrors);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [reasonText, setReasonText] = useState("");
  const [selectedUnitId, setSelectedUnitId] = useState(null);
  const [openUnitAddReason, setOpenUnitAddReason] = useState(false);
  function isValidDate(dateString) {
    // Create a new Date object from the dateString
    let date = new Date(dateString);

    // Check if the date object is valid
    if (isNaN(date.getTime())) {
      // Date is invalid
      return false;
    } else {
      // Date is valid
      return true;
    }
  }

  function isValidTime(timeString) {
    // Split time string into hours, minutes, and am/pm
    const [time, amPm] = timeString.split(" ")?.map((itm) => itm);
    const [hours, minutes] = time.split(":")?.map((itm) => itm);
    const ampmLowerCase = amPm ? amPm.toLowerCase() : "";
    console.log("timeCon", amPm);
    console.log("timeCon", hours);
    console.log("timeCon", minutes);

    if (hours == "NaN" || minutes == "NaN") {
      return false;
    }
    // Check if hours and minutes are within valid ranges
    if (hours < 1 || hours > 12 || minutes < 0 || minutes > 59) {
      return false;
    }

    // Check if am/pm is valid
    if (ampmLowerCase !== "am" && ampmLowerCase !== "pm") {
      return false;
    }

    // If all checks pass, the time is valid
    return true;
  }

  function formatDateTime(date) {
    // Format the date object as 'hh:mm:ss AM/PM'
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    let ampm = hours >= 12 ? "PM" : "AM";

    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")} ${ampm}`;
  }

  const handleChange = (e, number, errName) => {
    if (number == 1) {
      props.setPhone(e.target.value);
      handlePhoneChange(e);
      props?.setObjError({
        ...props?.ObjError,
        phone_error: false,
        phone_message: "",
      });
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 2) {
      props.setRequestLocation(e.target.value);
      props?.setObjError({
        ...props?.ObjError,
        location_error: false,
        location_message: "",
      });
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 3) {
      props.setRequestType(e);
      if (e?.mandatory_units != 1) props?.setListOfSelectedRows([]);
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
      props?.setObjError({
        ...props?.ObjError,
        type_error: false,
        type_message: "",
      });
    } else if (number == 4) {
      props.setNotes(e.target.value);
      props?.setObjError({
        ...props?.ObjError,
        note_error: false,
        note_message: "",
      });
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 5) {
      console.log("date", new Date(e));
      if (e) props.setPreferedDate(new Date(e));
      else props.setPreferedDate(null);

      let check = isValidDate(helpers.formatDate(new Date(e)));
      if (check) {
        props?.setObjError({
          ...props?.ObjError,
          prefereDate_error: false,
          prefereDate_message: "",
        });
      } else {
        props?.setObjError({
          ...props?.ObjError,
          prefereDate_error: true,
          prefereDate_message: "صيغة التاريخ غير صحيحة",
        });
      }
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 6) {
      console.log("time", formatDateTime(new Date(e)));
      let date__ = helpers.formatDate(e);
      if (date__ && !date__.includes("Nan")) props.setPreferedTime(e);
      let check = false;
      if (e) check = isValidTime(formatDateTime(new Date(e)));
      console.log("time", check);

      if (check) {
        props?.setObjError({
          ...props?.ObjError,
          prefereTime_error: false,
          prefereTime_message: "",
        });
      } else {
        props?.setObjError({
          ...props?.ObjError,
          prefereTime_error: true,
          prefereTime_message: "صيغة الوقت غير صحيحة",
        });
      }

      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    }
  };
  const clearInput = (number, errName) => {
    if (number == 1) {
      props.setPhone("");
      props?.setObjError({
        ...props?.ObjError,
        phone_error: false,
        phone_message: "",
      });
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 2) {
      props.setRequestLocation("");
      props?.setObjError({
        ...props?.ObjError,
        location_error: false,
        location_message: "",
      });
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 3) {
      props.setRequestType(null);
      props?.setObjError({
        ...props?.ObjError,
        type_error: false,
        type_message: "",
      });
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 4) {
      props.setNotes("");
      props?.setObjError({
        ...props?.ObjError,
        note_error: false,
        note_message: "",
      });
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 5) {
      props.setPreferedDate(null);
      props?.setObjError({
        ...props?.ObjError,
        prefereDate_error: false,
        prefereDate_message: "",
      });
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 6) {
      props.setPreferedTime(null);
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    }
  };
  const columns = useMemo(
    () => [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        width: 40,
      },
      { headerName: t("REQUESTS.add_unit_id"), field: "index", flex: 0.5 },
      { headerName: t("REQUESTS.add_unit_name"), field: "nm", flex: 2 },
      {
        headerName: t("REQUESTS.add_unit_notes"),
        field: "nmxc",
        width: 150,
        renderCell: (rows) =>
          checkIdFound(rows?.row?.id) ? (
            <Box
              sx={{
                width: "100%",
                height: "fit-content",
                // direction:'rtl',
                "& .MuiInputBase-root": {
                  // direction:'ltr !important',
                },
                display: "flex",
                alignItems: "center",
                flexDirection: "row-reverse",
              }}
            >
              <Box
                onClick={() => {
                  setOpenUnitAddReason(true);
                  setSelectedUnitId(rows?.row?.id);
                  setReasonText(rows?.row?.reason);
                }}
                sx={{
                  backgroundColor: "#1e6a99",
                  padding: "5px 10px",
                  margin: "0 10px",
                  boxShadow: "0 0 10px -2px rgba(0,0,0,0.3)",
                  color: "#fff",
                  cursor: "pointer",
                }}
              >
                {t("REQUESTS.notesReason")}
              </Box>
            </Box>
          ) : null,
      },

      {
        headerName: t("REQUESTS.add_unit_reasones"),
        field: "reason",
        flex: 2,
        editable: false,
        renderHeader: () => <Box></Box>,
        renderCell: (rows) => (
          <Box
            sx={{
              width: "100%",
              height: "fit-content",
              // direction:'rtl',
              "& .MuiInputBase-root": {
                // direction:'ltr !important',
              },
              display: "flex",
              alignItems: "center",
              flexDirection: "row-reverse",
              position: "relative",
            }}
          >
            <Box
              sx={{
                textAlign: "justify",
                wordSpacing: "break-word",
                wordBreak: "break-word",
                height: "fit-content",
                width: "75%",
              }}
            >
              {rows?.row?.reason ? (
                <span style={{ fontSize: "15px", fontFamily: "Cairo" }}>
                  {rows?.row?.reason}
                </span>
              ) : (
                <span
                  style={{
                    color: "gray",
                    fontSize: "12px",
                    fontFamily: "Cairo-Light",
                  }}
                >
                  يرجى كتابة السبب
                </span>
              )}
            </Box>
            {/* <TextField 
                id="outlined-basic" 
                // label="Outlined" 
                placeholder='يرجى كتابة السبب'
                variant="filled"
                value={rows?.row?.reason}
                sx={{
                  width:'100%',
                  direction:'rtl'
                }}
                onChange={(e)=>{
                  handleChangeCellReason(rows?.row,e?.target?.value)
                }}
              /> */}
          </Box>
        ),
      },
    ],
    [props?.listOfSelectedRows]
  );

  const checkIdFound = (id) => {
    let check = false;
    if (props?.listOfSelectedRows?.length > 0)
      check = props?.listOfSelectedRows?.find((itm) => itm == id);
    return check;
  };

  // const handleChangeCellReason =debounce((obj,value,rows)=>{
  //   console.log('sadasdasda', obj)
  //   let arr = []
  //   props?.units && props?.units?.length && props?.units?.map((itm) => {
  //     if (itm?.id == obj?.id) {
  //       arr.push({
  //         ...itm,
  //         reason: value,
  //       })
  //     } else {
  //       arr.push(itm)
  //     }
  //   })

  //   console.log('datadadjandkasjdnss', props?.allUnits);
  //   if (arr&&arr?.length==props?.units?.length)
  //     props.setUnits(arr)
  // },1000)
  const [selectedImage, setSelectedImage] = useState("");
  const [pageSize, setPageSize] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const handlePageChange = (newPage) => {
    setPageNumber(newPage + 1);
  };
  const [isUpdateImage, setIsUpdatedImage] = useState(false);
  const [rowsTotal, setRowsTotal] = useState(0);
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);
  const [nameForUpdatedImageValue, setNameForUpdatedImageValue] = useState("");
  const [nameForUpdatedImage, setNameForUpdatedImage] = useState(false);
  const [fileForUpdatedImage, setFileForUpdatedImage] = useState(null);
  const [
    fileForUpdatedImageExtention,
    setFileForUpdatedImageExtention,
  ] = useState("");
  const [isUploadingEditMode, setIsUploadingEditMode] = useState(false);

  // const [listOfSelectedRows,setListOfSelectedRows]=useState([])
  const handleCheckBoxChange = useCallback((rows) => {
    console.log("datadadjandkasjdn", rows);
    props.setListOfSelectedRows(rows);
    let arr = [];
    props?.units &&
      props?.units?.length &&
      props?.units?.map((itm) => {
        if (rows?.find((e) => e == itm?.id)) {
          arr.push(itm);
        }
      });
    console.log("datadadjandkasjdn", arr);
    props.setUnit(arr);
  }, []);
  const handleRowCellChange = (row) => {
    console.log("sadasdasda", row);
    let key = null;
    for (var i in row) {
      key = i;
    }
    let arr = [];
    props?.units &&
      props?.units?.length &&
      props?.units?.map((itm) => {
        if (itm?.id == key) {
          arr.push({
            ...itm,
            reason: row[key]?.reason?.value,
          });
        } else {
          arr.push(itm);
        }
      });

    console.log("datadadjandkasjdn", arr);
    if (arr) props.setUnits(arr);
  };
  const handleSizeOfFile = async (e) => {
    if (e.size > 2097152) {
      toast.warn("حجم الملف تجاوز الحد المسموح");
      return false;
    }
    return true;
  };
  const getNameOfImage = (file) => {
    const name = file?.split("\\");
    if (name && name?.length > 0) {
      return name[name?.length - 1];
    }
    return "";
  };
  const handleImageChange = async (e) => {
    // console.log('file', e.files[0].size)
    if (e && e?.target && e.target.files[0]) {
      if (await handleSizeOfFile(e.target.files[0])) {
        try {
          if (e?.target?.files) {
            // alert('asdasd')
            if (!isUpdateImage) {
              if (props?.editMode) {
                let formData = new FormData();
                formData.append(`files[0][image]`, e?.target?.files[0]);
                formData.append(`files[0][name]`, e.target.value);

                let params = {
                  username: cookies.get("user_name"),
                  user_id: cookies.get("user_id"),
                  account_name: cookies.get("account_name"),
                  account_id: cookies.get("account_id"),
                  sid: cookies.get("sid"),
                  baseurl: cookies.get("baseUrl"),
                };

                let result = await requestAPI.AddRequestFile({
                  data: formData,
                  params: params,
                  id: props?.task_id,
                  headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
                });

                if (result?.status && result?.data?.status) {
                  let checkImage = getExtension(e.target.value);

                  props?.setImageList((itms) => [
                    ...itms,
                    {
                      url: window.URL.createObjectURL(e.target.files[0]),
                      file: e.target.files[0],
                      index: itms[itms?.length - 1]?.index
                        ? itms[itms?.length - 1]?.index + 1
                        : 1,
                      type: checkImage ? "image" : "file",
                      name: getNameOfImage(e.target.value),
                    },
                  ]);
                } else {
                  if (result && result?.data && result?.data?.data?.message) {
                    if (
                      result?.data?.data?.message?.text ==
                      "unauthorized to complete"
                    ) {
                      navigate("/redirect");
                    }
                  }
                }
              } else {
                dispatch(setGlobalLoading(true));
                let checkImage = getExtension(e.target.value);
                props?.setImageList((itms) => [
                  ...itms,
                  {
                    url: window.URL.createObjectURL(e.target.files[0]),
                    file: e.target.files[0],
                    index: itms[itms?.length - 1]?.index
                      ? itms[itms?.length - 1]?.index + 1
                      : 1,
                    id: "",
                    type: checkImage ? "image" : "file",
                    name: getNameOfImage(e.target.value),
                  },
                ]);
                setTimeout(() => {
                  dispatch(setGlobalLoading(false));
                }, 1000);
              }
            } else {
              dispatch(setGlobalLoading(true));

              let arr = [];
              let checkImage = getExtension(e.target.value);
              props?.imageList &&
                props?.imageList?.length &&
                props?.imageList?.map((itm) => {
                  if (isUpdateImage?.index == itm?.index) {
                    arr.push({
                      ...itm,
                      url: window.URL.createObjectURL(e?.target?.files[0]),
                      file: e.target.files[0],
                      type: checkImage ? "image" : "file",
                      name: getNameOfImage(e.target.value),
                    });
                  } else {
                    arr.push(itm);
                  }
                });
              props.setImageList(arr);
              setIsUpdatedImage(null);
              setTimeout(() => {
                dispatch(setGlobalLoading(false));
              }, 1000);
            }
          }
          // document.getElementById('imagesForTask').value='';
        } catch (e) {
          dispatch(setGlobalLoading(false));
          console.log(e?.message);
        }
      }
    }
  };
  const addImageToList = (e) => {
    if (props?.imageList?.length > 5 && !isUpdateImage) {
      // becase we started from index 0..4=5 items
      toast.warn("لا يمكن رفع اكثر من 6 صور");
      return;
    }

    const image = e.target?.querySelector("input");
    if (image) {
      image.click();
      image = null;
    }
  };
  const setSelectedImageToShow = (itm) => {
    setSelectedImage(itm?.url);
  };
  const handleImageChangeInUpdateMode = (e) => {
    if (e?.target?.files[0]) {
      setFileForUpdatedImage(e?.target?.files[0]);
      setFileForUpdatedImageExtention(e?.target?.value);
      setNameForUpdatedImage(true);
    }
  };
  const addUpdatedImage = async () => {
    if (!nameForUpdatedImageValue) {
      toast.warn("يجب ادخال اسم للصورة");
      return false;
    }

    try {
      setIsUploadingEditMode(true);
      let formData = new FormData();

      let string = nameForUpdatedImageValue?.replace(/\\|\/|\'/g, "");

      formData.append(`files[0][image]`, fileForUpdatedImage);
      formData.append(`files[0][name]`, string);

      let params = {
        username: cookies.get("user_name"),
        user_id: cookies.get("user_id"),
        account_name: cookies.get("account_name"),
        account_id: cookies.get("account_id"),
        sid: cookies.get("sid"),
        baseurl: cookies.get("baseUrl"),
      };

      let result = await requestAPI.AddRequestFile({
        data: formData,
        params: params,
        id: props?.task_id,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      if (result?.status && result?.data?.status) {
        console.log("sadasassdada", result?.data);
        setIsUploadingEditMode(false);

        let checkImage = getExtension(fileForUpdatedImageExtention);
        setFileForUpdatedImage(null);
        setNameForUpdatedImage(false);
        setNameForUpdatedImageValue("");
        setFileForUpdatedImageExtention("");
        props?.setImageList((itms) => [
          ...itms,
          {
            url: window.URL.createObjectURL(fileForUpdatedImage),
            file: fileForUpdatedImage,
            index: itms[itms?.length - 1]?.index
              ? itms[itms?.length - 1]?.index + 1
              : 1,
            type: checkImage ? "image" : "file",
            name: nameForUpdatedImageValue,
            // id:
          },
        ]);
      } else {
        setIsUploadingEditMode(false);

        toast.warn("خطء غير معروف");
        if (result && result?.data && result?.data?.data?.message) {
          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }
        }
      }
    } catch (err) {
      console.log(err?.message);
    }
  };
  const handleUpdateImage = (e) => {
    // setSelectedImage(null)
    setIsUpdatedImage(e);
    let data = document.getElementById("imagesForTask");
    if (data) {
      data.click();
    }
  };
  const handleDeleteImage = async (e) => {
    if (props?.editMode) {
      console.log(e);
      let params = {
        username: cookies.get("user_name"),
        user_id: cookies.get("user_id"),
        account_name: cookies.get("account_name"),
        account_id: cookies.get("account_id"),
        sid: cookies.get("sid"),
        baseurl: cookies.get("baseUrl"),
      };

      let result = await requestAPI.DeleteRequestFile({
        params: params,
        id: e?.id,
      });

      if (result?.status && result?.data?.status) {
        setSelectedImage(null);
        props.setImageList(
          props?.imageList.filter((itm) => itm?.index !== e.index)
        );
      } else {
        if (result && result?.data && result?.data?.data?.message) {
          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }
        }
      }
    } else {
      setSelectedImage(null);
      props.setImageList(
        props?.imageList.filter((itm) => itm?.index !== e.index)
      );
    }
  };
  const [errorPhone, setPhoneError] = useState({
    error: false,
    message: "",
  });
  const handlePhoneChange = (e) => {
    let inputtxt = e.target;
    var phoneno = /^\d{11}$/;
    let object = {
      error: false,
      message: "",
    };
    setPhoneError({
      error: false,
      message: "",
    });
    if (isNaN(inputtxt.value)) {
      console.log("messgae", "string");
      setPhoneError({
        ...errorPhone,
        message: "يجب ان يكون الهاتف رقم",
        error: true,
      });

      return false;
    }

    if (e?.target?.value?.length == 11 && !isNaN(inputtxt.value)) {
      console.log("messgae", "true");
      object = {
        message: "",
        error: false,
      };
    } else if (e?.target?.value?.length >= 11) {
      console.log("messgae", "false");
      object = {
        message: "رقم الهاتف بصيغة غير صحيحة !",
        error: true,
      };
    } else {
      object = {
        message: "",
        error: false,
      };
    }

    setPhoneError({
      ...errorPhone,
      ...object,
    });
  };
  const getTranslateStatus = (name) => {
    if (name == "new") return "جديد";
    else if (name == "in progress") return "قيد المعالجة";
    else if (name == "closed") return "مغلقة";
    else if (name == "rejected") return "ألغيت";
    else if (name == "approved") return "مكتملة";
    else if (name == "archived") return "مؤرشف";
    else if (name == "successful") return "مكتملة";
    else if (name == "canceled") return "ألغيت";
    else if (name == "failed") return "ألغيت";
    else return name;
  };
  const getExtension = (file) => {
    const extension = file.split(".").pop();

    if (
      extension == "jpeg" ||
      extension == "jpg" ||
      extension == "png" ||
      extension == "gif" ||
      extension == "svg"
    ) {
      return true;
    }
  };
  const checkisImageOrExcel = (obj) => {
    let arr = obj?.split(".");
    console.log("kasdasdasd", arr);
    if (arr && arr?.length > 0) {
      if (
        arr[arr?.length - 1] == "jpeg" ||
        arr[arr?.length - 1] == "jpg" ||
        arr[arr?.length - 1] == "png" ||
        arr[arr?.length - 1] == "gif" ||
        arr[arr?.length - 1] == "svg"
      ) {
        return true;
      }
    }
    return false;
  };

  const handleUnitReasonChange = () => {
    console.log("enter");
    let arr = [];
    props?.units &&
      props?.units?.length &&
      props?.units?.map((itm) => {
        if (itm?.id == selectedUnitId) {
          arr.push({
            ...itm,
            reason: reasonText,
          });
        } else {
          arr.push(itm);
        }
      });

    console.log("datadadjandkasjdnss", props?.allUnits);
    if (arr && arr?.length == props?.units?.length) props.setUnits(arr);

    setOpenUnitAddReason(false);
  };
  const handleImageNameChnage = (value, obj) => {
    let arr = [];
    props?.imageList &&
      props?.imageList?.length > 0 &&
      props?.imageList?.map((itm) => {
        if (itm?.index === obj?.index) {
          arr.push({
            ...itm,
            name: value,
          });
        } else {
          arr.push(itm);
        }
      });
    props?.setImageList(arr);
  };

  const checkPreferedDateInEditMode = () => {
    let dateForInput = props?.preferedDate
      ? new Date(props?.preferedDate)
      : props?.preferedDate;
    let preferdDateFromDatabase = props?.preferdDateFromDatabase
      ? new Date(props?.preferdDateFromDatabase)
      : null;
    // if(preferdDateFromDatabase&&preferdDateFromDatabase ){

    // }
  };

  console.log("props?.object", props?.object);
  return (
    <Box
      className="Container-fluid position-relative"
      sx={{
        "& .MuiFormHelperText-root": {
          fontFamily: "Cairo !important",
          color: "red",
        },
      }}
    >
      <Box
        sx={{
          width: "35px",
          height: "35px",
          borderRadius: "50%",
          position: "fixed",
          top: "15px",
          right: "20px",
          boxShadow: "0 0 10px -2px rgba(0,0,0,0.9)",
          backgroundColor: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          zIndex: "21120",
        }}
        onClick={() => {
          props?.setOpen(false);
        }}
      >
        <CloseIcon
          sx={{
            fontSize: "30px",
            color: "#407ea4",
          }}
        />
      </Box>

      <Box
        className="row d-flex justify-content-center align-items-start"
        sx={{ transition: "all 0.7s ease" }}
      >
        <Box className="col-12 col-sm-12 col-md-6 col-lg-4 ">
          <ContainerOfInputFields
            mainTheme={maintheme}
            customeWidth={"100%"}
            isForm={true}
          >
            <Box
              sx={{
                position: "relative",
                // margin: "5px 0",
                width: "100%",
                backgroundColor: "#fff",
                "& .MuiFilledInput-input": {
                  paddingRight: "0px !important",
                },
              }}
              className="mt-3 orderdata"
            >
              <TextField
                id="standard-basic"
                variant="filled"
                label={t("REQUESTS.add_customer_name")}
                value={props?.customerName}
                InputProps={{ readOnly: true }}
                placeholder="سيف يوسف سيف ..."
                type={"text"}
                sx={{
                  width: "100% !important",
                  paddingRight: "0 !important",
                }}
                focused={true}
              />
            </Box>
            <Box
              sx={{
                position: "relative",
                // margin: "5px 0",
                width: "100%",
                backgroundColor: "#fff",
              }}
              className="mt-2 orderdata"
            >
              <TextField
                id="standard-basic"
                variant="filled"
                label={t("REQUESTS.add_username")}
                value={props?.userName}
                InputProps={{ readOnly: true }}
                // onChange={(e)=>handleChange(e,2,'customerName')}
                placeholder="سيف يوسف سيف ..."
                type={"text"}
                focused={true}
                // className={`${ErrorsObject?.customerName?.error?'errors':''} Mui-focused`}
              />
              {/* {props?.customerName ? (
                            <CloseIcon
                            className="closeIcon"
                            onClick={()=>clearInput(2,'customerName')}
                            />
                            ) : null} */}
            </Box>
            <Box
              sx={{
                position: "relative",
                // margin: "5px 0",
                width: "100%",
                backgroundColor: "#fff",
              }}
              className="mt-3 orderdata"
            >
              <TextField
                id="standard-basic"
                variant="filled"
                label={t("REQUESTS.add_request_date")}
                value={
                  props?.requestDate
                    ? helpers.formatDate(props?.requestDate)
                    : ""
                }
                InputProps={{ readOnly: true }}
                // onChange={(e)=>handleChange(e,2,'customerName')}
                placeholder="سيف يوسف سيف ..."
                type={"text"}
                focused={true}
                // className={`${ErrorsObject?.customerName?.error?'errors':''} Mui-focused`}
              />
              {/* {props?.customerName ? (
                            <CloseIcon
                            className="closeIcon"
                            onClick={()=>clearInput(2,'customerName')}
                            />
                            ) : null} */}
            </Box>
          </ContainerOfInputFields>
        </Box>
        <Box className="col-12 col-sm-12 col-md-6 col-lg-4 ">
          <ContainerOfInputFields
            mainTheme={maintheme}
            customeWidth={"100%"}
            isForm={true}
          >
            <Box
              sx={{
                position: "relative",
                // margin: "5px 0",
                width: "100%",
                backgroundColor: "#fff",
              }}
              className="mt-3 orderdata"
            >
              <TextField
                id="standard-basic"
                variant="filled"
                label={t("REQUESTS.add_phone")}
                value={props?.phone}
                onChange={(e) => handleChange(e, 1, "phone")}
                error={errorPhone?.error}
                helperText={errorPhone?.message}
                placeholder="saifJ"
                type={"text"}
                focused={props?.phone ? true : false}
                className={`${ErrorsObject?.phone?.error ? "errors" : ""}`}
              />
              {props?.phone ? (
                <CloseIcon
                  className="closeIcon"
                  onClick={() => clearInput(1, "phone")}
                />
              ) : null}
            </Box>
            {ErrorsObject?.phone?.error &&
            ErrorsObject?.phone?.message?.length ? (
              <Box
                sx={{
                  height: "fit-content",
                  padding: "5px",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  width: "95%",
                }}
              >
                {ErrorsObject?.phone?.message &&
                ErrorsObject?.phone?.message?.length > 0
                  ? ErrorsObject?.phone?.message?.map((messg) => (
                      <span
                        style={{
                          fontFamily: "Cairo-Bold",
                          fontSize: "14px",
                          color: "red",
                          height: "auto",
                        }}
                      >
                        {messg}
                      </span>
                    ))
                  : null}
              </Box>
            ) : null}
            <Box
              sx={{
                position: "relative",
                // margin: "5px 0",
                width: "100%",
                backgroundColor: "#fff",
              }}
              className="mt-3 orderdata"
            >
              <TextField
                id="standard-basic"
                variant="filled"
                label={t("REQUESTS.add_location")}
                value={props?.requestLocation}
                onChange={(e) => handleChange(e, 2, "requestLocation")}
                error={props?.ObjError?.location_error}
                helperText={props?.ObjError?.location_message}
                multiline
                InputProps={{
                  inputProps: {
                    maxLength: props?.maximumRequestLocationCharts,
                  },
                }}
                type={"text"}
                focused={props?.requestLocation ? true : false}
                className={`${
                  ErrorsObject?.requestLocation?.error ? "errors" : ""
                }`}
              />
              {props?.requestLocation ? (
                <CloseIcon
                  className="closeIcon"
                  onClick={() => clearInput(2, "requestLocation")}
                />
              ) : null}
            </Box>
            {ErrorsObject?.requestLocation?.error &&
            ErrorsObject?.requestLocation?.message?.length ? (
              <Box
                sx={{
                  height: "fit-content",
                  padding: "5px",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  width: "95%",
                }}
              >
                {ErrorsObject?.requestLocation?.message &&
                ErrorsObject?.requestLocation?.message?.length > 0
                  ? ErrorsObject?.requestLocation?.message?.map((messg) => (
                      <span
                        style={{
                          fontFamily: "Cairo-Bold",
                          fontSize: "14px",
                          color: "red",
                          height: "auto",
                        }}
                      >
                        {messg}
                      </span>
                    ))
                  : null}
              </Box>
            ) : null}

            {props?.task_id ? (
              <Box
                sx={{
                  position: "relative",
                  // margin: "5px 0",
                  width: "100%",
                  backgroundColor: "#fff",
                }}
                className="mt-3 orderdata"
              >
                <TextField
                  id="standard-basic"
                  variant="filled"
                  label={t("REQUESTS.add_status")}
                  value={getTranslateStatus(props?.status?.name)}
                  InputProps={{ readOnly: true }}
                  // onChange={(e)=>handleChange(e,2,'customerName')}
                  placeholder="سيف يوسف سيف ..."
                  type={"text"}
                  focused={true}
                  // className={`${ErrorsObject?.customerName?.error?'errors':''} Mui-focused`}
                />
                {/* {props?.customerName ? (
                          <CloseIcon
                          className="closeIcon"
                          onClick={()=>clearInput(2,'customerName')}
                          />
                          ) : null} */}
              </Box>
            ) : null}
          </ContainerOfInputFields>

          <ContainerOfSelectField
            mainTheme={maintheme}
            customeWidth={"100%"}
            hasError={ErrorsObject?.requestType?.error}
            isForm={props?.requestType ? true : false}
          >
            <Box
              sx={{
                position: "relative",
                // margin: "10px 0",
                width: "100%",
                backgroundColor: "#fff",
              }}
              className="mt-3"
            >
              <Autocomplete
                //    disablePortal
                id="combo-box-demo"
                options={
                  props?.requestTypes && props?.requestTypes?.length
                    ? props?.requestTypes
                    : []
                }
                value={props?.requestType}
                onChange={(e, newValue) =>
                  handleChange(newValue, 3, "requestType")
                }
                getOptionLabel={(option) => option?.name || ""}
                popupIcon={<RiIcons.RiArrowDropDownLine />}
                clearIcon={
                  <CloseIcon
                    sx={{ fontSize: "20px", color: maintheme?.iconColor }}
                    onClick={() => clearInput(3, "requestType")}
                  />
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("REQUESTS.add_request_type")}
                    focused={props?.requestType ? true : false}
                    variant="filled"
                    error={props?.ObjError?.type_error}
                    helperText={props?.ObjError?.type_message}
                  />
                )}
                className={`${ErrorsObject?.requestType?.error} ${
                  ErrorsObject?.requestType?.error
                    ? "Mui-focused"
                    : props?.requestType
                    ? "Mui-focused"
                    : ""
                }`}
              />
            </Box>
            {ErrorsObject?.requestType?.error &&
            ErrorsObject?.requestType?.message?.length ? (
              <Box
                sx={{
                  height: "fit-content",
                  padding: "5px",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  width: "95%",
                }}
              >
                {ErrorsObject?.requestType?.message &&
                ErrorsObject?.requestType?.message?.length > 0
                  ? ErrorsObject?.requestType?.message?.map((messg) => (
                      <span
                        style={{
                          fontFamily: "Cairo-Bold",
                          fontSize: "14px",
                          color: "red",
                          height: "auto",
                        }}
                      >
                        {messg}
                      </span>
                    ))
                  : null}
              </Box>
            ) : null}
          </ContainerOfSelectField>
        </Box>
        <Box className="col-12 col-sm-12 col-md-6 col-lg-4 ">
          <ContainerOfInputFields
            mainTheme={maintheme}
            customeWidth={"100%"}
            isForm={true}
          >
            <Box
              sx={{
                position: "relative",
                // margin: "5px 0",
                width: "100%",
                backgroundColor: "#fff",
              }}
              className="mt-3 orderdata"
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label={t("REQUESTS.add_prefered_date")}
                  inputFormat="MM/DD/YYYY"
                  value={props?.preferedDate}
                  minDate={
                    props?.preferdDateFromDatabase
                      ? new Date(props?.preferdDateFromDatabase)
                      : new Date()
                  }
                  inputProps={{
                    placeholder: "لم يتم التحديد",
                  }}
                  onChange={(date) => handleChange(date, 5, "preferedDate")}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      focused={props?.preferedDate ? true : false}
                      className={`${
                        ErrorsObject?.preferedDate?.error ? "errors" : ""
                      }`}
                      error={props?.ObjError?.prefereDate_error}
                      helperText={props?.ObjError?.prefereDate_message}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "لم يتم التحديد",
                      }}
                    />
                  )}
                />
                {props?.preferedDate ? (
                  <CloseIcon
                    className="closeIcon"
                    onClick={() => clearInput(5, "preferedDate")}
                    sx={{
                      right: "37px !important",
                      top: "14px !important",
                    }}
                  />
                ) : null}
              </LocalizationProvider>
            </Box>
            {ErrorsObject?.preferedDate?.error &&
            ErrorsObject?.preferedDate?.message?.length ? (
              <Box
                sx={{
                  height: "fit-content",
                  padding: "5px",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  width: "95%",
                }}
              >
                {ErrorsObject?.preferedDate?.message &&
                ErrorsObject?.preferedDate?.message?.length > 0
                  ? ErrorsObject?.preferedDate?.message?.map((messg) => (
                      <span
                        style={{
                          fontFamily: "Cairo-Bold",
                          fontSize: "14px",
                          color: "red",
                          height: "auto",
                        }}
                      >
                        {messg}
                      </span>
                    ))
                  : null}
              </Box>
            ) : null}
            <Box
              sx={{
                position: "relative",
                // margin: "5px 0",
                width: "100%",
                backgroundColor: "#fff",
              }}
              className="mt-3 orderdata"
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label={t("REQUESTS.add_prefered_time")}
                  // inputFormat="HH:mm"
                  value={props?.preferedTime}
                  onChange={(date) => {
                    handleChange(date, 6, "preferedTime");
                  }}
                  inputProps={{
                    placeholder: "لم يتم التحديد",
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      focused={props?.preferedTime ? true : false}
                      className={`${
                        ErrorsObject?.preferedTime?.error ? "errors" : ""
                      }`}
                      error={props?.ObjError?.prefereTime_error}
                      helperText={props?.ObjError?.prefereTime_message}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "لم يتم التحديد",
                      }}
                    />
                  )}
                />
                {props?.preferedTime ? (
                  <CloseIcon
                    className="closeIcon"
                    onClick={() => clearInput(6, "preferedTime")}
                    sx={{
                      right: "37px !important",
                      top: "14px !important",
                    }}
                  />
                ) : null}
              </LocalizationProvider>
            </Box>
            {ErrorsObject?.preferedTime?.error &&
            ErrorsObject?.preferedTime?.message?.length ? (
              <Box
                sx={{
                  height: "fit-content",
                  padding: "5px",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  width: "95%",
                }}
              >
                {ErrorsObject?.preferedTime?.message &&
                ErrorsObject?.preferedTime?.message?.length > 0
                  ? ErrorsObject?.preferedTime?.message?.map((messg) => (
                      <span
                        style={{
                          fontFamily: "Cairo-Bold",
                          fontSize: "14px",
                          color: "red",
                          height: "auto",
                        }}
                      >
                        {messg}
                      </span>
                    ))
                  : null}
              </Box>
            ) : null}
          </ContainerOfInputFields>

          <ContainerOfInputFields
            mainTheme={maintheme}
            customeWidth={"100%"}
            isForm={true}
            hasMultiLine={true}
          >
            <Box
              sx={{
                position: "relative",
                // margin: "5px 0",
                width: "100%",
                backgroundColor: "#fff",
              }}
              className="mt-3 orderdata"
            >
              <TextField
                id="standard-basic"
                variant="filled"
                label={t("REQUESTS.add_notes")}
                value={props?.notes}
                error={props?.ObjError?.note_error}
                helperText={props?.ObjError?.note_message}
                onChange={(e) => handleChange(e, 4, "notes")}
                placeholder="saifJ"
                type={"text"}
                multiline
                focused={props?.notes ? true : false}
                className={`${ErrorsObject?.notes?.error ? "errors" : ""}`}
              />
              {props?.notes ? (
                <CloseIcon
                  className="closeIcon textAreaIcons"
                  onClick={() => clearInput(4, "notes")}
                />
              ) : null}
            </Box>
            {ErrorsObject?.notes?.error &&
            ErrorsObject?.notes?.message?.length ? (
              <Box
                sx={{
                  height: "fit-content",
                  padding: "5px",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  width: "95%",
                }}
              >
                {ErrorsObject?.notes?.message &&
                ErrorsObject?.notes?.message?.length > 0
                  ? ErrorsObject?.notes?.message?.map((messg) => (
                      <span
                        style={{
                          fontFamily: "Cairo-Bold",
                          fontSize: "14px",
                          color: "red",
                          height: "auto",
                        }}
                      >
                        {messg}
                      </span>
                    ))
                  : null}
              </Box>
            ) : null}
          </ContainerOfInputFields>
        </Box>


        <Box className="col-12 col-md-12 col-lg-12 col-xlg-12 my-3 px-4">
          <Box
            sx={{
              width: "97.8%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "0 20px",
              margin: "10px auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "10px 0 20px 0",
                width: "100%",
              }}
            >
              <span
                style={{
                  width: "43%",
                  height: "1px",
                  backgroundColor: "#c1c1c1",
                }}
              ></span>
              <span style={{ fontFamily: "Cairo", fontWeight: "Bold" }}>
               الصور
              </span>
              <span
                style={{
                  width: "43%",
                  height: "1px",
                  backgroundColor: "#c1c1c1",
                }}
              ></span>
            </Box>
            <Box
              className="my-3 me-4 pe-3"
              sx={{
                display: "flex",
                alignItems: "start",
                flexDirection: "column",
                justifyContent: "center",
                width:'100%'
              }}
            >
              <span
                style={{
                  fontFamily: "Cairo-Bold",
                  fontSize: "18px",
                  margin: "-10px 0 10px 0",
                }}
              >
                اسناد مرفقات
              </span>
              <Box>
                <FaIcons.FaCommentAlt
                  style={{ color: "grey", fontSize: "18px" }}
                />{" "}
                <span
                  style={{
                    fontFamily: "Cairo-Medium",
                    fontSize: "13px",
                    color: "red",
                  }}
                >
                  ملاحظة: اكبر عدد للملفات المسموح رفعها 6 بالاضافة ان لا يتجاوز
                  حجم الملف
                </span>
                <span
                  style={{
                    fontFamily: "Cairo-Medium",
                    fontSize: "15px",
                    color: "red",
                    margin: "0 2px",
                  }}
                >
                  2MB
                </span>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                padding: "0 40px",
                flexWrap: "wrap",
              }}
            >
              {props?.imageList &&
                props.imageList?.length > 0 &&
                props.imageList?.map((itm) => (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    {getExtension(itm?.url) || itm?.type == "image" ? (
                      <Box
                        sx={{
                          width: "200px !important",
                          height: "230px !important",
                          borderRadius: "5px",
                          //  border:'3px dashed green',
                          //  backgroundCOlor:'lightgrey',
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          borderRadius: "3px",
                          margin: "45px 10px",
                          background: "#ebebeb",
                          boxShadow:
                            "14px 14px 14px #d8d8d8,-14px -14px 14px #fefefe",
                          zIndex: 10,
                          //  flexGrow:'1',
                          //  flexBasis:'1',
                          "&:hover": {
                            transform: "scale(1.1)",
                          },
                          position: "relative",
                        }}
                      >
                        <img
                          src={itm?.url}
                          alt="task photo"
                          style={{
                            width: "100%",
                            height: "230px",
                            objectFit: "cover",
                            backgroundPosition: "center center",
                          }}
                          onClick={() => setSelectedImageToShow(itm)}
                        />
                        <Box
                          sx={{
                            width: "fit-content",
                            height: "fit-content",
                            padding: "3px 11px",
                            backgroundColor: "#414141",
                            borderRadius: "10px",
                            dispaly: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            position: "absolute",
                            top: "5px",
                            right: "10px",
                            zIndex: 23423423,
                            // pointerEvents:''
                          }}
                        >
                          <DeleteIcon
                            sx={{
                              color: "#fff",
                              fontSize: "20px",
                              margin: "0 3px",
                              cursor: "poinrt",
                              "&:hover": {
                                transform: "scale(1.3)",
                                color: "#fab9b4",
                              },
                            }}
                            onClick={() => handleDeleteImage(itm)}
                          />
                          {!props?.editMode && (
                            <CameraswitchIcon
                              sx={{
                                color: "#fff",
                                fontSize: "20px",
                                margin: "0 3px",
                                cursor: "poinrt",
                                "&:hover": {
                                  color: "#486b99",
                                  transform: "scale(1.3)",
                                },
                              }}
                              onClick={() => handleUpdateImage(itm)}
                            />
                          )}
                        </Box>
                        {/* {
                  props?.editMode? */}
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: "-60px",
                            right: 0,
                            zIndex: 23423,
                            width: "97%",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Cairo",
                              color: "#000",
                              fontSize: "12px",
                              wordSpacing: "break-word",
                              wordBreak: "break-word",
                            }}
                          >
                            {itm?.name}
                          </span>
                        </Box>
                        {/* :null} */}
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          width: "200px !important",
                          height: "230px !important",
                          borderRadius: "5px",
                          //  border:'3px dashed green',
                          //  backgroundCOlor:'lightgrey',
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          borderRadius: "3px",
                          margin: "45px 10px",
                          background: "#ebebeb",
                          boxShadow:
                            "14px 14px 14px #d8d8d8,-14px -14px 14px #fefefe",
                          zIndex: 10,
                          //  flexGrow:'1',
                          //  flexBasis:'1',
                          "&:hover": {
                            transform: "scale(1.1)",
                          },
                          position: "relative",
                        }}
                      >
                        <a href={itm?.url}>
                          <img
                            src={
                              "https://cdn-icons-png.flaticon.com/512/1092/1092004.png"
                            }
                            alt="task photo"
                            style={{
                              width: "100%",
                              height: "230px",
                              objectFit: "cover",
                              backgroundPosition: "center center",
                            }}
                            // onClick={() => setSelectedImageToShow(itm)}
                          />
                        </a>
                        <Box
                          sx={{
                            width: "fit-content",
                            height: "fit-content",
                            padding: "3px 11px",
                            backgroundColor: "#414141",
                            borderRadius: "10px",
                            dispaly: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            position: "absolute",
                            top: "5px",
                            right: "10px",
                            zIndex: 23423423,
                            // pointerEvents:''
                          }}
                        >
                          <DeleteIcon
                            sx={{
                              color: "#fff",
                              fontSize: "20px",
                              margin: "0 3px",
                              cursor: "poinrt",
                              "&:hover": {
                                transform: "scale(1.3)",
                                color: "#fab9b4",
                              },
                            }}
                            onClick={() => handleDeleteImage(itm)}
                          />
                          {!props?.editMode && (
                            <CameraswitchIcon
                              sx={{
                                color: "#fff",
                                fontSize: "20px",
                                margin: "0 3px",
                                cursor: "poinrt",
                                "&:hover": {
                                  color: "#486b99",
                                  transform: "scale(1.3)",
                                },
                              }}
                              onClick={() => handleUpdateImage(itm)}
                            />
                          )}
                        </Box>
                        {/* {
                  props?.editMode? */}
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: "-60px",
                            right: 0,
                            zIndex: 23423,
                            width: "97%",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Cairo",
                              color: "#000",
                              fontSize: "12px",
                              wordSpacing: "break-word",
                              wordBreak: "break-word",
                            }}
                          >
                            {itm?.name}
                          </span>
                        </Box>
                        {/* :null} */}
                      </Box>
                    )}
                    {/* {
                  !props?.editMode?
                <ContainerOfInputFields
                    mainTheme={maintheme}
                    customeWidth={"100%"}
                    isForm={true}
                 >
                  <Box
                    sx={{
                      position: "relative",
                      // margin: "5px 0",
                      width: "100%",
                      backgroundColor: "#fff",
                      position:'relative',
                      top:'-30px'
                    }}
                    className="mt-3 orderdata"
                  >
                    <TextField
                      id="standard-basic"
                      variant="filled"
                      label={getExtension(itm?.url)||itm?.type=="image"?'اسم الصورة':'اسم الملف'}
                      value={itm?.name}
                      onChange={(e) => handleImageNameChnage(e?.target?.value,itm)}
                      placeholder="..."
                      // error={props?.ObjError?.location_error}
                      // helperText={props?.ObjError?.location_message}
                      type={'text'}
                      focused
                      // focused={props?.requestLocation ? true : false}
                      className={` Mui-focused`}
                    />
                    {itm?.name ? (
                      <CloseIcon
                        className="closeIcon"
                        onClick={() => handleImageNameChnage('', itm)}
                      />
                    ) : null}
                  </Box>
                  {
                    ErrorsObject?.requestLocation?.error && ErrorsObject?.requestLocation?.message?.length ?
                      <Box sx={{
                        height: 'fit-content',
                        padding: '5px',
                        display: 'flex',
                        flexDirection: 'column',
                        flexWrap: 'wrap',
                        width: '95%',
                      }}>
                        {
                          ErrorsObject?.requestLocation?.message && ErrorsObject?.requestLocation?.message?.length > 0 ? ErrorsObject?.requestLocation?.message?.map((messg) => (
                            <span style={{ fontFamily: 'Cairo-Bold', fontSize: '14px', color: 'red', height: 'auto' }}>{messg}</span>
                          )) : null
                        }
                      </Box> 
                      : 
                      null
                  }
                 </ContainerOfInputFields>:null
                } */}
                  </Box>
                ))}
              <Box
                sx={{
                  width: "200px",
                  height: "200px",
                  borderRadius: "5px",
                  border: "3px dashed green",
                  //  backgroundCOlor:'lightgrey',
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  borderRadius: "3px",
                  background: "#ebebeb",
                  boxShadow: "14px 14px 14px #d8d8d8,-14px -14px 14px #fefefe",
                }}
                onClick={addImageToList}
              >
                <AddAPhotoIcon
                  sx={{
                    fontSize: "40px",
                    color: "black",
                    pointerEvents: "none",
                  }}
                />
                <input
                  type="file"
                  className="d-none"
                  onChange={handleImageChange}
                  id="imagesForTask"
                  accept="image/png, image/gif, image/jpeg,.xlsx, .xls, .csv"
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="col-12 col-md-12 col-lg-12 col-xlg-12 my-3 px-4">
          <Box
            sx={{
              width: "97.8%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "0 20px",
              margin: "10px auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "10px 0",
                width: "100%",
              }}
            >
              <span
                style={{
                  width: "43%",
                  height: "1px",
                  backgroundColor: "#c1c1c1",
                }}
              ></span>
              <span style={{ fontFamily: "Cairo", fontWeight: "Bold" }}>
                التعليقات
              </span>
              <span
                style={{
                  width: "43%",
                  height: "1px",
                  backgroundColor: "#c1c1c1",
                }}
              ></span>
            </Box>
            <ViewComment data={props?.data} />
          </Box>
        </Box>
        {props?.requestType && props?.requestType?.mandatory_units ? (
          <Box className="col-12 col-md-12 col-lg-12 col-xlg-12 my-3 px-4">
            <Box
              sx={{
                width: "97.8%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "0 20px",
                margin: "10px auto",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "10px 0 20px 0",
                  width: "100%",
                }}
              >
                <span
                  style={{
                    width: "43%",
                    height: "1px",
                    backgroundColor: "#c1c1c1",
                  }}
                ></span>
                <span style={{ fontFamily: "Cairo", fontWeight: "Bold" }}>
                  الوحدات
                </span>
                <span
                  style={{
                    width: "43%",
                    height: "1px",
                    backgroundColor: "#c1c1c1",
                  }}
                ></span>
              </Box>
              <Grid
                rows={props?.units}
                columns={columns}
                setColumns={null}
                pageSize={pageSize}
                setPageSize={setPageSize}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                loading={loading}
                handlePageChange={handlePageChange}
                rowsTotal={props?.units?.length}
                checkScreenSize={screenwidth}
                pageCount={Math.ceil(props?.units?.length / pageSize)}
                openFilterColumn={null}
                elementClicked={null}
                setOpenFilterColumn={null}
                setColumnFilterValue={null}
                columFilterValue={null}
                clearFunction={props.handleClearInAddvancSearch}
                searchFunction={props.handleSearchInAddvancSearch}
                creatFunction={null}
                ExportFunction={null}
                importFunction={null}
                mapFunction={null}
                telegramFunction={null}
                hasCreate={false}
                hasImport={false}
                hasExport={false}
                hasMap={false}
                hasTetelgram={false}
                filterChilds={
                  <FilterSearchContent
                    setUnitNameSearch={props?.setUnitNameSearch}
                    unitNameSearch={props?.unitNameSearch}
                    setCheckAllselected={props.setCheckAllselected}
                    checkAllselected={props.checkAllselected}
                    setCheckAllNotSelected={props.setCheckAllNotSelected}
                    checkAllNotSelected={props?.checkAllNotSelected}
                  />
                }
                filterHasSelectCounter={true}
                filterHasSelectCounterValue={props?.listOfSelectedRows?.length}
                notHaveAdvancSearch={false}
                hasSelectOption={true}
                handleCheckBoxChange={handleCheckBoxChange}
                listOfSelectedRows={props.listOfSelectedRows}
                handleRowCellChange={handleRowCellChange}
                paginationMode={"client"}
                isUnitAdd={true}
              />
            </Box>
          </Box>
        ) : null}
        {/* <Box className="col-12 col-sm-12 col-md-12 mx-1 d-flex justify-content-center align-items-center">
                    <Box
                      sx={{
                        width:'200px',
                        borderRadius:'50%',
                        backgroundColor:'rgba(242, 245, 243,0.4)',
                        height:'200px',
                        margin:'20px 0 10px 0',
                        border:'2px dashed #1e6a99',
                        // zIndex:335345,
                        // position:'relative',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        cursor:'pointer',
                        backgroundImage:`url(${props?.selectedImageURL})`,
                        backgroundSize:'cover',
                        backgroundPosition:'center center',
                      }}
                      onClick={openImageSelector}
                    >
                       <BsIcons.BsCamera style={{fontSize:'40px',color:'#1e6a99',pointerEvents:'none'}}/>
                       <input type="file" accept="image/x-png,image/gif,image/jpeg"  className='d-none' id="userImage" onChange={onImageChange}/>
                    </Box>
                    
                </Box> */}
        {<Box></Box>}

        {selectedImage ? (
          <Box
            sx={{
              position: "fixed",
              width: window.innerWidth,
              height: window.innerHeight,
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backdropFilter: "blur(7px)",
              zIndex: "234234234",
            }}
          >
            <img
              src={selectedImage}
              alt="selected photo to show"
              style={{
                width: "600px",
                height: "600px",
                objectFit: "cover",
                backgroundPosition: "center center",
              }}
            />
            <CloseIcon
              sx={{
                position: "fixed",
                top: 10,
                left: 20,
                color: "#47aeed",
                fontSize: "40px",
                zIndex: 234234234,
                cursor: "pinter !important",
                "&:hover": {
                  color: "red",
                },
              }}
              onClick={() => setSelectedImage("")}
            />
          </Box>
        ) : null}

        <AddReason
          title={t("REQUESTS.reasonTitle")}
          titleFiled={t("REQUESTS.noteReason")}
          open={openUnitAddReason}
          setOpen={setOpenUnitAddReason}
          value={reasonText}
          setValue={setReasonText}
          submit={handleUnitReasonChange}
          clearFlieldAfterClose={() => {}}
        />
        <AddReason
          title={"اضافة اسم للصورة او الملف"}
          titleFiled={"الاسم"}
          open={nameForUpdatedImage}
          setOpen={setNameForUpdatedImage}
          value={nameForUpdatedImageValue}
          setValue={setNameForUpdatedImageValue}
          submit={!isUploadingEditMode ? addUpdatedImage : () => {}}
          clearFlieldAfterClose={() => {
            setFileForUpdatedImage(null);
            setNameForUpdatedImage(false);
            setNameForUpdatedImageValue("");
            setFileForUpdatedImageExtention("");
          }}
        />
      </Box>
    </Box>
  );
};

const RenderFooter = (props) => {
  const [t] = useTranslation("common");
  const gridtheme = useSelector((state) => state.themeData.gridtheme);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box display="flex">
          <Button
            variant="outlined"
            // spacing={2}
            sx={{
              width: "50% !important",
              // flexGrow: 1,
              minWidth: "80px !important",
              maxWidth: "80px !important",
              margin: 1,
              backgroundColor: "#d1d0d0",
              borderColor: gridtheme?.colorWhite,
              color: gridtheme?.colorblack,
              boxShadow: "0 0 7px -2px white",
              //   color:SearchButtonTheme?.clear_button_text_color,

              "&:hover": {
                backgroundColor: "#1e6A99",
                borderColor: gridtheme?.colorWhite,
                color: "#fff",
                boxShadow: "0 0 7px 1px white",
                boxShadow: "0",
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterClear"
            color="secondary"
            onClick={() => {
              // props.setOpen(false)
              props.clearForm();
            }}
          >
            {t("GLOBAL.clear")}
          </Button>
          <Button
            disabled={props?.isUploadingTime}
            variant="contained"
            // spacing={2}
            sx={{
              // flexGrow: 1,
              margin: 1,
              width: "80px !important",
              minWidth: "80px !important",
              maxWidth: "80px !important",
              "&:hover": {
                //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterSearch"
            onClick={() => {
              props.submit();
            }}
          >
            {t("GLOBAL.Save")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default function AddRequest(props) {
  const [t] = useTranslation("common");
  const [name, setName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [ErrorsObject, setErrorsObject] = useState(ObjectErrors);
  const navigate = useNavigate();
  const [phone, setPhone] = useState(cookies.get("phone"));
  const [requestLocation, setRequestLocation] = useState("");
  const [requestType, setRequestType] = useState(null);
  const [requestTypes, setRequestTypes] = useState([]);
  const [unit, setUnit] = useState(null);
  const [units, setUnits] = useState([]);
  const [preferedDate, setPreferedDate] = useState(null);
  const [preferedTime, setPreferedTime] = useState(null);
  const [notes, setNotes] = useState("");
  const [customerName, setCustomerName] = useState(cookies.get("account_name"));
  const [userName, setUserName] = useState(cookies.get("user_name"));
  const [requestDate, setRequestDate] = useState(new Date());
  const [comments, setComments] = useState([]);

  const [status, setStatus] = useState({
    id: 1,
    name: "جديد",
  });
  const [statusList, setStatusList] = useState([
    {
      id: 1,
      name: "new",
    },
    {
      id: 2,
      name: "in Progress",
    },
    {
      id: 3,
      name: "closed",
    },
    {
      id: 4,
      name: "rejected",
    },
    {
      id: 5,
      name: "approved",
    },
    {
      id: 6,
      name: "archived",
    },
  ]);
  const globalLoading = useSelector(
    (state) => state.settingsData.globalLoading
  );
  const [inprogressDateTime, setInprogressDate] = useState(null);
  const [closeingDateTime, setcloseingDate] = useState(null);
  const [monitoredBy, setMonitoredBy] = useState(null);
  const [assignedBy, setAssignedBy] = useState(null);
  const [unitNameSearch, setUnitNameSearch] = useState();
  const [filteredUnits, setFilteredUnits] = useState([]);
  const [listOfSelectedRows, setListOfSelectedRows] = useState([]);
  const [isSearchApplay, setIsSearchApplay] = useState(false);
  const [checkAllselected, setCheckAllselected] = useState(false);
  const [checkAllNotSelected, setCheckAllNotSelected] = useState(false);
  const [isUploadingTime, setIsUploadingTime] = useState(false);
  const [preferdDateFromDatabase, setPreferdDateFromDatabase] = useState(null);
  const [
    maximumRequestLocationCharts,
    setMaximumRequestLocationCharts,
  ] = useState(50);
  const [ObjError, setObjError] = useState({
    phone_error: false,
    phone_message: "",
    note_error: false,
    note_message: "",
    location_error: false,
    location_message: "",
    type_error: false,
    type_message: "",
    prefreDate_error: false,
    prefereDate_message: "",
    prefreTime_error: false,
    prefereTime_message: "",
  });

  const [imageList, setImageList] = useState([]);

  const getNameOfImage = (file) => {
    const name = file.split("/");
    if (name && name?.length > 0) {
      return name[name?.length - 1];
    }
    return "";
  };

  const checkPreferedDateInEditMode = () => {
    let dateForInput = preferedDate ? new Date(preferedDate) : preferedDate;
    let preferdDateFromDB = preferdDateFromDatabase
      ? new Date(preferdDateFromDatabase)
      : null;
    if (preferdDateFromDB && dateForInput >= preferdDateFromDB) {
      return true;
    }
    if (!preferdDateFromDB) return true;
    return false;
  };
  const checkDateAndTimePreferd = (number) => {
    console.log("errorcheck-testDate3");

    if (number == 0) {
      let check = isValidDate(helpers.formatDate(preferedDate));
      if (check) {
        console.log("errorcheck-testDate4");
        setObjError({
          ...ObjError,
          prefereDate_error: false,
          prefereDate_message: "",
        });
        return true;
      } else {
        console.log("errorcheck-testDate5");

        setObjError({
          ...ObjError,
          prefereDate_error: true,
          prefereDate_message: "صيغة التاريخ غير صحيحة",
        });
        return false;
      }
    } else if (number == 1) {
      let check = false;
      if (preferedTime)
        check = isValidTime(formatDateTime(new Date(preferedTime)));
      if (check) {
        console.log("errorcheck-testDate6");
        setObjError({
          ...ObjError,
          prefereTime_error: false,
          prefereTime_message: "",
        });
        return true;
      } else {
        console.log("errorcheck-testDate7");
        setObjError({
          ...ObjError,
          prefereTime_error: true,
          prefereTime_message: "صيغة الوقت غير صحيحة",
        });
        return false;
      }
    }
  };
  const submit = async () => {
    if (
      cookies.get("active_session") &&
      cookies.get("active_session") == "yes"
    ) {
      try {
        let object = ObjError;
        if (!phone) {
          //  toast.warn('رقم الهاتف مطلوب')
          console.log("errorcheck-phone1");
          object = {
            ...object,
            phone_error: true,
            phone_message: "رقم الهاتف مطلوب",
          };
        } else if (
          (phone?.length < 11 || phone?.length > 11) &&
          !isNaN(phone)
        ) {
          console.log("errorcheck-phone2");
          object = {
            ...object,
            phone_error: true,
            phone_message: "رقم الهاتف يجب ان يتكون من 11 رقم",
          };
        } else if (isNaN(phone)) {
          console.log("errorcheck-phone3");
          object = {
            ...object,
            phone_error: true,
            phone_message: "صيغة رقم الهاتف غير صحيحة",
          };
        } else {
          console.log("errorcheck-phone4");
          object = {
            ...object,
            phone_error: false,
            phone_message: "",
          };
        }

        if (
          (listOfSelectedRows?.length == 0 &&
            !notes &&
            requestType?.mandatory_units == 1) ||
          (!notes && requestType?.mandatory_units == 1) ||
          requestType == null
        ) {
          console.log("errorcheck-notes1");
          object = {
            ...object,
            note_error: true,
            note_message: "حقل الملاحظات مطلوب",
          };
        } else {
          console.log("errorcheck-notes2");
          object = {
            ...object,
            note_error: false,
            note_message: "",
          };
        }

        if (!requestLocation) {
          console.log("errorcheck-requestlocation1");
          object = {
            ...object,
            location_error: true,
            location_message: "يرجى تحديد مكان الطلب",
          };
        } else {
          console.log("errorcheck-requestlocation2");
          object = {
            ...object,
            location_error: false,
            location_message: "",
          };
        }

        if (!requestType) {
          console.log("errorcheck-requestType1");
          object = {
            ...object,
            type_error: false,
            type_message: "يرجى تحديد نوع الطلب",
          };
        } else {
          console.log("errorcheck-requestType2");
          object = {
            ...object,
            type_error: false,
            type_message: "",
          };
        }

        if (preferedTime && !preferedDate) {
          console.log("errorcheck-preferdDate1");
          object = {
            ...object,
            prefereDate_error: true,
            prefereDate_message: "يرجى أختيار التاريخ المفضل",
          };
        } else {
          console.log("errorcheck-preferdDate2");
          object = {
            ...object,
            prefereDate_error: false,
            prefereDate_message: "",
          };
        }

        let checkDatePreferedOld = false;
        if (preferedDate && !checkPreferedDateInEditMode()) {
          console.log("errorcheck-preferdDate3");
          let dateEnterd = new Date(preferedDate);
          let ToDate = new Date();

          let check = false;
          if (dateEnterd?.getFullYear() < ToDate?.getFullYear()) {
            check = true;
          } else if (dateEnterd?.getFullYear() == ToDate?.getFullYear()) {
            if (dateEnterd?.getMonth() < ToDate?.getMonth()) {
              check = true;
            } else if (dateEnterd?.getMonth() == ToDate?.getMonth()) {
              if (dateEnterd?.getDate() < ToDate?.getDate()) {
                check = true;
              }
            }
          }

          if (check) {
            console.log("errorcheck-preferdDate4");
            object = {
              ...object,
              prefereDate_error: true,
              prefereDate_message: "يرجى أختيار تاريخ بالمستقبل",
            };
            checkDatePreferedOld = true;
          } else {
            console.log("errorcheck-preferdDate5");
            object = {
              ...object,
              prefereDate_error: false,
              prefereDate_message: "",
            };
            checkDatePreferedOld = false;
          }
        }

        if (
          (preferedTime && !preferedDate) ||
          !requestType ||
          !requestLocation ||
          (listOfSelectedRows?.length == 0 &&
            !notes &&
            requestType?.mandatory_units == 1) ||
          (!notes && requestType?.mandatory_units == 1) ||
          ((phone?.length < 11 || phone?.length > 11) && !isNaN(phone)) ||
          isNaN(phone) ||
          !phone ||
          checkDatePreferedOld
        ) {
          console.log("errorcheck-showEror1");
          toast.warn("خطء غير معروف");
          setObjError(object);
          // alert('asdasdsad')
          return;
        } else {
          console.log("errorcheck-showEror2");
          setObjError(object);
        }
        if (!checkDateAndTimePreferd(0) && preferedDate) {
          console.log("errorcheck-testDate1");
          return false;
        }
        if (!checkDateAndTimePreferd(1) && preferedTime) {
          console.log("errorcheck-testDate2");
          return false;
        }
        //  alert('22')

        setIsUploadingTime(true);
        setLoading(true);
        let arr = [];
        let updateObject = {};
        console.log("listOfSelectedRows", listOfSelectedRows);
        listOfSelectedRows &&
          listOfSelectedRows?.length > 0 &&
          listOfSelectedRows?.map((itm) => {
            units &&
              units?.length &&
              units?.map((itm2) => {
                if (itm === itm2?.id) {
                  arr.push({
                    wialon_id: itm2?.id,
                    name: itm2?.nm?.replace("'", ""),
                    reason: itm2?.reason ? itm2?.reason : "",
                  });
                }
              });
          });
        console.log("listOfSelectedRows", arr);

        // let dumData=resetErrors(ErrorsObject)
        const formData = new FormData();
        if (phone) {
          formData.append("user_mobile", phone);
          updateObject["user_mobile"] = phone;
        }
        if (requestLocation) {
          let string = requestLocation?.replace(/\\|\/|\'/g, "");
          formData.append("request_location", string);
          updateObject["request_location"] = string;
        }

        if (requestType) {
          formData.append("request_type_id", requestType?.id);
          updateObject["request_type_id"] = requestType?.id;
        }

        if (arr && arr?.length > 0) {
          arr?.map((itm, index) => {
            formData.append(`units[${index}][wialon_id]`, itm?.wialon_id);
            formData.append(`units[${index}][name]`, itm?.name);
            formData.append(`units[${index}][reason]`, itm?.reason);
          });
          updateObject["units"] = arr;
        }
        if (preferedDate) {
          formData.append("prefered_date", helpers.formatDate(preferedDate));
          updateObject["prefered_date"] = helpers.formatDate(preferedDate);
        }
        if (preferedTime) {
          formData.append("prefered_time", helpers.formatTime(preferedTime));
          updateObject["prefered_time"] = helpers.formatTime(preferedTime);
        }
        if (notes) {
          console.log("sdfsdfds", 1);
          let string = notes?.replace(/\\|\/|\'/g, "");
          formData.append("notes", string);
          updateObject["notes"] = string;
          console.log("sdfsdfds", 2);
        }

        let params = {
          username: cookies.get("user_name"),
          user_id: cookies.get("user_id"),
          account_name: cookies.get("account_name"),
          account_id: cookies.get("account_id"),
          sid: cookies.get("sid"),
          baseurl: cookies.get("baseUrl"),
        };

        let result = null;
        if (props?.object && props?.object?.task_id) {
          updateObject["id"] = props?.object?.task_id;

          result = await requestAPI.EditRequest({
            data: updateObject,
            params: params,
            id: props?.object?.task_id,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          });
        } else {
          if (imageList && imageList?.length > 0) {
            imageList?.map((itm, index) => {
              if (itm?.file) {
                let string = "";
                if (itm?.name) string = itm?.name?.replace(/\\|\/|\'/g, "");

                formData.append(`files[${index}][image]`, itm?.file);
                formData.append(`files[${index}][name]`, string);
              }
            });
          }
          result = await requestAPI.AddRequest({
            data: formData,
            params: params,
          });
        }

        if (result?.status && result?.data?.status) {
          setLoading(false);
          setIsUploadingTime(false);
          toast.success(t("NETWORKMESSAGE.addCustomerMessageSuccess"));
          clearForm();
          props.loadData();
          props.setAddForm(false);
        } else {
          if (typeof result.errorMessage === "object") {
            //   let updatedObject = resetErrors(ErrorsObject)
            //  alert('asdasd')
            // console.log('message',result.errorMessage,updatedObject)
            //   setErrorsObject(checkErrors(result.errorMessage, updatedObject))
          }
          setLoading(false);
          setIsUploadingTime(false);
          if (result?.data?.data?.message?.code == 1000) {
            toast.warn(
              "لا يمكنك تعديل الطلب وذالك لان الطلب قيد المعالجة الان "
            );
            return;
          }
          toast.error(t("NETWORKMESSAGE.addCustomerMessageError"));
          if (result && result?.data && result?.data?.data?.message) {
            if (
              result?.data?.data?.message?.text == "unauthorized to complete"
            ) {
              navigate("/redirect");
            }
          }
        }
        setLoading(false);
        setIsUploadingTime(false);
      } catch (error) {
        setLoading(false);
        setIsUploadingTime(false);
        toast.error(error.message);
      }
    } else {
      // navigate('/redirect')
    }
  };

  const clearForm = () => {
    setPhone("");
    setRequestLocation("");
    setRequestType(null);
    setUnit([]);
    setListOfSelectedRows([]);
    setPreferedDate(null);
    setPreferedTime(null);
    setImageList([]);
    setNotes("");
  };
  useEffect(() => {
    if (!props?.object) {
      clearForm();
      setPhone(cookies.get("phone"));
    } else {
      getData();
      getDataOfSelectedTask(props?.object?.task_id);
    }
  }, [props?.addForm, props?.object]);

  useEffect(() => {
    getAllUnits();
    getAllRequestTypes();
    locadDataOfConfiguration();
  }, []);
  //get all units from wialon her...
  const getAllUnits = () => {
    if (
      cookies.get("active_session") &&
      cookies.get("active_session") == "yes"
    ) {
      const newSid = cookies.get("sid");
      $.ajax({
        type: "GET",
        enctype: "application/json",
        processData: !1,
        contentType: !1,
        crossDomain: true,
        dataType: "jsonp",
        url:
          cookies.get("baseUrl") +
          '/wialon/ajax.html?svc=core/search_items&params={"spec":{"itemsType":"avl_unit","propName":"sys_name","propValueMask":"*","sortType":"sys_name","propType":"property"},"force":0,"flags":1,"from":0,"to":0}&sid=' +
          newSid,
        success: function(data) {
          console.log("allunitsdata", data);
          let arr = [];
          data?.items &&
            data?.items?.length > 0 &&
            data?.items?.map((itm, index) => {
              arr.push({
                ...itm,
                index: index + 1,
              });
            });
          setUnits(arr);
        },
      });
    } else {
      navigate("/");
    }
  };
  //get all requestTypes here..
  const getAllRequestTypes = async () => {
    if (
      cookies.get("active_session") &&
      cookies.get("active_session") == "yes"
    ) {
      try {
        setLoading(true);
        let arr = [];
        let params = {
          username: cookies.get("user_name"),
          user_id: cookies.get("user_id"),
          account_name: cookies.get("account_name"),
          account_id: cookies.get("account_id"),
          sid: cookies.get("sid"),
          baseurl: cookies.get("baseUrl"),
        };
        let result = await requestTypeAPI.RequestTypes({
          params: params,
        });
        if (result?.status) {
          setLoading(false);
          if (result?.data && result?.data?.data) {
            // let arr=[]
            // result?.data?.data&&result?.data?.data?.length>0&&result?.data?.data?.map((itm,index)=>{
            //      arr.push({
            //       ...itm,
            //       index:index,
            //      })
            // })
            setRequestTypes(result?.data?.data);
          }
          // toast.success(t('NETWORKMESSAGE.addCustomerMessageSuccess'))
          if (result && result?.data && result?.data?.data?.message) {
            if (
              result?.data?.data?.message?.text == "unauthorized to complete"
            ) {
              navigate("/redirect");
            }
          }
        } else {
          setLoading(false);
          toast.error(t("NETWORKMESSAGE.addCustomerMessageError"));
          if (result && result?.data && result?.data?.data?.message) {
            if (
              result?.data?.data?.message?.text == "unauthorized to complete"
            ) {
              navigate("/redirect");
            }
          }
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(error.message);
      }
    } else {
      // navigate('/redirect')
    }
  };
  const handleSearchInAddvancSearch = () => {
    let arr = [];
    if (unitNameSearch) {
      setIsSearchApplay(true);

      units &&
        units?.length &&
        units?.map((itm) => {
          if (itm?.nm.includes(unitNameSearch)) {
            arr.push(itm);
          }
        });
      setFilteredUnits(arr);
    } else {
      arr = [...units];
      setFilteredUnits(units);
    }
    if (checkAllselected) {
      setFilteredUnits(
        arr.filter((itm) => listOfSelectedRows?.includes(itm?.id))
      );
      console.log("sadasdasdasdasd", unit);
      console.log("sadasdasdasdasd", listOfSelectedRows);
    }
    if (checkAllNotSelected) {
      setFilteredUnits(
        arr.filter((itm) => !listOfSelectedRows?.includes(itm?.id))
      );
    }
  };
  const handleClearInAddvancSearch = () => {
    setIsSearchApplay(false);
    setUnitNameSearch("");
    setFilteredUnits(units);
    setCheckAllselected(false);
    console.log("hhhddkkkdd", unit);
  };
  // useEffect(()=>{
  //   if(unit&&unit?.length>0&&!isSearchApplay){
  //     let arr=[];
  //     unit&&unit?.length&&unit?.map((itm)=>{
  //       if(units){
  //         units&&units?.length&&units?.map((itm2)=>{
  //             if(itm?.id==itm2?.id)
  //              arr.push(itm2)
  //         })
  //       }
  //     })
  //     units&&units?.length&&units?.map((itm2)=>{
  //       let check=false;
  //       arr&&arr?.length&&unit?.map((itm)=>{
  //         if(itm2?.id==itm?.id)
  //         check=true
  //       })
  //       if(!check)
  //         arr.push(itm2)
  //     })

  //     setUnits(arr)
  //   }
  // },[unit,isSearchApplay])

  useEffect(() => {
    if (units) {
      setFilteredUnits(units);
    }
  }, [units]);
  const getExtension = (file) => {
    const extension = file.split(".").pop();

    if (
      extension == "jpeg" ||
      extension == "jpg" ||
      extension == "png" ||
      extension == "gif" ||
      extension == "svg"
    ) {
      return true;
    }
  };

  const getDataOfSelectedTask = async (id) => {
    let result = null;
    try {
      let params = {
        username: cookies.get("user_name"),
        user_id: cookies.get("user_id"),
        account_name: cookies.get("account_name"),
        account_id: cookies.get("account_id"),
        sid: cookies.get("sid"),
        baseurl: cookies.get("baseUrl"),
      };
      result = await requestAPI.ShowRequestTask({
        params: params,
        id: id,
      });

      if (result?.status && result?.data?.status) {
        setPhone(result?.data?.data?.task?.user_mobile);
        setRequestLocation(result?.data?.data?.task?.request_location);
        setRequestType(result?.data?.data?.request_type);
        setNotes(result?.data?.data?.task?.note);
        setPreferedDate(
          result?.data?.data?.task?.prefered_date
            ? helpers.formatDate(result?.data?.data?.task?.prefered_date)
            : null
        );
        setPreferdDateFromDatabase(
          result?.data?.data?.task?.prefered_date &&
            result?.data?.data?.task?.prefered_date != "0000-00-00"
            ? helpers.formatDate(result?.data?.data?.task?.prefered_date)
            : null
        );
        setPreferedTime(
          result?.data?.data?.task?.prefered_time &&
            result?.data?.data?.task?.prefered_date
            ? new Date(
                helpers.formatDate(result?.data?.data?.task?.prefered_date) +
                  " " +
                  result?.data?.data?.task?.prefered_time
              )
            : null
        );
        let arrImg = [];
        result?.data?.data?.files &&
          result?.data?.data?.files?.length > 0 &&
          result?.data?.data?.files?.map((itm, index) => {
            let checkImage = getExtension(itm?.file_path);
            arrImg.push({
              url: APP_CONSTANTS.API_URL_IMG + itm?.file_path,
              file: null,
              index: index,
              id: itm?.file_id,
              type: checkImage ? "image" : "file",
              name: itm?.name ? itm?.name : getNameOfImage(itm?.file_path),
            });
          });
        setImageList(arrImg);
        let arr = [];
        if (result?.data?.data?.units && result?.data?.data?.units?.length) {
          result?.data?.data?.units?.map((itm, index) => {
            arr.push({
              id: itm?.wialon_id,
              index: index + 1,
              nm: itm?.name,
              nmxc: "",
              reason: itm?.reason,
            });
          });
        }
        setUnits(arr);
        setStatus(result?.data?.data?.status);
        setInprogressDate(result?.data?.data?.inprogress?.created_at);
        setcloseingDate(result?.data?.data?.closed?.created_at);
        let arrId = [];
        result?.data?.data?.units &&
          result?.data?.data?.units?.length > 0 &&
          result?.data?.data?.units?.map((itm) => {
            arrId.push(itm?.wialon_id);
          });
        setListOfSelectedRows(arrId);

        setLoading(false);
        // toast.success(t('NETWORKMESSAGE.addCustomerMessageSuccess'))
      } else {
        setLoading(false);
        toast.error(t("NETWORKMESSAGE.addCustomerMessageError"));
        if (result && result?.data && result?.data?.data?.message) {
          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  function isValidDate(dateString) {
    // Create a new Date object from the dateString
    let date = new Date(dateString);

    // Check if the date object is valid
    if (isNaN(date.getTime())) {
      // Date is invalid
      return false;
    } else {
      // Date is valid
      return true;
    }
  }

  function isValidTime(timeString) {
    // Split time string into hours, minutes, and am/pm
    const [time, amPm] = timeString.split(" ")?.map((itm) => itm);
    const [hours, minutes] = time.split(":")?.map((itm) => itm);
    const ampmLowerCase = amPm ? amPm.toLowerCase() : "";
    console.log("timeCon", amPm);
    console.log("timeCon", hours);
    console.log("timeCon", minutes);

    if (hours == "NaN" || minutes == "NaN") {
      return false;
    }
    // Check if hours and minutes are within valid ranges
    if (hours < 1 || hours > 12 || minutes < 0 || minutes > 59) {
      return false;
    }

    // Check if am/pm is valid
    if (ampmLowerCase !== "am" && ampmLowerCase !== "pm") {
      return false;
    }

    // If all checks pass, the time is valid
    return true;
  }

  function formatDateTime(date) {
    // Format the date object as 'hh:mm:ss AM/PM'
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    let ampm = hours >= 12 ? "PM" : "AM";

    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")} ${ampm}`;
  }
  const locadDataOfConfiguration = async () => {
    if (
      cookies.get("active_session") &&
      cookies.get("active_session") == "yes"
    ) {
      try {
        setLoading(true);
        let arr = [];
        let params = {
          username: cookies.get("user_name"),
          user_id: cookies.get("user_id"),
          account_name: cookies.get("account_name"),
          account_id: cookies.get("account_id"),
          sid: cookies.get("sid"),
          baseurl: cookies.get("baseUrl"),
        };
        let result = await profileAPI.getConfigurationData({
          params: params,
        });
        if (result?.status) {
          setLoading(false);
          if (result?.data && result?.data?.data) {
            let finalData = JSON.parse(result?.data?.data);
            console.log(
              "maxCharRequestLocation",
              finalData?.maxCharRequestLocation
            );
            setMaximumRequestLocationCharts(finalData?.maxCharRequestLocation);
          }
          if (result && result?.data && result?.data?.data?.message) {
            if (
              result?.data?.data?.message?.text == "unauthorized to complete"
            ) {
              navigate("/redirect");
            }
          }
        } else {
          setLoading(false);
          toast.error(t("NETWORKMESSAGE.addCustomerMessageError"));
          if (result && result?.data && result?.data?.data?.message) {
            if (
              result?.data?.data?.message?.text == "unauthorized to complete"
            ) {
              navigate("/redirect");
            }
          }
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(error.message);
      }
    } else {
      // navigate('/redirect')
    }
  };
  const getData = async () => {
    if (!props?.object || !props.object.id) {
      toast.warn(t("REQUESTS.youMustSelectTask"));
      return;
    }
    let params = {
      username: cookies.get("user_name"),
      user_id: cookies.get("user_id"),
      account_name: cookies.get("account_name"),
      account_id: cookies.get("account_id"),
      sid: cookies.get("sid"),
      baseurl: cookies.get("baseUrl"),
    };
    try {
      let data = await requestAPI.comments({
        params: params,
        data: {
          task_id: props?.object?.task_id,
        },
      });
      if (data && data?.status) {
        setComments(data?.data?.data);
      } else {
        toast.warn(t("NETWORKMESSAGE.deleteErrorr"));
      }
    } catch (err) {
      console.log(err?.message);
    }
  };

  return (
    <PopupForm
      open={props.addForm}
      setOpen={props.setAddForm}
      title={
        props?.object?.task_id
          ? t("REQUESTS.updateTitle")
          : t("REQUESTS.addTitle")
      }
      isFullScreen={true}
      content={
        <RenderContent
          object={props?.object}
          phone={phone}
          task_id={props?.object?.task_id}
          editMode={props?.object?.task_id ? true : false}
          setPhone={setPhone}
          requestLocation={requestLocation}
          setRequestLocation={setRequestLocation}
          requestType={requestType}
          setRequestType={setRequestType}
          requestTypes={requestTypes}
          unit={unit}
          setUnit={setUnit}
          units={filteredUnits}
          setUnits={setUnits}
          allUnits={units}
          preferedDate={preferedDate}
          setPreferedDate={setPreferedDate}
          preferedTime={preferedTime}
          setPreferedTime={setPreferedTime}
          notes={notes}
          setNotes={setNotes}
          customerName={customerName}
          userName={userName}
          requestDate={requestDate}
          status={status}
          inprogressDateTime={inprogressDateTime}
          closeingDateTime={closeingDateTime}
          setImageList={setImageList}
          imageList={imageList}
          unitNameSearch={unitNameSearch}
          setUnitNameSearch={setUnitNameSearch}
          handleSearchInAddvancSearch={handleSearchInAddvancSearch}
          handleClearInAddvancSearch={handleClearInAddvancSearch}
          setListOfSelectedRows={setListOfSelectedRows}
          listOfSelectedRows={listOfSelectedRows}
          isSearchApplay={isSearchApplay}
          checkAllselected={checkAllselected}
          setCheckAllselected={setCheckAllselected}
          setCheckAllNotSelected={setCheckAllNotSelected}
          checkAllNotSelected={checkAllNotSelected}
          setObjError={setObjError}
          ObjError={ObjError}
          open={props.addForm}
          setOpen={props.setAddForm}
          isFullScreen={true}
          preferdDateFromDatabase={preferdDateFromDatabase}
          maximumRequestLocationCharts={maximumRequestLocationCharts}
          data={comments}
          setAddForm={props?.setAddForm}
        />
      }
      footer={
        <RenderFooter
          open={props.addForm}
          setOpen={props.setAddForm}
          submit={submit}
          clearForm={clearForm}
          isUploadingTime={isUploadingTime}
        />
      }
    />
  );
}
