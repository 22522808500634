import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import Preloading from "../Pages/Preload/Preloading";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PopupForm(props) {
  const [open, setOpen] = React.useState(false);
  const globalLoading = useSelector(
    (state) => state.settingsData.globalLoading
  );
  const screenwidth=useSelector(state=>state.settingsData.screenwidth)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <Box
      sx={{
        width: props?.isFullScreen ? "100%" : "50%",
        height: "auto",
      }}
    >
      <Dialog
        open={props?.open}
        // TransitionComponent={Transition}
        keepMounted
        onClose={props?.notClose ? null : handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          width: "100%",
          // height: screenwidth<=700?'100%':props?.customeHeight?props?.customeHeight:'auto',
          overflowY: "scroll !important",

          "& .MuiDialog-container": {
            alignItems: props?.isCenter
              ? "center !important"
              : "start !important",
          },
          "& .MuiPaper-root": {
            // top:'90px',
            width: "100%",
            height: "fit-content",
            minHeight: props?.isFullScreen
              ? "100% !important"
              : props?.customeHeight
              ? props?.customeHeight
              : "400px",
            maxWidth: props?.isFullScreen
              ? "100% !important"
              : props?.customeWidth
              ? props?.customeWidth
              : "50% !important",
            // position:'relative',
            "& .backgroundSvg": {
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              width: "100%",
              height: "100%",
              zIndex: 0,
              backgroundColor: "#1E6A99",
              clipPath:
                "polygon(60% 42%, 100% 0, 100% 100%, 75% 100%, 0 100%, 0 100%, 26% 73%)",
            },
          },
        }}
        // BackdropProps={{
        //     style: {
        //         background: 'rgba(255, 255, 255, 0.1)',
        //         backdropFilter: 'blur(3px)',
        //     }
        // }}
        fullScreen={props?.isFullScreen ? true : false}
      >
        {/* <Box
         className='backgroundSvg'
       >

       </Box> */}

        {!props?.loading ? (
          <>
            <DialogTitle
              sx={{
                position: "relative",
                display: "flex",
                alignSelf: "center",
                fontFamily: "Cairo-Bold",
                fontSize: "24px !important",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {props?.title}
              <br />
              {props?.hasSubTitle ? (
                <span
                  style={{
                    fontFamily: "Cairo-Light",
                    fontSize: "14px",
                    marginTop: "10px",
                    textAlign: "center",
                  }}
                >
                  {props?.subTitle}
                </span>
              ) : null}
              {props?.hasIconheader ? props?.iconHeader : null}
            </DialogTitle>
            <DialogContent>
              {globalLoading ? <Preloading dashboardMode={true} /> : null}
              {props?.content}
            </DialogContent>
            <DialogActions>{props?.footer}</DialogActions>
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}
          >
            <CircularProgress
              size={50}
              sx={{ fontSize: "70px", color: "#5e99f7" }}
            />
          </Box>
        )}
      </Dialog>
    </Box>
  );
}
