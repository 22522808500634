import { Box, TextField, Button } from '@mui/material'
import React, { useState, useEffect } from 'react'
import PopupForm from '../../Components/PopupForm'
import { useTranslation } from 'react-i18next'
import { ContainerOfInputFields } from '../../Components/ThemDesign'
import { useSelector } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close';
import StarsIcon from '@mui/icons-material/Stars';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import requestAPI from '../../Network/Request'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import profileAPI from '../../Network/Profile'
const cookies = new Cookies();


const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },

}));

const customIcons = {
  1: {
    icon: <StarIcon color="error" sx={{ fontSize: '25px', margin: '0 4px' }} />,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <StarIcon color="error" sx={{ fontSize: '30px', margin: '0 4px' }} />,
    label: 'Dissatisfied',
  },
  3: {
    icon: <StarIcon color="warning" sx={{ fontSize: '35px', margin: '0 4px' }} />,
    label: 'Neutral',
  },
  4: {
    icon: <StarIcon color="success" sx={{ fontSize: '40px', margin: '0 4px' }} />,
    label: 'Satisfied',
  },
  5: {
    icon: <StarIcon color="success" sx={{ fontSize: '45px', margin: '0 4px' }} />,
    label: 'Very Satisfied',
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

const RenderContent = (props) => {
  const [t] = useTranslation('common')
  const maintheme = useSelector(state => state.themeData.maintheme);
  const checkLength=(value,max)=>{
    if(value&&value?.length<max){
      return true;
    }
    return false;
  }
  const handleChange = (value, number, errName) => {
    if (number == 1&&checkLength(value.target.value,props?.maximumCommentNumber)) {
      props.setRatingNotes(value.target.value)
    } else if (number == 2&&checkLength(value.target.value,props?.maximumSuggestionNumber)) {
      props.setRatingSuggestions(value.target.value)
    }
  }
  const clearInput = (number, errName) => {
    if (number == 1) {
      props.setRatingNotes('')
    } else if (number == 2) {
      props.setRatingSuggestions('')
    }
  }

  return (
    <Box className='Container-fluid position-relative'>

      <Box className="row d-flex justify-content-center align-items-start">
        {
          !props?.ratingComplete ?
            <Box className="col-12 col-sm-12 col-md-12 col-lg-12 ">
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '30px',
                  '& .MuiRating-root': {
                    display: 'flex !important',
                    justifyContent: 'space-between !important',
                    alignItems: 'center !important',
                  }
                }}
              >
                <StyledRating
                  name="highlight-selected-only"
                  value={props?.ratingValue}
                  IconContainerComponent={IconContainer}
                  getLabelText={(value) => customIcons[value].label}
                  onChange={(event, newValue) => {
                    props.setRatingValue(newValue);
                  }}
                />
              </Box>
              <ContainerOfInputFields
                mainTheme={maintheme}
                customeWidth={"100%"}
                isForm={true}
              >
                <Box
                  sx={{
                    position: "relative",
                    // margin: "5px 0",
                    width: "100%",
                    backgroundColor: "#fff",
                  }}
                  className="mt-3 orderdata"
                >
                  <TextField
                    id="standard-basic"
                    variant="filled"
                    label={t("REQUESTS.add_ratingsNot")}
                    value={props?.ratingNotes}
                    onChange={(e) => handleChange(e, 1, 'ratingNotes')}
                    placeholder="..."
                    InputProps={{ inputProps: {min:0, max: props?.maximumCommentNumber } }}
                    multiline
                    rows={2}
                    type={'text'}
                  // focused={true}
                  />
                  {props?.ratingNotes ? (
                    <CloseIcon
                      className="closeIcon"
                      onClick={() => clearInput(1, 'ratingNotes')}
                    />
                  ) : null}
                </Box>

                <Box
                  sx={{
                    position: "relative",
                    // margin: "5px 0",
                    width: "100%",
                    backgroundColor: "#fff",
                  }}
                  className="mt-3 orderdata"
                >
                  <TextField
                    id="standard-basic"
                    variant="filled"
                    label={t("REQUESTS.add_rating_suggestions")}
                    value={props?.ratingSuggestions}
                    onChange={(e) => handleChange(e, 2, 'reatingSuggestions')}
                    placeholder="..."
                    InputProps={{ inputProps: { min:0,max: props?.maximumSuggestionNumber } }}
                    multiline
                    rows={2}
                    type={'text'}
                  // focused={true}
                  />
                  {props?.ratingSuggestions ? (
                    <CloseIcon
                      className="closeIcon"
                      onClick={() => clearInput(2, 'reatingSuggestions')}
                    />
                  ) : null}
                </Box>
              </ContainerOfInputFields>

            </Box> :
            <Box sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '18px',
              fontFamily: 'Cairo',
              letterSpacing: '-1px',
              flexDirection: 'column',

            }}>
              <Box sx={{
                width: '80px', height: '80px', borderRadius: '50%', border: '1px solid #4287f5',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '15px'
              }}>
                <StarIcon sx={{ fontSize: '70px', color: '#4287f5' }} />
              </Box>
              {props?.ratingValue == 1 ? <p style={{ width: '100%', textAlign: 'center' }}>{t('REQUESTS.maessage_after_rating_1')}</p> : null}
              {props?.ratingValue == 2 ? <p style={{ width: '100%', textAlign: 'center' }}>{t('REQUESTS.maessage_after_rating_2')}</p> : null}
              {props?.ratingValue == 3 ? <p style={{ width: '100%', textAlign: 'center' }}>{t('REQUESTS.maessage_after_rating_3')}</p> : null}
              {props?.ratingValue == 4 ? <p style={{ width: '100%', textAlign: 'center' }}>{t('REQUESTS.maessage_after_rating_4')}</p> : null}
              {props?.ratingValue == 5 ? <p style={{ width: '100%', textAlign: 'center' }}>{t('REQUESTS.maessage_after_rating_5')}</p> : null}
            </Box>
        }
      </Box>
    </Box>
  )
}

const RenderFooter = (props) => {
  const [t] = useTranslation('common')
  const gridtheme = useSelector(state => state.themeData.gridtheme)

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
    }}>


      <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', width: '100%' }}>
        <Box display="flex" >

          <Button variant="outlined"
            // spacing={2}
            sx={{
              width: '50% !important',
              // flexGrow: 1, 
              minWidth: '80px !important',
              maxWidth: '80px !important',
              margin: 1,
              backgroundColor: '#f7f7f7',
              borderColor: gridtheme?.colorWhite,
              color: gridtheme?.colorblack,
              boxShadow: '0 0 7px -2px white',
              //   color:SearchButtonTheme?.clear_button_text_color,

              '&:hover': {
                backgroundColor: '#f7f7f7',
                borderColor: gridtheme?.colorWhite,
                color: gridtheme?.colorblack,
                boxShadow: '0 0 7px 1px white',
                boxShadow: '0',
              },
              height: '35px',
              fontFamily: 'Cairo-Bold'

            }}
            className="iconeFilterClear"
            color="secondary"
            onClick={async() => {
              if(props?.ratingComplete){
                props.setOpen(false)
                props.setRatingComplete(false)
              }else{
                props?.clearForm()
              }
            }}
          >
            {props?.ratingComplete?t('GLOBAL.close'):t('GLOBAL.clear')}
          </Button>
          {
            !props.ratingComplete ?
              <Button
                variant="contained"
                // spacing={2} 
                sx={{
                  // flexGrow: 1,
                  margin: 1,
                  width: '80px !important',
                  minWidth: '80px !important',
                  maxWidth: '80px !important',
                  '&:hover': {
                    //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
                  },
                  height: '35px',
                  fontFamily: 'Cairo-Bold'
                }}
                className="iconeFilterSearch"
                onClick={() => {
                  props.submit()
                }}
              >
                {t('GLOBAL.Save')}
              </Button> : null
          }
        </Box>
      </Box>
    </Box>
  )
}


export default function AddRating(props) {
  const navigate = useNavigate()
  const [t] = useTranslation('common');
  const [loading, setLoading] = useState(false)
  const [taskId, setTaskId] = useState();
  const [ratingValue, setRatingValue] = useState('');
  const [ratingNotes, setRatingNotes] = useState('')
  const [ratingSuggestions, setRatingSuggestions] = useState('')
  const [messgaeAfterRating, setMesageAfterRating] = useState("1 شكرا لوقتك. نحن ناسف لتجربتك الغير جيدة. سوف يتم الاتصال بحضرتك لبحث سبب المشكلة والعمل على حلها في المستقبل2 شكرا لوقتك. نحن ناسف لتجربتك الغير جيدة. سوف يتم الاتصال بحضرتك لبحث سبب المشكلة والعمل على حلها في المستقبل3 شكرا لوقتك. على ما يبدوا ان تجربتك لم تكن ممتازة. سوف يتم مراجعة تقييمك والعمل على اصلاح الخلل4 شكرا لوقتك. نحن نعمل على تقديم أفضل الخدمات. نأمل بان نقدم لك خدمة أفضل في المستقبل5 شكرا لوقتك. نحن سعداء بخدمتك")
  const [ratingComplete, setRatingComplete] = useState(false)
  const [isRatingBefor, setIsRatingBefor] = useState(false)
  const [maximumCommentNumber,setMaximumCommentNumber]=useState(150)
  const [maximumSuggestionNumber,setMaximumSuggestionNumber]=useState(150)


  useEffect(() => {
    if(!props?.rateingValue?.value)
    clearForm()

    if (props?.object) {
      if(props?.object?.rate_score>0)
      setRatingValue(props?.object?.rate_score)
      setRatingNotes(props?.object?.rate_text)
      setRatingSuggestions(props?.object?.suggestions)
      setTaskId(props?.object?.task_id)
    }

    if(props?.rateingValue?.value){
      console.log('sadasdsada',props?.rateingValue?.value);
      setRatingValue(props?.rateingValue?.value)
    }
    if(props?.rateingValue){
      setIsRatingBefor(props?.rateingValue?.check)
      setRatingNotes(props?.rateingValue?.rate_text)
      setRatingSuggestions(props?.rateingValue?.suggestions)
    }
  }, [props?.object,props?.rateingValue])



  const submit = async () => {
    if (cookies.get('active_session') && cookies.get('active_session') == 'yes') {
      try {

        if(!ratingValue||ratingValue==0){
            toast.warn('يرجى اختيار التقييم')
            return false
        }

        setLoading(true)
        let params = {
          username: cookies.get('user_name'),
          user_id: cookies.get('user_id'),
          account_name: cookies.get('account_name'),
          account_id: cookies.get('account_id'),
          sid: cookies.get('sid'),
          baseurl: cookies.get('baseUrl')
        }
        let filterRateText=ratingNotes?ratingNotes?.replace(/\\|\/|\'/g,''):''
        let filterRateSuggestions=ratingSuggestions?ratingSuggestions?.replace(/\\|\/|\'/g,''):''
        let data = {
          rate_score: ratingValue,
          rate_text: filterRateText,
          suggestions: filterRateSuggestions,
          isRatingRequest:true,//to prevent global loading...
        }

        let result = null;
        if (props?.object?.task_id) {

          result = await requestAPI.UpdateRating({
            data: data,
            params: params,
            id: props?.object?.task_id,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          });
        }

        if (result?.status) {
           setLoading(false)
           if(result?.data&&!result?.data?.status){
            if((ratingNotes&&ratingNotes?.includes("'"))||(ratingSuggestions&&ratingSuggestions?.includes("'"))){
               toast.error("<<'>> لا يمكنك استخدام حرف ")
            }
            return false
           }


          toast.success(t('NETWORKMESSAGE.addCustomerMessageSuccess'))
          setRatingComplete(true)
          props?.loadData()
          if(result&&result?.data&&result?.data?.data?.message){
            if(result?.data?.data?.message?.text=="unauthorized to complete"){
               navigate('/redirect')
            }
          }
        } else {
          if (typeof result.errorMessage === "object") {
            //   let updatedObject = resetErrors(ErrorsObject)
            //  alert('asdasd')
            // console.log('message',result.errorMessage,updatedObject)

            //   setErrorsObject(checkErrors(result.errorMessage, updatedObject))   
          }
          setLoading(false)
          toast.error(t('NETWORKMESSAGE.addCustomerMessageError'))
          if(result&&result?.data&&result?.data?.data?.message){
            if(result?.data?.data?.message?.text=="unauthorized to complete"){
               navigate('/redirect')
            }
          }
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
        toast.error(error.message)
      }
    } else {
      navigate('/redirect')
    }
  }

  const clearForm = () => {
    setRatingSuggestions('')
    setRatingNotes('')
    setRatingValue(0)
    setRatingComplete(false)
  }
  useEffect(() => {
    if (!props?.object&&!props?.rateingValue?.value)
      clearForm()
    if(props?.object)
      locadDataOfConfiguration()
  }, [props?.addForm, props?.object])
  useEffect(() => {
    if(!props?.rateingValue?.value)
    setRatingValue(0)
    return () => {
      setRatingComplete(false)
    }
  }, [])
  const locadDataOfConfiguration=async()=>{
    if (cookies.get('active_session') && cookies.get('active_session') == 'yes') {
      try {
        setLoading(true)
        let arr = []
        let params = {
          username: cookies.get('user_name'),
          user_id: cookies.get('user_id'),
          account_name: cookies.get('account_name'),
          account_id: cookies.get('account_id'),
          sid: cookies.get('sid'),
          baseurl: cookies.get('baseUrl')
        }
        let result = await profileAPI.getConfigurationData({
          params: params
        });
        if (result?.status) {
          setLoading(false)
          if (result?.data && result?.data?.data){
            let finalData=JSON.parse(result?.data?.data)
            setMaximumCommentNumber(finalData?.maxCharComment)
            setMaximumSuggestionNumber(finalData?.maxCharSuggestions)
          }
          if(result&&result?.data&&result?.data?.data?.message){
            if(result?.data?.data?.message?.text=="unauthorized to complete"){
               navigate('/redirect')
            }
          }
        } else {
          setLoading(false)
          toast.error(t('NETWORKMESSAGE.addCustomerMessageError'))
          if(result&&result?.data&&result?.data?.data?.message){
            if(result?.data?.data?.message?.text=="unauthorized to complete"){
               navigate('/redirect')
            }
          }
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
        toast.error(error.message)
      }
    } else {
      // navigate('/redirect')
    }
  }
  useEffect(()=>{
    console.log('maxNumber1=>',maximumCommentNumber)
  },[maximumCommentNumber])
  useEffect(()=>{
    console.log('maxNumber2=>',maximumSuggestionNumber)
  },[maximumSuggestionNumber])

  return (
    <PopupForm
      open={props.addForm}
      setOpen={props.setAddForm}
      title={!ratingComplete ? props?.object ? t('REQUESTS.addRating') : t('REQUESTS.editRating') : null}
      subTitle={!ratingComplete ? "عزيزي العميل، ان تقييمك لخدماتنا يساعدنا على تحسين جودة العمل بهدف تقديم أفضل الخدمات لزبائننا وفق المعايير العالمية. سوف يتم مراجعة تقييمك من قبل قسم الجودة والتواصل معك عند الضرورة" : null}
      hasSubTitle={!ratingComplete ? true : false}
      isFullScreen={false}
      loading={loading}
      isCenter={true}
      customeHeight={'400px'}
      hasIconheader={!ratingComplete ? true : false}
      iconHeader={
      <Box>
        <StarsIcon sx={{
          position: 'absolute',
          top: '13px',
          left: '10px',
          fontSize: '40px',
          color: '#4287f5'
        }} />
        <Box
         sx={{
          width:'35px',
          height:'35px',
          borderRadius:'50%',
          position:'absolute',
          top:'15px',
          right:'20px',
          boxShadow:'0 0 10px -2px rgba(0,0,0,0.3)',
          display:'flex',
          alignItems:'center',
          justifyContent:'center',
          cursor:'pointer',
          zIndex:'211231'
         }}
         onClick={()=>{
          props?.setAddForm(false)
         }}
       >
           <CloseIcon sx={{
            fontSize:'30px',
            color:'#407ea4',
           }}/>
       </Box>
      </Box>
      }
      content={<RenderContent
        ratingValue={ratingValue}
        setRatingValue={setRatingValue}
        ratingComplete={ratingComplete}
        ratingNotes={ratingNotes}
        setRatingNotes={setRatingNotes}
        messgaeAfterRating={messgaeAfterRating}
        ratingSuggestions={ratingSuggestions}
        loading={loading}
        setRatingSuggestions={setRatingSuggestions}
        open={props.addForm}
        setOpen={props.setAddForm}
        isFullScreen={false}
        isRatingBefor={isRatingBefor}
        maximumCommentNumber={maximumCommentNumber}
        maximumSuggestionNumber={maximumSuggestionNumber}
      />}
      footer={<RenderFooter
        open={props.addForm}
        setOpen={props.setAddForm}
        ratingComplete={ratingComplete}
        setRatingComplete={setRatingComplete}
        submit={submit}
        loadData={props.loadData}
        clearForm={clearForm}
      />}
    />
  )
}
