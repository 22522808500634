import React, { useState, useEffect } from "react";
import Grid from "../Grid/Grid";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import DropDownGrid from "../Grid/Components/DropDownGrid";
import {
  Box,
  MenuItem,
  ListItemIcon,
  Typography,
  Divider,
  Autocomplete,
  TextField,
  Stack,
  styled,
  CircularProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import AddRequest from "./AddRequest";
import swal from "sweetalert";
import SearchInput from "../../Components/SearchInput";
import SearchDate from "../../Components/SearchDate";
// import EmployeeAPI from '../Global/Network/Employee/index'
import requestAPI from "../../Network/Request/index";

import { from } from "stylis";
import helpers from "../../assets/js/helper";
import { useGlobalContext } from "../../context";
import * as BsIcons from "react-icons/bs";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import * as BiIcons from "react-icons/bi";
import ImagePreview from "../../Components/ImagePreview";
import Cookies from "universal-cookie";
import ViewRequest from "./ViewRequest";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Rating from "@mui/material/Rating";
import AddRating from "./AddRating";
import StarsIcon from "@mui/icons-material/Stars";
import { Navigate, useLocation } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import $ from "jquery";
import statusAPI from "../../Network/Status/index";
import { useNavigate } from "react-router-dom";
import requestTypeAPI from "../../Network/RequestType";
import Preloading from "../../Pages/Preload/Preloading";
import { useMemo } from "react";
import ViewComment from "./ViewComment";
import CommentIcon from '@mui/icons-material/Comment';
import ShareIcon from '@mui/icons-material/Share';
import ShareTask from "./ShareTask";

const APP_CONSTANTS = {
  API_URL_IMG: `${process.env.REACT_APP_API_URL_image}`,
};
const cookies = new Cookies();

const RenderSearchContent = (props) => {
  const [t] = useTranslation("common");
  const location = useLocation();
  const navigate = useNavigate();

  // console.log('user', props?.users)
  const reatedOptions = [
    {
      id: 1,
      name: "مقيم",
      value: 1,
    },
    {
      id: 2,
      name: "غير مقيمة",
      value: 0,
    },
  ];

  const getTranslateStatus = (name) => {
    if (name == "new") return "جديد";
    else if (name == "in progress") return "قيد المعالجة";
    else if (name == "closed") return "مغلقة";
    else if (name == "rejected") return "ألغيت";
    else if (name == "approved") return "مكتملة";
    else if (name == "archived") return "مؤرشف";
    else if (name == "successful") return "مكتملة";
    else if (name == "canceled") return "ألغيت";
    else if (name == "failed") return "ألغيت";
    else return name;
  };
  const getStatusSelected = () => {
    let arr = [];
    props?.status &&
      props?.status?.length > 0 &&
      props?.status?.map((itm) => {
        if (itm?.status_id !== 5 && itm?.status_id !== 6) {
          arr.push(itm);
        }
      });
    return arr;
  };
  const getStatusListFIlter = () => {
    let arr = [];
    props?.unitList &&
      props?.unitList?.length > 0 &&
      props?.unitList?.map((itm) => {
        if (itm?.status_id !== 5 && itm?.status_id !== 6) {
          arr.push(itm);
        }
      });
    return arr;
  };
  // useEffect(()=>{
  //   console.log('asdasdasdasd',props?.ratedRequest)
  // },[props?.ratedRequest])

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100% !important",
      }}
    >
      <Autocomplete
        sx={{
          ml: 1.5,
          mt: 1,
          mb: 1,
          width: "95%",
          "& .MuiFormLabel-root,& .MuiInputBase-input": {
            fontFamily: "Cairo-Medium",
          },
        }}
        options={props?.unitList ? props?.unitList : []}
        getOptionLabel={(option) => option?.nm || ""}
        id="disable-close-on-select"
        value={props?.unitName}
        onChange={(e, v, r) => {
          props.setUnitName(v ? v : "");
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={t("REQUESTS.unitName")}
            variant="standard"
            sx={{ fontFamily: "Cairo-Medium" }}
          />
        )}
      />
      <Autocomplete
        sx={{
          ml: 1.5,
          mt: 1,
          mb: 1,
          width: "95%",
          "& .MuiFormLabel-root,& .MuiInputBase-input": {
            fontFamily: "Cairo-Medium",
          },
        }}
        options={props?.statusList ? props?.customeStatuses : []}
        getOptionLabel={(option) => getTranslateStatus(option?.name) || ""}
        // id="disable-close-on-select2"
        multiple
        value={props?.status ? getStatusSelected() : null}
        autoHighlight={false}
        autoSelect={false}
        onChange={(e, v, r) => {
          props.setStatus(v ? v : "");
          if (!v) {
            navigate(location.pathname, { replace: true });
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={t("REQUESTS.status")}
            variant="standard"
            sx={{ fontFamily: "Cairo-Medium" }}
          />
        )}
      />
      <Autocomplete
        sx={{
          ml: 1.5,
          mt: 1,
          mb: 1,
          width: "95%",
          "& .MuiFormLabel-root,& .MuiInputBase-input": {
            fontFamily: "Cairo-Medium",
          },
        }}
        options={reatedOptions ? reatedOptions : []}
        getOptionLabel={(option) => option?.name || ""}
        id="disable-close-on-select"
        value={props?.ratedRequest}
        onChange={(e, v, r) => {
          props.setRatedRequest(v ? v : "");
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={t("REQUESTS.ratedRequest")}
            variant="standard"
            sx={{ fontFamily: "Cairo-Medium" }}
          />
        )}
      />
      {/* <SearchInput
       value={props?.requestType}
       setValue={props?.setRequestType}
       title={t('REQUESTS.requestType')}
     /> */}
      <Autocomplete
        sx={{
          ml: 1.5,
          mt: 1,
          mb: 1,
          width: "95%",
          "& .MuiFormLabel-root,& .MuiInputBase-input": {
            fontFamily: "Cairo-Medium",
          },
        }}
        options={props?.requestTypes ? props?.requestTypes : []}
        getOptionLabel={(option) => option?.name || ""}
        id="disable-close-on-select"
        value={props?.requestType}
        multiple
        onChange={(e, v, r) => {
          props.setRequestType(v ? v : "");
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={t("REQUESTS.requestType")}
            variant="standard"
            sx={{ fontFamily: "Cairo-Medium" }}
          />
        )}
      />

      {/* <SearchInput
       value={props?.requestNumber}
       setValue={props?.setRequestNumber}
       title={t('REQUESTS.requestNumber')}
     />
 
     <SearchInput
       value={props?.accountName}
       setValue={props?.setAccountName}
       title={t('REQUESTS.accountName')}
     /> */}
      <SearchInput
        value={props?.userName}
        setValue={props?.setUserName}
        title={t("REQUESTS.accountUserName")}
      />

      <SearchDate
        value={props?.requestDate}
        setValue={props?.setRequestDate}
        title={t("REQUESTS.requestDate")}
      />
      <SearchDate
        value={props?.endRequestDate}
        setValue={props?.setEndRequestDate}
        title={t("REQUESTS.endrequestDate")}
      />
    </Box>
  );
};

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#ff6d75",
  },
  "& .MuiRating-iconHover": {
    color: "#ff3d47",
  },
});

export default function Request() {
  const { state } = useLocation();
  const [t] = useTranslation("common");
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [openFilterColumn, setOpenFilterColumn] = useState(false);
  const [openFilterColumnValue, setOpenFilterColumnValue] = useState([]);
  const [elementClicked, setElementClicked] = useState(null);
  const [addForm, setAddForm] = useState(false);
  const [selectedObject, setselectedObject] = useState(null);
  const [workTypeName, setWorkTypeName] = useState(null);
  const [createdBy, setCreatedBy] = useState(null);
  const [users, setUsers] = useState([]);
  const { exportToCSV } = useGlobalContext();
  const directions = useSelector((state) => state.settingsData.directions);
  const handlePageChange = (newPage) => {
    setPageNumber(newPage + 1);
  };
  const navigate = useNavigate();
  const controller = new AbortController();
  const [rowsTotal, setRowsTotal] = useState(0);
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);
  const [selectedImage, setSelectedImage] = useState(null);
  const [openImagePrivew, setOpenImagePrivew] = useState(false);

  const getStatusname = (id) => {
    if(id==1){
      return 'new'
    }else if(id==2){
      return "in progress"
    }else if(id==3){
      return "closed"
    }else if(id==4){
      return "rejected"
    }else if(id==5){
      return "approved"
    }else if(id==6){
      return "archived"
    }
  };
  const statusObject=state?.data?.status_id?[{
    id:state?.data?.status_id,
    name:getStatusname(state?.data?.status_id)
  }]:[]
  const [status, setStatus] = useState(statusObject);
  const [accountName, setAccountName] = useState(
    cookies.get("account_name") ? cookies.get("account_name") : null
  );
  const [accountUserName, setAccountUserName] = useState(
    cookies.get("user_name") ? cookies.get("user_name") : null
  );
  const [requestDate, setRequestDate] = useState(null);
  const [endRequestDate, setEndRequestDate] = useState(null);

  const [requestNumber, setRequestNumber] = useState("");
  const [unitName, setUnitName] = useState(null);
  const [requestType, setRequestType] = useState([]);
  const [accountUserId, setAccountUserId] = useState(
    cookies.get("user_id") ? cookies.get("user_id") : null
  );
  const [accountId, setAccountId] = useState(
    cookies.get("account_id") ? cookies.get("account_id") : null
  );
  const [authHash, setAuthHash] = useState(
    cookies.get("authHash") ? cookies.get("authHash") : null
  );
  const [sid, setSid] = useState(
    cookies.get("sid") ? cookies.get("sid") : null
  );
  const [baseUrl, setBseUrl] = useState(
    cookies.get("baseUrl") ? cookies.get("baseUrl") : null
  );
  const [userName, setUserName] = useState("");
  const [ratedRequest, setRatedRequest] = useState(null);
  const [showRating, setShowRating] = useState(false);
  const location = useLocation();
  const [rateScore, setRateScore] = useState(0);
  const [viewRequest, setViewRequest] = useState(false);
  const [isRatingFromGridMode, setIsRatingFromGridMode] = useState(false);
  const [statusList, setStatusList] = useState([]);
  const [unitList, setUnitList] = useState();
  const [closeStatusId, setCloseStatusId] = useState(null);
  const [customeStatuses, setCustomeStatuses] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [showComments, setShowComments] = useState(false);
  const [showShareTask,setShowShareTask]=useState(false)
  const globalLoading = useSelector(
    (state) => state.settingsData.globalLoading
  );
  const [requestTypes, setRequestTypes] = useState([]);

  // const [isExportFor]
  const [rateingValue, setRatingValue] = useState({
    value: null,
    task_id: null,
  });

  const getColor = (request) => {
    if (request?.status_id == 1) {
      return "rgba(97, 172, 232,.8)";
    }
    if (request?.status_id == 2) {
      return "#FDD835";
    }
    if (request?.status_id == 3) {
      if (request?.close_status_id == 1) {
        return request?.close_status_color;
      } else return "rgba(198, 40, 40,0.8)";
    }
    if (request?.status_id == 4) {
      return "rgba(198, 40, 40,0.8)";
    }
    if (request?.status_id == 5 && request?.close_status_id == 1) {
      return "rgb(0,191,61)";
    } else if (request?.status_id == 5 && request?.close_status_id != 1) {
      return "rgba(198, 40, 40,0.8)";
    }
    if (request?.status_id == 6) {
      return "rgba(27, 94, 32,0.8)";
    }
    return "lightgrey";
  };
  const getTranslateStatus = (name, request) => {
    if (name == "new") return "جديد";
    else if (name == "in progress") return "قيد المعالجة";
    else if (name == "closed") return "مغلقة";
    else if (name == "rejected") return "ألغيت";
    else if (name == "approved") {
      if (request?.status_id == 5 && request?.close_status_id == 1) {
        return "مكتملة";
      } else if (request?.status_id == 5 && request?.close_status_id == 2) {
        return "ألغيت";
      } else if (request?.status_id == 5 && request?.close_status_id == 3) {
        return "ألغيت";
      } else return "مكتملة";
    } else if (name == "archived") return "مؤرشف";
    else if (name == "successful") return "مكتملة";
    else if (name == "canceled") return "ألغيت";
    else if (name == "failed") return "ألغيت";
    else return name;
  };
  const submitEvalution = async (object) => {
    if (
      cookies.get("active_session") &&
      cookies.get("active_session") == "yes"
    ) {
      try {
        setLoading(true);
        let params = {
          username: cookies.get("user_name"),
          user_id: cookies.get("user_id"),
          account_name: cookies.get("account_name"),
          account_id: cookies.get("account_id"),
          sid: cookies.get("sid"),
          baseurl: cookies.get("baseUrl"),
        };

        let data = {
          rate_score: rateingValue?.value,
          // rate_text: ratingNotes,
          // suggestions: ratingSuggestions
        };

        let result = null;
        if (rateingValue?.task_id) {
          result = await requestAPI.UpdateRating({
            data: data,
            params: params,
            id: rateingValue?.task_id,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          });
        }

        if (result?.status) {
          setLoading(false);
          toast.success(t("NETWORKMESSAGE.addCustomerMessageSuccess"));
          // setRatingComplete(true)
          loadData();
          if (result && result?.data && result?.data?.data?.message) {
            if (
              result?.data?.data?.message?.text == "unauthorized to complete"
            ) {
              navigate("/redirect");
            }
          }
        } else {
          setLoading(false);
          toast.error(t("NETWORKMESSAGE.addCustomerMessageError"));
          if (result && result?.data && result?.data?.data?.message) {
            if (
              result?.data?.data?.message?.text == "unauthorized to complete"
            ) {
              navigate("/redirect");
            }
          }
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(error.message);
      }
    } else {
      navigate("/redirect");
    }
  };
  useEffect(() => {
    // if(rateingValue&&rateingValue?.value>0){
    //   submitEvalution()
    // }
    if (rateingValue?.value > 0) {
      setIsRatingFromGridMode(true);
      setShowRating(true);
    }
  }, [rateingValue]);
  const columns = useMemo(
    () => [
      { field: "id", headerName: "#", minWidth: 10, maxWidth: 45 },
      {
        field: "username",
        flex: 2,
        minWidth: 110,
        maxWidth: 150,
        hide: false,
        renderHeader: (rows) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "start",
              height: "60px",
              position: "relative",
              top: "3px",
            }}
            //  className='cutomeRenderHeader'
          >
            {/* <span style={{height:'18px',textAlign:'left'}} className="gridHeaderItem">{t('REQUESTS.accountName')}</span> */}
            <span
              style={{ height: "18px", textAlign: "left" }}
              className="gridHeaderItem"
            >
              {t("REQUESTS.accountUserName")}
            </span>
          </Box>
        ),
        renderCell: (rows) => (
          <Box
            sx={{
              display: "flex",
              // flexDirection:'column',
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <span className="gridHeaderItem">{rows?.row&&rows?.row?.account_name}</span> */}
              <span
                style={{ fontSize: "12px", color: "grey" }}
                className="gridHeaderItem"
              >
                {rows?.row && rows?.row?.user_name}
              </span>
            </Box>
          </Box>
        ),
      },
      {
        field: "date_and_request_number",
        flex: 1,
        minWidth: 120,
        maxWidth: 120,
        hide: false,
        renderHeader: (rows) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "start",
              height: "60px",
              top: "4px !important",
              position: "relative !important",
              left: "0 !important",
            }}
            //  className='cutomeRenderHeader'
          >
            <span style={{ height: "18px" }} className="gridHeaderItem">
              {t("REQUESTS.dateforRequest")}
            </span>
            {/* <span style={{height:'18px'}} className="gridHeaderItem">{t('EMPLOYEE.email')}</span> */}
          </Box>
        ),
        renderCell: (rows) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span
              className="gridHeaderItem"
              style={{
                textDecoration: "underline",
                fontWeight: "bold",
                color: "#00f",
                cursor: "pointer",
              }}
              onClick={() => ViewFunction(rows?.row)}
            >
              {rows?.row && rows?.row?.task_id}
            </span>
            <span className="gridHeaderItem">
              {rows?.row && rows?.row?.date_created
                ? helpers.formatDate(rows?.row?.date_created)
                : "0000-00-00"}
            </span>
          </Box>
        ),
      },
      {
        field: "request_type_and_device_number",
        flex: 1,
        minWidth: 110,
        maxWidth: 110,
        hide: false,
        renderHeader: (rows) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "start",
              height: "60px",
              position: "relative",
              top: "3px",
            }}
            //  className='cutomeRenderHeader'
          >
            <span style={{ height: "18px" }} className="gridHeaderItem">
              {t("REQUESTS.requestType")}
            </span>
            {/* <span style={{height:'18px'}} className="gridHeaderItem">{t('REQUESTS.unitName_details')}</span> */}
          </Box>
        ),
        renderCell: (rows) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span className="gridHeaderItem">
              {rows?.row && rows?.row?.request_type_name}
            </span>
            {rows?.row?.request_type_mandatory_units == 1 ? (
              <span
                className="gridHeaderItem ancorTage"
                style={{ cursor: "pointer" }}
                onClick={() => ViewFunction(rows?.row)}
              >
                {t("REQUESTS.unitName_details")}
              </span>
            ) : null}
          </Box>
        ),
      },
      {
        field: "date_and_favorite_time",
        flex: 1.5,
        minWidth: 150,
        maxWidth: 230,
        hide: false,
        renderHeader: (rows) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "start",
              height: "60px",
              top: "4px !important",
              position: "relative !important",
              left: "0 !important",
            }}
            //  className='cutomeRenderHeader'
          >
            <span style={{ height: "18px" }} className="gridHeaderItem">
              {t("REQUESTS.dateAndFavoriteTIme")}
            </span>
            {/* <span style={{height:'18px'}} className="gridHeaderItem">{t('REQUESTS.deviceNumber')}</span> */}
          </Box>
        ),
        renderCell: (rows) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {rows?.row?.prefered_date &&
            !rows?.row?.prefered_date?.includes("NaN") &&
            rows?.row?.prefered_date != "0000-00-00" ? (
              <span className="gridHeaderItem">
                {rows?.row && rows?.row?.prefered_date
                  ? helpers.formatDate(rows?.row?.prefered_date)
                  : "0000-00-00"}
              </span>
            ) : null}
            {rows?.row?.prefered_time &&
            !rows?.row?.prefered_time?.includes("NaN") &&
            rows?.row?.prefered_time != "00:00:00" &&
            rows?.row?.prefered_date != "0000-00-00" ? (
              <span className="gridHeaderItem">
                {rows?.row &&
                rows?.row?.prefered_time &&
                rows?.row?.prefered_date
                  ? helpers.formatTime(
                      helpers.formatDate(rows?.row?.prefered_date) +
                        " " +
                        rows?.row?.prefered_time,
                      true
                    )
                  : "00:00:00"}
              </span>
            ) : null}
            {(rows?.row?.prefered_time?.includes("NaN") &&
              rows?.row?.prefered_date?.includes("NaN")) ||
            (rows?.row?.prefered_date == "0000-00-00" &&
              rows?.row?.prefered_time == "00:00:00") ||
            rows?.row?.prefered_date == "0000-00-00" ? (
              <span className="gridHeaderItem">لم يتم التعيين</span>
            ) : null}
          </Box>
        ),
      },
      {
        field: "totlal_comments",
        flex: 1.5,
        minWidth: 150,
        maxWidth: 230,
        hide: false,
        renderHeader: (rows) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "start",
              height: "60px",
              top: "4px !important",
              position: "relative !important",
              left: "0 !important",
            }}
            //  className='cutomeRenderHeader'
          >
            <span style={{ height: "18px" }} className="gridHeaderItem">
              {t("REQUESTS.commentsCount")}
            </span>
          </Box>
        ),
        renderCell: (rows) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              // textDecoration: "underline",
              textAlign: "center",
              // color: "#00f",
              // cursor: "pointer",
              width: "100%",
            }}
            // onClick={() => {
            //   setselectedObject(rows?.row);
            //   setShowComments(true);
            // }}
          >
            {rows?.row?.total_comments}
          </Box>
        ),
      },
      {
        field: "rating",
        flex: 2,
        minWidth: 140,
        maxWidth: 200,
        hide: false,
        renderHeader: (rows) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "start",
              height: "60px",
              top: "4px !important",
              position: "relative !important",
              left: "0 !important",
            }}
            //  className='cutomeRenderHeader'
          >
            <span style={{ height: "18px" }} className="gridHeaderItem">
              {t("REQUESTS.rating")}
            </span>
            {/* <span style={{height:'18px'}} className="gridHeaderItem">{t('REQUESTS.deviceNumber')}</span> */}
          </Box>
        ),
        renderCell: (rows) => {
          // console.log(rows?.row?.rate_score);
          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                direction: "rtl",
                "& .MuiRating-root ": {
                  display: "flex",
                  flexDirection: "row-reverse",
                },
                "& .MuiSvgIcon-root": {
                  color: "#7FBBEB !important",
                },
              }}
            >
              {(rows?.row?.status_id == 3 && rows?.row?.close_status_id == 1) ||
              (rows?.row?.status_id == 5 && rows?.row?.close_status_id == 1) ? (
                <Rating
                  key={rows?.row?.task_id}
                  defaultValue={rows?.row?.rate_score}
                  value={rows?.row?.rate_score}
                  // readOnly={rows?.row?.rate_score!=null&&rows?.row?.rate_score>0?true:false}
                  onChange={(event, newValue) => {
                    setRatingValue({
                      value: newValue,
                      task_id: rows?.row?.task_id,
                      check:
                        rows?.row?.rate_score != null &&
                        rows?.row?.rate_score > 0
                          ? true
                          : false,
                      suggestions: rows?.row?.suggestions
                        ? rows?.row?.suggestions
                        : "",
                      rate_text: rows?.row?.rate_text
                        ? rows?.row?.rate_text
                        : "",
                    });

                    setselectedObject(rows?.row);
                  }}
                />
              ) : (
                <Rating
                  key={rows?.row?.task_id}
                  defaultValue={rows?.row?.rate_score}
                  readOnly
                />
              )}
            </Box>
          );
        },
      },
      {
        headerName: t("REQUESTS.status"),
        field: "active",
        flex: 1,
        minWidth: 110,
        hide: false,
        renderCell: (rows) => (
          <Box>
            <Box
              sx={{
                width: "100px",
                padding: "1px 4px",
                height: "30px",
                borderRadius: "2px",
                // border:'1px solid green',
                backgroundColor: getColor(rows?.row),
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <span style={{ color: "#fff" }}>
                {rows?.row?.status_id == 3
                  ? getTranslateStatus(rows?.row?.close_status_name, rows?.row)
                  : getTranslateStatus(rows?.row?.status_name, rows?.row)}
              </span>
              {/* <CheckCircleIcon sx={{color:'green',fill:'green'}}/> */}
            </Box>
          </Box>
        ),
      },
      {
        headerName: t("REQUESTS.actions"),
        field: "Actions",
        flex: 0.4,
        minWidth: 100,
        maxWidth: 250,
        hide: false,
        renderCell: (rows) => (
          <DropDownGrid>
            <MenuItem
              onClick={() => {
                ViewFunction(rows?.row);
              }}
              className="ActionIcons"
            >
              <ListItemIcon
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "0px 7px 0 0",
                }}
              >
                <RemoveRedEyeIcon style={{ fill: "#1E6A99" }} />
              </ListItemIcon>
              {t("GLOBAL.action_view")}
            </MenuItem>
            {(rows?.row?.status_id == 3 && rows?.row?.close_status_id == 1) ||
            (rows?.row?.status_id == 5 && rows?.row?.close_status_id == 1) ? (
              <MenuItem
                onClick={() => {
                  addRatingFunction(rows?.row);
                }}
                className="ActionIcons"
              >
                <ListItemIcon
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0px 7px 0 0",
                  }}
                >
                  <StarsIcon style={{ fill: "#dbeb05" }} />
                </ListItemIcon>
                {t("GLOBAL.action_rating")}
              </MenuItem>
            ) : null}
            {rows?.row?.status_id == 1 ? (
              <MenuItem
                onClick={() => {
                  updateFunction(rows?.row);
                }}
                className="ActionIcons"
              >
                <ListItemIcon
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0px 7px 0 0",
                  }}
                >
                  <EditIcon style={{ fill: "#1E6A99" }} />
                </ListItemIcon>
                {t("GLOBAL.action_edit")}
              </MenuItem>
            ) : null}
            {/* {((rows?.row?.status_id == 3 && rows?.row?.close_status_id == 1) ||
              (rows?.row?.status_id == 5 && rows?.row?.close_status_id == 1))&&rows?.row?.allow_send_email==1 ? ( */}
            {/* {((rows?.row?.status_id == 3 && rows?.row?.close_status_id == 1) ||
              (rows?.row?.status_id == 5 && rows?.row?.close_status_id == 1))&&rows?.row?.allow_send_email==1 ? ( */}
              <MenuItem
                onClick={() => {
                  shareTask(rows?.row);
                }}
                className="ActionIcons"
              >
                <ListItemIcon
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0px 7px 0 0",
                  }}
                >
                  <ShareIcon style={{ fill: "green" }} />
                </ListItemIcon>
                {t("GLOBAL.action_share")}
              </MenuItem>
            {/* ) : null} */}
          </DropDownGrid>
        ),
      },
    ],
    [rows]
  );
  const shareTask=(row)=>{
    setselectedObject(row)
    setShowShareTask(true)
  }
  useEffect(() => {
    // if (localStorage.getItem('isUseNavigation')||!state?.data) {
    console.log("load Data 3");
    console.log("render", 2);
    let filterDat = {};
    if (state?.data?.status_id) {
      const listOfStatuses = localStorage.getItem("listOfStatuses")
        ? JSON.parse(localStorage.getItem("listOfStatuses"))
        : [];
      let arr = [];
      listOfStatuses &&
        listOfStatuses?.length > 0 &&
        listOfStatuses?.map((itm) => {
          if (itm?.status_id == state?.data?.status_id) {
            arr.push(itm);
          }
          if (state?.data?.status_id == 3 && itm?.status_id == 5) {
            arr.push(itm);
          }
          if (state?.data?.status_id == 4 && itm?.status_id == 5) {
            arr.push(itm);
          }
        });
      setStatus(arr);

      let ids = [];
      let close_status_ids = [];
      arr?.length &&
        arr?.map((itm) => {
          ids.push(itm?.status_id);
        });
      if (state?.data?.status_id == 4) {
        close_status_ids.push(2);
        close_status_ids.push(3);
        if (!ids?.includes(3)) ids.push(3);
        if (!ids?.includes(5)) ids.push(5);
      }
      if (state?.data?.status_id == 3) {
        close_status_ids.push(1);
      }

      if (close_status_ids) {
        filterDat = {
          ...filterDat,
          close_status_ids: close_status_ids,
        };
      }
      filterDat = {
        ...filterDat,
        status_ids: ids,
      };
    }
    if (state?.data?.is_ratedMode) {
      setRatedRequest({
        id: 2,
        name: "غير مقيمة",
        value: 0,
      });
      filterDat = {
        ...filterDat,
        is_rated: 0,
      };
    }
    if (state?.data?.is_ratedMode && state?.data?.status_id == 3) {
      setCloseStatusId(1);
    }
    if (state?.data?.rate_score) {
      setRateScore(state?.data?.rate_score);
      filterDat = {
        ...filterDat,
        rate_score: state?.data?.rate_score,
      };
    }
    loadData(filterDat);
    // }
  }, [pageNumber, pageSize, state, state?.data, customeStatuses]);

  useEffect(() => {
    console.log("render", 1);
    if (!localStorage.getItem("isUseNavigation")) {
      localStorage.setItem("isUseNavigation", "done");
      if (state && state?.data && state.data?.is_createdMod) {
        createFUnction();
      }
    }
  }, [state]);

  useEffect(() => {
    // loadDataOfUsers()
    // loadData()
    getAllUnits();
    getAllStatus();
    getAllRequestTypes();
    // getAllRequestType()
  }, []);
  const removeDuplicates = (arr) => {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  };
  const loadData = async (searchParams) => {
    if (
      cookies.get("active_session") &&
      cookies.get("active_session") == "yes"
    ) {
      let object = {
        username: accountUserName,
        user_id: accountUserId,
        account_name: accountName,
        account_id: accountId,
        authHash: authHash,
        sid: sid,
        baseurl: baseUrl,
        offset: pageNumber - 1,
        itemsPerPage: pageSize,
        search: "",
        ...searchParams,
      };

      let statIds = [];
      status &&
        status?.length > 0 &&
        status?.map((itm) => {
          statIds.push(itm?.status_id);
        });
      if (statIds && statIds?.length > 0) {
        object?.status_ids?.map((id) => {
          statIds.push(id);
        });
        let removerDuplicatesFromArr = removeDuplicates(statIds);
        object = {
          ...object,
          status_ids: removerDuplicatesFromArr,
        };
      } else if (!object?.isRefresh) {
        object = {
          ...object,
          status_ids:
            statusList &&
            statusList?.map((itm) =>
              itm.status_id != 6 ? itm?.status_id : null
            ),
          close_status_ids: [1, 2, 3],
        };
      }
      //make combination from status_id and close_status_ids
      let status_combination_ids = [];
      object?.status_ids &&
        object?.status_ids?.map((sta) => {
          if (sta == 1 || sta == 2) {
            status_combination_ids?.push(`"${sta}-0"`);
          } else if (sta == 4) {
            status_combination_ids?.push(`"${sta}-0"`);
            status_combination_ids?.push(`"${sta}-1"`);
            status_combination_ids?.push(`"${sta}-2"`);
            status_combination_ids?.push(`"${sta}-3"`);
          } else
            object?.close_status_ids &&
              object?.close_status_ids?.map((cloSta) => {
                if (sta) status_combination_ids?.push(`"${sta}-${cloSta}"`);
              });
        });
      object = {
        ...object,
        status_combination_ids: status_combination_ids,
        status_ids: null,
        close_status_ids: null,
      };

      setLoading(true);

      console.log("searchParams", searchParams);
      const result = await requestAPI.Requests({
        params: {
          // page_size: pageSize,
          ...object,
        },
        signal: controller?.signal,
      });
      setLoading(false);
      // console.log('asdadasdaasdasdasdas', result)
      if (
        result.status &&
        result?.data &&
        result?.data?.data &&
        result?.data?.data?.data
      ) {
        // console.log('asdadasdaasdasdasdas', result?.data?.data?.data)
        // if (result?.data?.data?.data?.length)
        //   result?.data?.data?.data.sort((a, b) => (a.task_id > b.task_id) ? 1 : ((b.task_id > a.task_id) ? -1 : 0));

        let arr = [];
        result?.data?.data?.data?.length > 0 &&
          result?.data?.data?.data?.map((itm, index) => {
            arr.push({
              ...itm,
              id: index + (pageNumber - 1) * pageSize + 1,
            });
          });
        // let finalArray = []
        // arr?.length > 0 && arr?.map((itm, index) => {
        //     finalArray.push({
        //       ...itm,
        //       id: index +((pageNumber-1)*pageSize)+1,
        //     })
        // })

        setRows(arr);
        setRowsTotal(result?.data?.data?.total);
        setPageCount(Math.ceil(result?.data?.data?.total / pageSize));
      } else {
        // alert('3')
        if (!result?.data?.error) {
          toast.error(t("NETWORKMESSAGE.addCustomerMessageError"));
          return;
        }
        if (result && result?.data && result?.data?.data?.message) {
          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }
        }
      }
      setLoading(false);
    } else {
      navigate("/redirect");
    }
  };
  const loadDataOfUsers = async (searchParams) => {
    // setLoading(true)
    // const result = await EmployeeAPI.EmployeeList({
    //    signal: controller?.signal
    // })
    // setLoading(false)
    // if (result.status) {
    //    setUsers(result?.data)
    // }
    // else {
    //    if(!result?.data?.error)
    //    {
    //     toast.error(t('NETWORKMESSAGE.AddRequestMessageError'))
    //     return
    //    }
    // }
  };
  const createFUnction = () => {
    setselectedObject(null);
    setAddForm(true);
  };
  const updateFunction = (obj) => {
    if (obj?.status_id == 1) {
      setselectedObject(obj);
      setAddForm(true);
    }
  };
  const ViewFunction = (obj) => {
    setselectedObject(obj);
    setViewRequest(true);
  };
  const addRatingFunction = (obj) => {
    setselectedObject(obj);
    setShowRating(true);
  };
  const deleteFunction = async (obj) => {
    swal({
      title: "?هل انت متئكد",
      text: "لن تتمكن من التراجع عن هذا!",
      icon: "warning",
      buttons: ["الغاء!", "نعم!"],
      dangerMode: true,
    }).then(async (willDelete) => {
      // console.log('1')
      if (willDelete) {
        // console.log('2')

        // try{
        //     setLoading(true)

        //     let result=null;
        //     if(obj&&obj?.id){
        //            console.log('3')

        //         result=await EmployeeAPI.DeleteEmployee({
        //         data:{
        //           id:obj.id,
        //         }
        //         });
        //     }

        //     if(result?.status){
        //       setLoading(false)
        // toast.success(t('NETWORKMESSAGE.deleteSuccess'))
        swal(`${t("NETWORKMESSAGE.deleteSuccess")}`, {
          icon: "success",
        });
        //       loadData()
        //     }else{
        //       setLoading(false)
        //       toast.error(t('NETWORKMESSAGE.deleteErrorr'))
        //     }
        //     setLoading(false)
        // }catch(error){
        //     setLoading(false)
        //     toast.error(t(error?.message))
        // }
      } else {
        // alert('canceled')
      }
    });
  };
  const clearFunction = () => {
    setUnitName("");
    // setAccountName('')
    // setAccountUserName('')
    setRequestDate(null);
    setRequestNumber("");
    setStatus([]);
    setCreatedBy(null);
    setEndRequestDate(null);
    setRequestType([]);
    setUserName("");
    setRatedRequest(null);
    setCloseStatusId(null);
    setRateScore(null);
    navigate(location.pathname, { replace: true });
  };
  const searchFunction = () => {
    let data = {};

    if (unitName) {
      data = {
        ...data,
        unit_name: unitName?.nm,
      };
    }
    if (userName) {
      data = {
        ...data,
        user_name: userName,
      };
    }
    // if(requestNumber){
    //   data={
    //    ...data,
    //    request_number:requestNumber
    //   }
    // }
    if (requestDate) {
      data = {
        ...data,
        start_date: helpers.formatDate(requestDate),
      };
    }
    if (endRequestDate) {
      data = {
        ...data,
        end_date: helpers.formatDate(endRequestDate),
      };
    }
    if (status && status?.length > 0) {
      let ids = [];
      status?.map((itm) => {
        ids.push(itm?.status_id);
      });
      let check = false;
      let close_status_ids = [];
      if (ids && ids?.length > 0) {
        check = ids?.includes(3);
        if (check && !ids?.includes(5)) ids.push(5);

        if (check) {
          close_status_ids.push(1);
        }

        if (ids?.includes(4)) {
          close_status_ids.push(2);
          close_status_ids.push(3);
          if (!ids?.includes(3)) ids.push(3);
          if (!ids?.includes(5)) ids.push(5);
        }
      }

      if (close_status_ids) {
        data = {
          ...data,
          close_status_ids: close_status_ids,
        };
      }
      data = {
        ...data,
        status_ids: ids,
      };
    }

    if (ratedRequest) {
      data = {
        ...data,
        is_rated: ratedRequest?.value ? ratedRequest?.value : 0,
      };
    }
    if (requestType && requestType?.length) {
      data = {
        ...data,
        request_type_ids: requestType?.map((itm) => itm?.id),
      };
    }
    if (createdBy && createdBy?.id) {
      data = {
        ...data,
        created_by_id: createdBy?.id,
      };
    }

    console.log("load Data 4");
    console.log("render", 4);

    loadData(data);
  };
  const loadDataForExport = async (searchParams) => {
    setLoading2(true);
    let object = {
      ...searchParams,
    };

    if (object?.status_ids && object?.status_ids?.length > 0) {
      let removerDuplicatesFromArr = removeDuplicates(object?.status_ids);
      object = {
        ...object,
        status_ids: removerDuplicatesFromArr,
      };
    } else {
      object = {
        ...object,
        status_ids:
          statusList &&
          statusList?.map((itm) =>
            itm.status_id != 6 ? itm?.status_id : null
          ),
      };
    }

    let status_combination_ids = [];
    object?.status_ids &&
      object?.status_ids?.map((sta) => {
        if (sta == 1 || sta == 2) {
          status_combination_ids?.push(`"${sta}-0"`);
        } else if (sta == 4) {
          status_combination_ids?.push(`"${sta}-0"`);
          status_combination_ids?.push(`"${sta}-1"`);
          status_combination_ids?.push(`"${sta}-2"`);
          status_combination_ids?.push(`"${sta}-3"`);
        } else
          object?.close_status_ids &&
            object?.close_status_ids?.map((cloSta) => {
              if (sta) status_combination_ids?.push(`"${sta}-${cloSta}"`);
            });
      });
    object = {
      ...object,
      status_combination_ids: status_combination_ids,
      status_ids: null,
      close_status_ids: null,
    };
    const result = await requestAPI.Requests({
      params: {
        // page_size: rowsTotal,
        username: accountUserName,
        user_id: accountUserId,
        account_name: accountName,
        account_id: accountId,
        authHash: authHash,
        sid: sid,
        baseurl: baseUrl,
        // offset: pageNumber - 1,
        itemsPerPage: rowsTotal,
        search: "",
        ...object,
      },
      signal: controller?.signal,
    });
    // setLoading(false)
    if (
      result.status &&
      result?.data &&
      result?.data?.data &&
      result?.data?.data?.data
    ) {
      setLoading2(false);
      if (result?.data?.data?.data?.length) {
        let arr = [];
        result?.data?.data?.data?.map((itm, index) => {
          arr.push({
            ...itm,
            id: index + (pageNumber - 1) * pageSize + 1,
          });
        });
        return arr;
      }
    } else {
      setLoading2(false);

      if (result && result?.data && result?.data?.data?.message) {
        if (result?.data?.data?.message?.text == "unauthorized to complete") {
          navigate("/redirect");
        }
      }
    }
  };
  const ExportFunction = async () => {
    let filteredData = {};

    if (unitName) {
      filteredData = {
        ...filteredData,
        unit_name: unitName?.nm,
      };
    }
    if (userName) {
      filteredData = {
        ...filteredData,
        user_name: userName,
      };
    }
    // if(requestNumber){
    //   filteredData={
    //    ...filteredData,
    //    request_number:requestNumber
    //   }
    // }
    if (requestDate) {
      filteredData = {
        ...filteredData,
        start_date: helpers.formatDate(requestDate),
      };
    }
    if (endRequestDate) {
      filteredData = {
        ...filteredData,
        end_date: helpers.formatDate(endRequestDate),
      };
    }
    if (status && status?.length > 0) {
      console.log("vnasnds", status);
      let ids = [];
      let close_status_ids = [];
      status?.map((itm) => {
        ids.push(itm?.status_id);
      });

      let check = false;
      if (ids && ids?.length > 0) {
        check = ids?.includes(3);
        if (check && !ids?.includes(5)) ids.push(5);
        if (check) {
          close_status_ids.push(1);
        }

        if (ids?.includes(4)) {
          close_status_ids.push(2);
          close_status_ids.push(3);
          if (!ids?.includes(3)) ids.push(3);
          if (!ids?.includes(5)) ids.push(5);
        }
      }

      if (close_status_ids) {
        filteredData = {
          ...filteredData,
          close_status_ids: close_status_ids,
        };
      }

      filteredData = {
        ...filteredData,
        status_ids: ids,
      };
    }
    if (ratedRequest) {
      filteredData = {
        ...filteredData,
        is_rated: ratedRequest?.value,
      };
    }
    if (closeStatusId == 1) {
      filteredData = {
        ...filteredData,
        close_status_id: closeStatusId,
      };
    }
    if (rateScore) {
      filteredData = {
        ...filteredData,
        rate_score: rateScore,
      };
    }

    if (requestType && requestType?.length > 0) {
      filteredData = {
        ...filteredData,
        request_type_ids: requestType?.map((itm) => itm?.id),
      };
    }
    if (createdBy && createdBy?.id) {
      filteredData = {
        ...filteredData,
        created_by_id: createdBy?.id,
      };
    }

    const fetchedData = await loadDataForExport(filteredData);
    if (!fetchedData) {
      toast.error("لا يمكن استرجاع البيانات");
      return;
    }

    let arr = [];
    fetchedData &&
      fetchedData?.map((itm) => {
        let preDate = itm?.prefered_date;
        let preTime = itm?.prefered_time;
        if ((preDate && preDate == "0000-00-00") || !preDate) {
          preDate = "لم يتم التعيين";
        } else {
          preDate = helpers.formatDate(preDate);
        }

        if ((preTime && preTime == "00:00:00") || !preTime || !preDate) {
          preTime = "لم يتم التعيين";
        } else {
          preTime = helpers.formatTime(
            helpers.formatDate(preDate) + " " + preTime
          );
        }

        arr.push({
          "رقم الطلب": itm?.task_id,
          الحالة:
            itm?.status_id == 3
              ? getTranslateStatus(itm?.close_status_name, itm)
              : getTranslateStatus(itm?.status_name, itm),
          التقييم: itm?.rate_score ? itm?.rate_score : 0,
          الملاحضات: itm?.rate_text,
          الاقتراحات: itm?.suggestions,
          "اسم المستخدم": itm?.user_name,
          "اسم الزبون": itm?.account_name,
          "نوع الطلب": itm?.request_type_name,
          "الوقت المفضل للطلب": preDate,
          "التاريخ المفضل للطلب": preTime,
          "تاريخ الانشاء": itm?.date_created,
        });
      });

    exportToCSV(arr, "Tasks");
  };
  const handleEWvalutionsRequest = () => {
    setRatedRequest({
      id: 2,
      name: "غير مقيمة",
      value: 0,
    });
    const listOfStatuses = localStorage.getItem("listOfStatuses")
      ? JSON.parse(localStorage.getItem("listOfStatuses"))
      : [];
    listOfStatuses &&
      listOfStatuses?.length > 0 &&
      listOfStatuses?.map((itm) => {
        if (itm?.status_id == 3) {
          setStatus([itm]);
        }
      });
    setCloseStatusId(1);

    loadData({
      is_rated: 0,
      status_ids: [3, 5],
      close_status_ids: [1],
      isRefresh: true,
    });
  };
  //get all units from wialon her...
  const getAllUnits = () => {
    // console.log('entered_here')
    if (
      cookies.get("active_session") &&
      cookies.get("active_session") == "yes"
    ) {
      // console.log('entered_here2')

      const newSid = cookies.get("sid");
      $.ajax({
        type: "GET",
        enctype: "application/json",
        processData: !1,
        contentType: !1,
        crossDomain: true,
        dataType: "jsonp",
        url:
          cookies.get("baseUrl") +
          '/wialon/ajax.html?svc=core/search_items&params={"spec":{"itemsType":"avl_unit","propName":"sys_name","propValueMask":"*","sortType":"sys_name","propType":"property"},"force":0,"flags":1,"from":0,"to":0}&sid=' +
          newSid,
        success: function(data) {
          // console.log('allunitsdataشسيش', data);
          setUnitList(data?.items?.length > 0 ? data?.items : []);
        },
        error: function(err) {
          console.log("allunitsdataشسيش", err);
        },
      });
    } else {
      navigate("/");
    }
  };
  //get all requestTypes here..
  const getAllStatus = async () => {
    // console.log('asdasdasdasdasd');
    if (
      cookies.get("active_session") &&
      cookies.get("active_session") == "yes"
    ) {
      try {
        setLoading(true);
        let arr = [];
        let params = {
          username: cookies.get("user_name"),
          user_id: cookies.get("user_id"),
          account_name: cookies.get("account_name"),
          account_id: cookies.get("account_id"),
          sid: cookies.get("sid"),
          baseurl: cookies.get("baseUrl"),
        };
        let result = await statusAPI.Status({
          params: params,
        });
        if (result?.status) {
          setLoading(false);
          if (result?.data && result?.data?.data) {
            let arr = [];
            result?.data?.data?.length > 0 &&
              result?.data?.data?.map((itm) => {
                if (itm?.status_id != 5 && itm?.status_id != 6) {
                  arr?.push(itm);
                }
              });
            setStatusList(result?.data?.data);
            setCustomeStatuses(arr);
          }

          localStorage.setItem(
            "listOfStatuses",
            JSON.stringify(result?.data?.data)
          );
          // toast.success(t('NETWORKMESSAGE.addCustomerMessageSuccess'))
          if (result && result?.data && result?.data?.data?.message) {
            if (
              result?.data?.data?.message?.text == "unauthorized to complete"
            ) {
              navigate("/redirect");
            }
          }
        } else {
          setLoading(false);
          toast.error(t("NETWORKMESSAGE.addCustomerMessageError"));
          if (result && result?.data && result?.data?.data?.message) {
            if (
              result?.data?.data?.message?.text == "unauthorized to complete"
            ) {
              navigate("/redirect");
            }
          }
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(error?.message);
      }
    } else {
      navigate("/redirect");
    }
  };
  const getAllRequestTypes = async () => {
    if (
      cookies.get("active_session") &&
      cookies.get("active_session") == "yes"
    ) {
      try {
        setLoading(true);
        let arr = [];
        let params = {
          username: cookies.get("user_name"),
          user_id: cookies.get("user_id"),
          account_name: cookies.get("account_name"),
          account_id: cookies.get("account_id"),
          sid: cookies.get("sid"),
          baseurl: cookies.get("baseUrl"),
        };
        let result = await requestTypeAPI.RequestTypes({
          params: params,
        });
        if (result?.status) {
          setLoading(false);
          if (result?.data && result?.data?.data) {
            // let arr=[]
            // result?.data?.data&&result?.data?.data?.length>0&&result?.data?.data?.map((itm,index)=>{
            //      arr.push({
            //       ...itm,
            //       index:index,
            //      })
            // })
            setRequestTypes(result?.data?.data);
          }
          // toast.success(t('NETWORKMESSAGE.addCustomerMessageSuccess'))
          if (result && result?.data && result?.data?.data?.message) {
            if (
              result?.data?.data?.message?.text == "unauthorized to complete"
            ) {
              navigate("/redirect");
            }
          }
        } else {
          setLoading(false);
          toast.error(t("NETWORKMESSAGE.addCustomerMessageError"));
          if (result && result?.data && result?.data?.data?.message) {
            if (
              result?.data?.data?.message?.text == "unauthorized to complete"
            ) {
              navigate("/redirect");
            }
          }
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(error.message);
      }
    } else {
      // navigate('/redirect')
    }
  };

  useEffect(() => {
    if (!showRating && selectedObject) {
      loadData();
    }
  }, [showRating]);

  //   //get all requestTypes here..
  // const getAllRequestType = async () => {
  //     // console.log('asdasdasdasdasd');
  //     if (cookies.get('active_session') && cookies.get('active_session') == 'yes') {
  //       try {
  //         setLoading(true)
  //         let arr = []
  //         let params = {
  //           username: cookies.get('user_name'),
  //           user_id: cookies.get('user_id'),
  //           account_name: cookies.get('account_name'),
  //           account_id: cookies.get('account_id'),
  //           sid: cookies.get('sid'),
  //           baseurl: cookies.get('baseUrl')
  //         }
  //         let result = await requestTypeAPI.GetRequesTypestList({
  //           params: params
  //         });
  //         if (result?.status) {
  //           setLoading(false)
  //           if (result?.data && result?.data?.data)
  //             setRequestTypes(result?.data?.data)
  //           localStorage.setItem('listOfStatuses', JSON.stringify(result?.data?.data))
  //           // toast.success(t('NETWORKMESSAGE.addCustomerMessageSuccess'))
  //           if (result && result?.data && result?.data?.data?.message) {
  //             if (result?.data?.data?.message?.text == "unauthorized to complete") {
  //               navigate('/redirect')
  //             }
  //           }
  //         } else {
  //           setLoading(false)
  //           toast.error(t('NETWORKMESSAGE.addCustomerMessageError'))
  //           if (result && result?.data && result?.data?.data?.message) {
  //             if (result?.data?.data?.message?.text == "unauthorized to complete") {
  //               navigate('/redirect')
  //             }
  //           }
  //         }
  //         setLoading(false)
  //       } catch (error) {
  //         setLoading(false)
  //         toast.error(error?.message)
  //       }
  //     } else {
  //       navigate('/redirect')
  //     }
  //   }

  return (
    <Box
      sx={{
        // padding: '10px',
        // paddingTop: '20px',
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "start",
        overflowX: "hidden",
        "& .MuiChip-label": {
          fontFamily: "Cairo-Medium",
        },
      }}
    >
      {globalLoading && !addForm && !viewRequest && !showRating ? (
        <Preloading dashboardMode={true} />
      ) : null}
      {showShareTask ? (
        <ShareTask
          object={selectedObject}
          addForm={showShareTask}
          setAddForm={setShowShareTask}
          loadData={loadData}
        />
      ) : null}
      {showComments ? (
        <ViewComment
          object={selectedObject}
          addForm={showComments}
          setAddForm={setShowComments}
          loadData={loadData}
        />
      ) : null}
      {addForm ? (
        <AddRequest
          object={selectedObject}
          addForm={addForm}
          setAddForm={setAddForm}
          loadData={loadData}
        />
      ) : null}
      {viewRequest ? (
        <ViewRequest
          object={selectedObject}
          addForm={viewRequest}
          setAddForm={setViewRequest}
          loadData={loadData}
        />
      ) : null}
      {showRating ? (
        <AddRating
          object={selectedObject}
          addForm={showRating}
          setAddForm={setShowRating}
          loadData={loadData}
          rateingValue={rateingValue}
          isRatingFromGridMode={isRatingFromGridMode}
        />
      ) : null}

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "stretch",
          // backgroundColor: '#fff',
          position: "relative",
          // top:'-25px'
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            margin: "0 10px",
          }}
        >
          <Box
            sx={{
              marginRight: "10px",
              backgroundColor: "#1E6A99",
              color: "#FFFFFF",
              width: "45px",
              height: "45px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={createFUnction}
            id="demo-customized-button"
            aria-controls={
              openFilterColumn ? "demo-customized-menu" : undefined
            }
            aria-haspopup="true"
            aria-expanded={openFilterColumn ? "true" : undefined}
            variant="contained"
          >
            {/* <ViewWeekIcon sx={{color:'#fffff'}}/> */}
            <Tooltip title={<span style={{ fontFamily: "Cairo" }}>أنشاء</span>}>
              <AddIcon
                sx={{
                  color: "#fff",
                  fontSize: "40px",
                }}
              />
            </Tooltip>
          </Box>
          <Box
            sx={{
              marginRight: "10px",
              backgroundColor: "#878703",
              color: "#FFFFFF",
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={handleEWvalutionsRequest}
            id="demo-customized-button"
            aria-controls={
              openFilterColumn ? "demo-customized-menu" : undefined
            }
            aria-haspopup="true"
            aria-expanded={openFilterColumn ? "true" : undefined}
            variant="contained"
          >
            {/* <ViewWeekIcon sx={{color:'#fffff'}}/> */}
            <Tooltip
              title={
                <span style={{ fontFamily: "Cairo" }}>
                  عرض الطلبات الغير مقيمة
                </span>
              }
            >
              <StarsIcon
                sx={{
                  color: "#fff",
                  fontSize: "25px",
                }}
              />
            </Tooltip>
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              marginLeft: "10px",
              marginBottom: "10px",
              fontFamily: "Cairo-Bold",
              fontSize: "23px",
              letterSpacing: "-1px",
            }}
          >
            {t("REQUESTS.title")}
          </Typography>
        </Box>

        <Box
          sx={{
            marginRight: "20px",
            backgroundColor: "#1E6A99",
            color: "#FFFFFF",
            width: "46px",
            height: "40px",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
          // onClick={(event)=>{
          //   setOpenFilterColumn(true)
          //   setElementClicked(event.currentTarget)
          // }}
          id="demo-customized-button"
          aria-controls={openFilterColumn ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openFilterColumn ? "true" : undefined}
          variant="contained"
        >
          {/* <ViewWeekIcon sx={{color:'#fffff'}}/> */}
          <BiIcons.BiGitPullRequest
            style={{
              color: "#fff",
              fontSize: "25px",
            }}
          />
        </Box>
      </Box>
      {loading2 ? (
        <Box
          sx={{
            backgroundColor: "rgba(0,0,0,0.5)",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2342342342,
          }}
        >
          <CircularProgress size={40} sx={{ color: "#fff" }} />
        </Box>
      ) : null}

      <Divider
        sx={{
          backgroundColor: "#EBF2F7",
          margin: "30px",
          marginTop: "10px",
          width: "100%",
        }}
        style={{
          height: "3px",
        }}
      />

      <Grid
        rows={rows}
        columns={columns}
        // setColumns={setColumns}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        loading={loading}
        handlePageChange={handlePageChange}
        rowsTotal={rowsTotal}
        checkScreenSize={screenwidth}
        pageCount={pageCount}
        openFilterColumn={openFilterColumn}
        elementClicked={elementClicked}
        setOpenFilterColumn={setOpenFilterColumn}
        setColumnFilterValue={setOpenFilterColumnValue}
        columFilterValue={openFilterColumnValue}
        clearFunction={clearFunction}
        searchFunction={searchFunction}
        creatFunction={createFUnction}
        ExportFunction={ExportFunction}
        importFunction={null}
        mapFunction={null}
        telegramFunction={null}
        hasCreate={true}
        hasImport={false}
        hasExport={true}
        hasMap={false}
        hasTetelgram={false}
        filterChilds={
          <RenderSearchContent
            accountName={accountName}
            setAccountName={setAccountName}
            accountUserName={accountUserName}
            setAccountUserName={setAccountUserName}
            unitName={unitName}
            setUnitName={setUnitName}
            requestDate={requestDate}
            setRequestDate={setRequestDate}
            requestNumber={requestNumber}
            setRequestNumber={setRequestNumber}
            requestType={requestType}
            setRequestType={setRequestType}
            endRequestDate={endRequestDate}
            setEndRequestDate={setEndRequestDate}
            ratedRequest={ratedRequest}
            setRatedRequest={setRatedRequest}
            userName={userName}
            setUserName={setUserName}
            status={status}
            setStatus={setStatus}
            statusList={statusList}
            createdBy={createdBy}
            setCreatedBy={setCreatedBy}
            users={users}
            unitList={unitList}
            setRequestTypes={setRequestTypes}
            requestTypes={requestTypes}
            setCustomeStatuses={setCustomeStatuses}
            customeStatuses={customeStatuses}
          />
        }
      />
      <ImagePreview
        open={openImagePrivew}
        setOpen={setOpenImagePrivew}
        src={selectedImage}
      />
    </Box>
  );
}
