import React from 'react'
import { useEffect, useState } from 'react';
import {  useLocation } from 'react-router-dom';
import {  Box, Typography } from '@mui/material';
import $ from 'jquery'
import Cookies from "universal-cookie";
import axios from 'axios';
const cookies = new Cookies();

function Tasks(props) {
    const [loading, setLoading] = useState(true)
    const [tasks, setTasks] = useState([])
    

    useEffect(() => {
        const baseUrl = cookies.get('baseUrl')
        const sid = cookies.get('sid')
        const user_id = cookies.get('user_id')
        const user_name = cookies.get('user_name')
        const account_id = cookies.get('user_id')
        const account_name = cookies.get('account_name')

        console.log("in");
        const params={
            username:user_name,
            user_id:user_id,
            account_name:account_name,
            account_id:account_id,
            sid:sid,
            baseurl:baseUrl
        }
        console.log(params);
         axios({
            url: 'http://localhost:3001/wialon_customers/tasks',
            method: "GET",
            // headers: headers,
            data: {},
            params:params

        })
            .then(response => {
                if(response?.status)
                {
                    setTasks(response?.data?.data?.data)
                }
                setLoading(false)
            }).catch((reason)=>{
                console.log("reason",reason);
            })

        return () => {

        }
    }, [])

    return (
        <Box sx={{
            backgroundColor: 'red',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Typography>
                Tasks
            </Typography>

            <Box sx={{
                backgroundColor: 'red',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                
                {tasks?.map((task, index) => {
                    return <Box key={index}>
                        <Typography>
                            {task?.id}
                        </Typography>
                        <Typography>
                            {task?.title}
                        </Typography>
                    </Box>
                })}
            </Box>
        </Box>

    )
}

export default Tasks