import React,{useState} from 'react'
import List from '@mui/material/List';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useTranslation } from "react-i18next";
import TuneIcon from '@mui/icons-material/Tune';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import { useSelector,useDispatch } from 'react-redux';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import * as FaIcons from 'react-icons/fa'
import * as BsIcons from 'react-icons/bs'
import {Box} from '@mui/material'
import CreditCardIcon from '@mui/icons-material/CreditCard';
import {NavLink,useLocation} from 'react-router-dom'
import {setCurrentPath} from '../reduxStore/SettingsReducer'
import {Collapse} from 'react-collapse';
import * as RiIcons from 'react-icons/ri'


export default function SideMenuItemArray({open,setOpen,pathsList}) {
  const [t] = useTranslation("common");
  const location=useLocation()
  const sidemenutheme=useSelector(state=>state.themeData.sidemenutheme)
  const currentpath=useSelector(state=>state.settingsData.currentpath)
  const [openCollapse,setOpenCollapse]=useState(false)

  const openCollapseToggle=()=>{
    setOpenCollapse(!openCollapse)
  }

   console.log('asdasdasd',location?.pathname)
  return (
    <List
      sx={{
        // overflowX:'hidden',
        // overflowY:'scroll',
        // padding:'0 10px'
        height:'100% !important',
        // overflowX: 'hidden',
        // overflowY: 'scroll',
        width:'100%',
        display:'flex',flexDirection:'column',alignItems:open?'space-between':'center',justifyContent:'start',
        '& .iconSideMenu':{
            color: `${sidemenutheme?.iconColor} `,

            fontSize:'28px'
        },
        '& .MuiListItemText-root .MuiTypography-root':{
            textAlign: "left", 
            font: "normal normal 15px/30px Cairo-Medium !important", 
            letterSpacing: "0px", 
            color: sidemenutheme?.textitemcolor, 
            opacity: "1",
        },
        '& .MuiButtonBase-root':{
          backgroundColor:'transparent !important',
          padding:'2px 20px !important'
        },
        '& .MuiListItem-root':{
          borderRadius:open?'0px':'50%',
          width:open?'100%':'40px',
          height:open?'48px':'40px',
          display:'flex',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center',
          // background:location?.pathname==currentpath?`${sidemenutheme.itmeshover} !important`:`transparent !important`
          

        }, 
        '& .MuiListItem-root:hover':{
          backgroundColor:`${sidemenutheme.itmeshover} !important`,
        },
        '& .MuiListItem-root:hover .iconSideMenu':{
          color:`${sidemenutheme?.secondaryColor } !important`
        },
        '& .MuiListItem-root:hover .MuiListItemText-root .MuiTypography-root':{
          color:`${sidemenutheme?.secondaryColor } !important`
        },
        '& .active .MuiListItem-root':{
          backgroundColor:`${sidemenutheme.itmeshover} !important`
        },
        '& .active .iconSideMenu':{
          color: `${sidemenutheme?.secondaryColor} !important`,
        },
        '& .active .MuiListItemText-root .MuiTypography-root':{
          color:sidemenutheme?.secondaryColor+' !important',
        },
        "& .activeDropDown":{
          backgroundColor: `${sidemenutheme?.dropdwoncolor} !important`,
          borderRadius:open?'0px':'50%',
          width:open?'100%':'40px',
          height:open?'48px':'40px',
        },
        "& .activeDropDown .itmdropdown svg,& .activeDropDown .itmdropdown .MuiTypography-root,& .itmContainerdropdown .MuiTypography-root,& .itmContainerdropdown .iconSideMenu ":{
          color:sidemenutheme?.secondaryColor+' !important',
          cursor:'pointer !important',
        },
        "& .itmContainerdropdown":{
          backgroundColor: `${sidemenutheme?.dropdwoncolor} !important`,
        }
      }}
    >
        {pathsList&&pathsList?.length>0&&pathsList?.map((obj, index) => (
          <>
          {obj&&obj?.subLink?.length==0?
          <NavLink to={obj?.to} key={obj?.name+"_"+obj?.id} onClick={()=>{setOpen(false)}} >  
            <ListItem disablePadding sx={{ display: 'block',mt:0 }} disableRipple>
              <ListItemButton
              disableRipple
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                {
                  obj?.id===7&& open?
                  <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center',width:'100%'}}>
                      <Box sx={{display:'flex',justifyContent:'start',alignItems:'center'}}>
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                          }}
                        >
                          {obj?.icon}
                        </ListItemIcon>
                        <ListItemText primary={obj?.name} sx={{ opacity: open ? 1 : 0 }} />
                      </Box>
                      <Box sx={{
                          display:'flex',justifyContent:'center',alignItems:'center',
                          backgroundColor:'#000',
                          color:'#ffffff',
                          width: "30px", 
                          height: "16px", 
                          textAlign: "center", 
                          font: "normal normal bold 14px/18px Rajdhani", 
                          letterSpacing: "0px", 
                          color: "#FFFFFF",
                          opacity: "1",
                          borderRadius:'50%',
                          padding:'15px 10px'
                      }}>
                          8
                      </Box>
                  </Box>:<>
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                        }}
                      >
                        {obj?.icon}
                      </ListItemIcon>
                      <ListItemText primary={obj?.name} sx={{ opacity: open ? 1 : 0 }} />
                    </>
                }
              </ListItemButton>
            </ListItem>
          </NavLink>
          :obj?
            <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center',width:'100%'}} className="activeDropDown" onClick={openCollapseToggle}>
              <Box sx={{display:'flex',justifyContent:'start',alignItems:'center'}}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 6 : 'auto',
                    justifyContent: 'center',
                    position:'relative',
                    left:open?'20px':'6.5px'
                  }}
                  className="itmdropdown"
                >
                  {obj?.icon}
                </ListItemIcon>
                <ListItemText className='itmdropdown' primary={obj?.name} sx={{ opacity: open ? 1 : 0 }} />
              </Box>
              {
              !openCollapse?
              <RiIcons.RiArrowDropDownLine style={{fontSize:'30px',color:'#1E6A99'}} className='itmdropdown'/>:
              <RiIcons.RiArrowDropUpLine style={{fontSize:'30px',color:'#1E6A99'}} className='itmdropdown'/>
              }
            </Box>:null
          }
          {
              obj&&obj?.subLink?.length>0?
              <Collapse isOpened={openCollapse&&open} className="activeDropDown">
                  {/* <List> */}
                  {obj?.subLink?.map((sub)=>(
                    <NavLink to={sub?.to} key={sub?.name+"_"+sub?.id} className='itmContainerdropdown itmdropdown'>
                      <ListItem disablePadding sx={{ display: 'block',mt:0 }} disableRipple className='itmContainerdropdown itmdropdown '>
                          <ListItemButton
                          disableRipple
                            sx={{
                              minHeight: 48,
                              justifyContent: open ? 'initial' : 'center',
                              px: 2.5,
                            }}
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                              }}
                            >
                              {sub?.icon}
                            </ListItemIcon>
                            <ListItemText primary={sub?.name} sx={{ opacity: open ? 1 : 0 }} />
                          </ListItemButton>
                      </ListItem>
                    </NavLink>
                  ))}
                  {/* </List> */}
                
              </Collapse>:null
              }
          </>
        ))}
    </List>
  )
}
